import {
  useGetProjectAndUsersQuery,
  useGetProjectStartupListsForProjectQuery,
} from 'apollo/generated/sdkInnovationManager';
import {
  CategorizedSuppliers,
  Maybe,
  OrganizationStartupProfile,
  Suppliers,
} from 'apollo/generated/sdkShared';
import { GetProjectAndStartupsConnectionType } from 'components/dashboard/startupConnections/types';
import { flatMap } from 'lodash';
import { useMemo } from 'react';
import { CustomDimensionCellJSON } from '../@types/shared';

export type CategorizedStartupForProjectAndStartups = Pick<
  CategorizedSuppliers,
  | 'id'
  | 'matrix_table_score'
  | 'all_startups_table_score'
  | 'is_shortlisted'
  | 'is_selected'
  | 'is_contacted'
  | 'is_recommended'
  | 'is_in_matrix'
  | 'is_archived'
> & {
  startup: Pick<Suppliers, 'id' | 'domain' | 'name' | 'logo_url'> & {
    startup_connections: GetProjectAndStartupsConnectionType[];
    organization_profile?: Maybe<
      Pick<OrganizationStartupProfile, 'id' | 'procurement_supplier_identifier'>
    >;
  };
  custom_dimensions_json_v1: Record<string, CustomDimensionCellJSON>;
  voters: string[];
};

export const useGetProjectAndStartups = ({
  projectId,
  skipGetStartupListAndUsers,
  publicUuid,
}: {
  projectId: number;
  skipGetStartupListAndUsers?: boolean;
  publicUuid?: string;
}) => {
  const {
    error: projectError,
    data: projectData,
    called: projectCalled,
    loading,
    previousData: projectPreviousData,
    refetch,
  } = useGetProjectAndUsersQuery({
    variables: {
      ...(publicUuid
        ? { uuid: publicUuid, projectId: -1 }
        : { projectId: projectId, uuid: '' }),
    },
    skip: skipGetStartupListAndUsers,
  });

  const { data: projectsStartupListsData } =
    useGetProjectStartupListsForProjectQuery({
      variables: {
        ...(publicUuid
          ? { uuid: publicUuid, projectId: -1 }
          : { projectId: projectId, uuid: '' }),
      },
      skip: skipGetStartupListAndUsers,
      fetchPolicy: 'cache-and-network',
    });

  const project = useMemo(
    () => projectData?.project[0] || projectPreviousData?.project[0],
    [projectData, projectPreviousData],
  );

  const categories = useMemo(
    () =>
      project?.startup_lists?.flatMap(sl => sl?.project_categories || []) || [],
    [project],
  );

  const linkedLists = projectsStartupListsData?.projects_startup_lists;
  const accessedLists = project?.startup_lists;
  const inaccessibleLists = useMemo(
    () =>
      linkedLists?.filter(
        list =>
          !accessedLists?.some(
            accessedList => accessedList.id === list.startup_list_id,
          ),
      ) || [],
    [linkedLists, accessedLists],
  );

  const categorizedStartups: CategorizedStartupForProjectAndStartups[] =
    useMemo(
      () =>
        flatMap(
          categories.filter(c => !c.is_hidden),
          c => c.categorized_suppliers,
        ).filter(c => !c.is_archived),
      [categories],
    );

  const shortlistedStartups: CategorizedStartupForProjectAndStartups[] =
    useMemo(
      () =>
        categorizedStartups
          .filter(cs => cs.is_shortlisted)
          .sort((a, b) => a.id - b.id) || [],
      [categorizedStartups],
    );

  const startupsInMatrixAndNotShortlisted: CategorizedStartupForProjectAndStartups[] =
    useMemo(
      () =>
        categorizedStartups.filter(cs => cs.is_in_matrix && !cs.is_shortlisted),
      [categorizedStartups],
    );

  const called = projectCalled;
  return {
    project,
    linkedLists,
    inaccessibleLists,
    categories,
    categorizedStartups,
    shortlistedStartups,
    startupsInMatrixAndNotShortlisted,
    loading,
    called,
    error: !!projectError,
    errorData: projectError,
    refetch,
  };
};
