import { useGetProblemScopeByIdQuery } from 'apollo/generated/sdkShared';

export const useGetProblemScope = (problemScopeId?: number) => {
  const { data: scopeData, loading: problemScopeLoading } =
    useGetProblemScopeByIdQuery({
      variables: { problemScopeId: problemScopeId! },
      skip: problemScopeId === undefined,
      fetchPolicy: 'cache-and-network',
    });

  const problemScope = scopeData?.problem_scopes_by_pk;
  return { problemScope, problemScopeLoading };
};
