import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  GridProps,
  SxProps,
} from '@mui/material';
import { varFadeIn } from 'components/animate';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router';
import { BaseStartupAvatar, BaseStartupAvatarSize } from './BaseStartupAvatar';
import { BaseCompanyDetails } from './BaseCompanyDetails';
import {
  Suppliers,
  useEnrichStartupAsyncMutation,
} from 'apollo/generated/sdkInnovationManager';
import BaseEllipsisTypographyOneLine from './BaseEllipsisTypographyOneline';
import StartupFunnelLabelChip from 'components/startups/StartupFunnelLabelChip';
import { StartupFunnelLabel } from 'components/dashboard/StartupInfoSidePanel/StartupLabelChipSelector';
import { StartupSignal } from 'components/startups/StartupSignalLabel';
import { useCallback, useEffect, useState } from 'react';
import { useEnrichmentProgressPool } from 'components/enrichmentProgress/StartupEnrichmentProgressContext';
import { useEnrichmentProgressSpinner } from 'components/enrichmentProgress/useEnrichmentProgressSpinner';
import { DocumentNode } from '@apollo/client';

export type StartupForCard = Pick<
  Suppliers,
  | 'id'
  | 'domain'
  | 'name'
  | 'website'
  | 'logo_url'
  | 'short_description'
  | 'hq'
  | 'founded_year'
  | 'employees_count'
  | 'funding'
  | 'enriched_at'
> & {
  funnel_label: StartupFunnelLabel | undefined | null;
  signal_label: StartupSignal | undefined;
};

export const BaseStartupCard = ({
  startup,
  avatarSize,
  trackFn,
  afterOpen,
  hoverActions,
  gridProps = { xs: 12, sm: 12, md: 6, lg: 6 },
  sx,
  enrichment,
}: {
  startup: StartupForCard;
  avatarSize: BaseStartupAvatarSize;
  trackFn: () => void;
  afterOpen?: () => void;
  hoverActions?: React.ReactNode;
  gridProps?: Partial<GridProps>;
  sx?: SxProps;
  enrichment?: {
    autoEnrich: boolean;
    queryDocument: DocumentNode;
  };
}) => {
  const { palette, shadows, typography } = useTheme();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const { addProgressUUIDToPool } = useEnrichmentProgressPool();
  const [enrichStartupAsync] = useEnrichStartupAsyncMutation();

  const handleEnrich = useCallback(async () => {
    if (enrichment) {
      const { data: enrichStartupAsyncData } = await enrichStartupAsync({
        variables: {
          domain: startup.domain,
          overwriteExistingData: ['all'],
        },
      });

      addProgressUUIDToPool({
        // eslint-disable-next-line
        progressUUID: enrichStartupAsyncData?.enrich_startup!,
        domain: startup.domain,
        refetchQuery: enrichment.queryDocument,
      });
    }
  }, [startup.domain, enrichment, enrichStartupAsync, addProgressUUIDToPool]);

  useEffect(() => {
    if (enrichment?.autoEnrich && !startup.enriched_at) {
      handleEnrich();
    }
  }, [handleEnrich, enrichment?.autoEnrich, startup.enriched_at]);

  return (
    <Grid item {...gridProps} key={startup?.id}>
      <Card
        onMouseEnter={() => hoverActions && setIsHovered(true)}
        onMouseLeave={() => hoverActions && setIsHovered(false)}
        onClick={() => {
          trackFn();
          navigate(`${location.pathname}?startup_id=${startup!.id}`);
          afterOpen?.();
        }}
        component={motion.div}
        {...varFadeIn}
        sx={[
          ({ palette, customShadows }) => ({
            borderRadius: 1,
            boxShadow: customShadows.z4,
            backgroundColor: palette.background.paper,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: palette.grey[50],
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <CardContent
          sx={{
            padding: 2,
          }}
        >
          <Stack gap={0.5}>
            <Stack direction='row' justifyContent='space-between'>
              <BaseStartupCardHeader
                startup={startup}
                avatarSize={avatarSize}
              />
              {isHovered && (
                <Box onClick={e => e.stopPropagation()}>{hoverActions}</Box>
              )}
            </Stack>
            <Box>
              <Tooltip
                title={startup?.short_description}
                placement='bottom-start'
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'common.white',
                      color: 'common.black',
                      boxShadow: shadows[5],
                      fontSize: typography.pxToRem(14),
                      fontWeight: '400',
                    },
                  },
                }}
              >
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    textOverflow: 'ellipsis',
                    maxHeight: '2.55rem', // Approximately 2 lines
                    color: palette.grey.A700,
                  }}
                >
                  {startup?.short_description}
                </Typography>
              </Tooltip>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export const BaseStartupCardHeader = ({
  startup,
  avatarSize,
}: {
  startup: StartupForCard;
  avatarSize: BaseStartupAvatarSize;
}) => {
  const { palette, spacing } = useTheme();
  const enrichSpinner = useEnrichmentProgressSpinner({
    startupDomain: startup.domain,
  });

  return (
    <Stack direction='row' gap={2} alignItems='center'>
      <BaseStartupAvatar
        key={`avatar-${startup.domain}`}
        startup={{
          logo_url: startup?.logo_url,
          name: startup!.name,
          domain: startup!.domain,
        }}
        size={avatarSize}
      />
      <Stack width='100%'>
        <Stack direction='row' alignItems='center'>
          <BaseEllipsisTypographyOneLine
            variant='body2'
            sx={{
              color: palette.secondary.main,
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
              },
              fontWeight: 500,
              maxWidth: spacing(20), // x 8 = 160px,
            }}
            className='company-name'
          >
            {startup.name}
          </BaseEllipsisTypographyOneLine>
          {enrichSpinner && enrichSpinner}

          {startup.funnel_label && (
            <StartupFunnelLabelChip
              label={startup.funnel_label as StartupFunnelLabel}
              startupName={startup.name}
              sx={theme => ({
                marginLeft: theme.spacing(1),
              })}
            />
          )}
        </Stack>
        <BaseCompanyDetails
          company={{
            website: startup?.website,
            employees_count: startup?.employees_count,
            founded_year: startup?.founded_year,
            funding: startup?.funding,
            hq: startup?.hq,
            name: startup?.name,
          }}
          showWebsiteLink
          showCaseStudies={false}
        />
      </Stack>
    </Stack>
  );
};
