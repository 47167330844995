import { Stack, Button, Avatar, Collapse } from '@mui/material';
import { useFindOrganizationConnectionsQuery } from 'apollo/generated/sdkShared';
import { X_HASURA_ORGANIZATION_UUID } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import useAuth from 'hooks/useAuth';

export const ConnectionButtons = ({ returnTo }: { returnTo?: string }) => {
  const currentOrganization = useCurrentOrganizationFromContext();

  const { login } = useAuth();

  const loginWithConnection = async (connection: string) => {
    await login({
      returnTo,
      connection,
    });
  };

  const { data, loading } = useFindOrganizationConnectionsQuery({
    context: {
      headers: {
        [X_HASURA_ORGANIZATION_UUID]: currentOrganization.uuid,
      },
    },
  });

  const connections = data?.find_organization_connections?.data;

  return (
    <Collapse
      in={!loading && connections && connections?.length > 0}
      mountOnEnter
    >
      <Stack gap={2}>
        {connections?.map(c => (
          <Button
            key={c.id}
            onClick={() => loginWithConnection(c.name)}
            color='inherit'
            variant='contained'
          >
            {c.logo_url && (
              <Avatar
                sx={({ spacing }) => ({
                  marginRight: 1,
                  '.MuiAvatar-img': {
                    objectFit: 'contain',
                  },
                  width: spacing(3),
                  height: spacing(3),
                })}
                src={c.logo_url}
              ></Avatar>
            )}
            Log in with {c.display_name}
          </Button>
        ))}
      </Stack>
    </Collapse>
  );
};
