import { createFilterOptions } from '@mui/material';
import { useMemo } from 'react';
import { StakeholderOptionType } from './BaseStakeholdersSelect';

// TODO: Migrate as it as simple function instead of hook
export const useBaseStakeholderFilterOptions = () => {
  const filterOptions = useMemo(
    () =>
      createFilterOptions<StakeholderOptionType>({
        limit: 40,
        ignoreAccents: true,
        stringify: option =>
          `${option.full_name.replaceAll(
            // Needed to help matching email addresses consisting of "firstname.lastname@domain"
            // to be matched by "firstname lastname"
            '.',
            ' ',
          )}
          ${option.email}
          ${option.department}`,
        trim: true,
      }),
    [],
  );

  return filterOptions;
};
