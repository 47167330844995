import { useGetSubmissionsByStartupIdQuery } from 'apollo/generated/sdkInnovationManager';
import {
  StartupCorporateCustomers,
  Suppliers,
} from 'apollo/generated/sdkShared';
import { useFormik } from 'formik';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { useMemo } from 'react';
import * as Yup from 'yup';

import { CachedWebsiteImage } from '../../../@types/shared';
import { StartupContact, StartupForSidePanel } from './StartupInfoSidePanel';

export type StartupDocument = {
  id: number;
  name: string;
  attachment_url: string;
  document_type: string;
};

export type CorporateCustomer = {
  id: number;
  name?: string | null;
  domain: string;
  logo_url?: string | null;
};

type Customer = CorporateCustomer &
  Pick<StartupCorporateCustomers, 'type' | 'case_study_url'>;

export type StartupFormState = Pick<
  Suppliers,
  | 'id'
  | 'name'
  | 'domain'
  | 'crunchbase_url'
  | 'linkedin_url'
  | 'website'
  | 'investors'
  | 'short_description'
  | 'hq'
  | 'employees_count'
  | 'founded_year'
  | 'funding'
  | 'tags'
  | 'logo_url'
  | 'usp'
  | 'pricing'
  | 'company_traction'
  | 'long_description'
> & {
  business_model?: string[];
  product_kpi?: string[];
  referenced_customers: Customer[];
  contacts?: StartupContact[];
  documents_json?: StartupDocument[];
  cached_website_images: CachedWebsiteImage[];
};

const VALIDATE_WEBSITE_OR_DOMAIN_PATTERN = /^([^.]+)(?:\.[^.]*){1,4}[^.]$/;

const REFERENCED_CUSTOMER_SCHEMA_FIELDS = {
  domain: Yup.string()
    .matches(
      VALIDATE_WEBSITE_OR_DOMAIN_PATTERN,
      'You have entered an invalid domain',
    )
    .required('Domain is required'),
  logoURL: Yup.string().default(null).nullable(),
  type: Yup.mixed().oneOf(['deduplicated', 'unknown']).optional(),
};

const REFERENCED_CUSTOMER_SCHEMA = Yup.object().shape(
  REFERENCED_CUSTOMER_SCHEMA_FIELDS,
);

const STARTUP_DETAILS_EDIT_SCHEMA = Yup.object({
  name: Yup.string().required('Name is required'),
  website: Yup.string()
    .required('Website is required')
    .matches(VALIDATE_WEBSITE_OR_DOMAIN_PATTERN, 'Invalid website'),
  crunchbase_url: Yup.string().nullable(),
  linkedin_url: Yup.string().nullable(),
  investors: Yup.array(Yup.string()).nullable(),
  short_description: Yup.string().nullable(),
  referenced_customers: Yup.array().of(REFERENCED_CUSTOMER_SCHEMA),
  hq: Yup.string().nullable(),
  employees_count: Yup.number().nullable(),
  founded_year: Yup.number().nullable(),
  funding: Yup.number().nullable(),
  tags: Yup.array(Yup.string()),
  logo_url: Yup.string().nullable(),
  long_description: Yup.string().nullable().optional(),
  usp: Yup.string().nullable().optional(),
  company_traction: Yup.string().nullable().optional(),
  pricing: Yup.string().nullable().optional(),
  business_model: Yup.array(Yup.string()),
  product_kpi: Yup.array(Yup.string()).optional(),
  contacts: Yup.array(
    Yup.object({
      name: Yup.string(),
      email: Yup.string().email({ message: 'Invalid email address' }),
      job_title: Yup.string().optional(),
      linkedin_url: Yup.string().optional(),
      is_primary_contact: Yup.boolean(),
    }),
  ),
  documents_json: Yup.array(
    Yup.object({
      id: Yup.number(),
      name: Yup.string(),
      document_type: Yup.mixed().oneOf([
        'sales_material',
        'customer_case_studies',
      ]),
      attachment_url: Yup.string(),
    }),
  ),
  features: Yup.array(Yup.string()).nullable(),
});

type FirstPartyDataFormState = {
  pricing: string;
  business_model: string[];
  product_kpi: string[];
  usp: string;
  company_traction: string;
  documents_json: StartupDocument[];
};

const firstPartyDataKeys: Readonly<(keyof FirstPartyDataFormState)[]> = [
  'pricing',
  'business_model',
  'product_kpi',
  'usp',
  'company_traction',
  'documents_json',
];

export function useStartupDetailsFormik({
  initialValues,
  isPanelOpened,
}: {
  initialValues: StartupFormState;
  isPanelOpened: boolean;
}) {
  const { isSharedPage } = useSharedPagesContext();

  const latestApprovedSubmission = useGetLatestFirstPartySubmission(
    initialValues.id,
    isSharedPage,
  );

  const onSubmit = async () => {
    throw new Error('onSubmit to be re-implemented');
  };

  const formik = useFormik<StartupFormState>({
    initialValues,
    initialStatus: isPanelOpened
      ? initialValues?.id
        ? 'hidden'
        : 'editing'
      : 'hidden',
    onSubmit,
    validationSchema: STARTUP_DETAILS_EDIT_SCHEMA,
    enableReinitialize: true,
    validateOnChange: true,
  });

  const isApprovedAndNotOverridden = !!latestApprovedSubmission;

  const allFirstPartyDataFieldsAreEmpty = firstPartyDataKeys.every(key => {
    return initialValues[key] === '' || initialValues[key]?.length === 0;
  });

  return {
    formik,
    isApprovedAndNotOverridden,
    hasFirstPartyData: !allFirstPartyDataFieldsAreEmpty,
    dateApproved: latestApprovedSubmission?.updated_at,
  };
}

export type StartupDetailsFormik = ReturnType<
  typeof useStartupDetailsFormik
>['formik'];

function useGetLatestFirstPartySubmission(
  startupId: number,
  isSharedPage?: boolean,
) {
  const { data: startupsSubmissionData } = useGetSubmissionsByStartupIdQuery({
    variables: {
      startupId: startupId!,
    },
    skip: !startupId || isSharedPage,
  });

  const approvedSubmissions = useMemo(
    () =>
      startupsSubmissionData?.startup_submissions
        .filter(s => s.status === 'approved')
        .slice(-1)[0],
    [startupsSubmissionData?.startup_submissions],
  );

  return approvedSubmissions;
}

export function getInitialValuesFromOrganizationStartups(
  firstPartyData: FirstPartyDataFormState,
  currentStartup?: StartupForSidePanel,
): StartupFormState {
  return {
    id: currentStartup?.id as number,
    domain: currentStartup?.domain as string,
    name: currentStartup?.name || '',
    website: currentStartup?.website || '',
    crunchbase_url: currentStartup?.crunchbase_url || '',
    linkedin_url: currentStartup?.linkedin_url || '',
    investors: currentStartup?.investors || [],
    short_description: currentStartup?.short_description || '',
    referenced_customers:
      currentStartup?.startup_corporate_customers.map(s => ({
        ...s.corporate_customer,
        type: s.type,
        case_study_url: s.case_study_url,
      })) || [],
    hq: currentStartup?.hq || '',
    funding: currentStartup?.funding,
    employees_count: currentStartup?.employees_count,
    founded_year: currentStartup?.founded_year,
    tags: currentStartup?.tags || [],
    logo_url: currentStartup?.logo_url || null,
    // features: currentStartup?._features || [],
    // is_known: currentStartup?.is_known || false,
    // is_proven: currentStartup?.is_proven || false,
    // is_recommended: currentStartup?.is_recommended || false,
    cached_website_images: currentStartup?.cached_website_images || [],
    // First party data? fields
    ...{
      ...firstPartyData,
      long_description:
        currentStartup?.long_description ||
        currentStartup?.short_description ||
        '',
      // When there is first party data show Customers as Pilot customers
      contacts:
        currentStartup?.contacts.filter(s => s.source === 'first_party_data') ||
        [],
    },
  };
}

export function getFirstPartyDataFromOrganizationStartup(
  currentStartup?: StartupForSidePanel,
): FirstPartyDataFormState {
  return {
    pricing: currentStartup?.pricing || '',
    business_model: currentStartup?.business_model || [],
    product_kpi: currentStartup?.product_kpi || [],
    usp: currentStartup?.usp || '',
    company_traction: currentStartup?.company_traction || '',
    documents_json: currentStartup?.documents_json || [],
  };
}
type SharedSupplier = Pick<
  Suppliers,
  | 'funding'
  | 'name'
  | 'logo_url'
  | 'founded_year'
  | 'is_proven'
  | 'is_known'
  | 'hq'
  | 'domain'
  | 'employees_count'
  | 'short_description'
  | 'crunchbase_url'
  | 'linkedin_url'
  | 'investors'
  | 'id'
  | 'website'
  | 'created_at'
  | 'funnel_labels'
> & {
  startup_corporate_customers: {
    type?: string | null;
    corporate_customer: CorporateCustomer;
  }[];
};

export type SearchStartup = SharedSupplier & {
  id: number;
  domain: string;
  name: string;
  tags: string[];
  _features?: string[] | null;
  is_recommended: boolean;
  long_description?: string | null;
  contacts: StartupContact[];
  pricing?: string | null;
  business_model?: string[] | null;
  product_kpi?: string[] | null;
  usp?: string | null;
  company_traction?: string | null;
  documents_json?: StartupDocument[];
  cached_website_images: CachedWebsiteImage[];
};

export function getInitialValuesFromSearchStartups(
  firstPartyData: FirstPartyDataFormState,
  startup: StartupForSidePanel,
): StartupFormState {
  return {
    id: startup.id,
    domain: startup?.domain as string,
    name: startup?.name || '',
    website: startup?.website || '',
    crunchbase_url: startup?.crunchbase_url || '',
    linkedin_url: startup?.linkedin_url || '',
    investors: startup?.investors || [],
    short_description: startup?.short_description || '',
    referenced_customers:
      startup?.startup_corporate_customers?.map(c => ({
        ...c.corporate_customer,
        case_study_url: c.case_study_url,
        type: c.type,
      })) || [],
    hq: startup?.hq || '',
    funding: startup?.funding,
    employees_count: startup?.employees_count,
    founded_year: startup?.founded_year,
    tags: startup?.tags || [],
    logo_url: startup?.logo_url || null,
    // is_known: startup?.is_known || false,
    // is_proven: startup?.is_proven || false,
    // is_recommended: startup?.is_recommended || false,
    cached_website_images: startup?.cached_website_images || [],

    // First party data? fields
    ...{
      ...firstPartyData,
      long_description:
        startup?.long_description || startup?.short_description || '',
      // When there is first party data show Customers as Pilot customers
      contacts:
        startup?.contacts.filter(s => s.source === 'first_party_data') || [],
    },
  };
}

export function getFirstPartyDataFromSearchStartup(
  startup?: StartupForSidePanel,
): FirstPartyDataFormState {
  return {
    pricing: startup?.pricing || '',
    business_model: startup?.business_model || [],
    product_kpi: startup?.product_kpi || [],
    usp: startup?.usp || '',
    company_traction: startup?.company_traction || '',
    documents_json: startup?.documents_json || [],
  };
}
