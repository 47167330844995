import { InfoOutlined } from '@mui/icons-material';
import { Box, Chip, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridSlotsComponentsProps,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import {
  EnumTablePriorityEnum,
  EnumTableStakeholderMaturityEnum,
  EnumTableStakeholderTypeEnum,
} from 'apollo/generated/sdkInnovationManager';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import BaseEllipsisTypographyOneLine from 'components/base/BaseEllipsisTypographyOneline';
import BaseInitialsAvatar from 'components/base/BaseInitialsAvatar';
import BaseLabel from 'components/base/BaseLabel';
import { useRightTableActions } from 'components/base/useRightTableActions';
import { EmailField } from 'components/shared/EmailField';
import SendEmailButton from 'components/shared/SendEmailButton';
import AssignTeamButton from 'components/teams/AssignTeamButton';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { BusinessUnitPersonRow, PersonTag } from 'contexts/PersonTableContext';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { isString } from 'lodash';
import { useMemo } from 'react';
import { buildProjectDetailsPath } from 'routes/paths';
import DeletePersonFromTable from './DeletePersonFromTable';
import LinkToPocOrLead from './LinkToPocOrLead';
import MaturityCell from './MaturityCell';
import ProjectsAndLeadsForPerson from './ProjectsAndLeadsForPerson';
import { GLASSDOLLAR_EMAIL_DOMAIN } from 'config';

export const priorityValueOptions: {
  value: EnumTablePriorityEnum;
  label: string;
}[] = [
  { label: 'High', value: 'high' },
  { label: 'Medium', value: 'medium' },
  { label: 'Low', value: 'low' },
];

export const maturityValueOptions: {
  value: EnumTableStakeholderMaturityEnum;
  label: string;
}[] = [
  { label: 'PoC(s) completed', value: 'pocs_completed' },
  { label: 'Project(s) started', value: 'projects_started' },
  { label: 'Request(s) submitted', value: 'requests_submitted' },
  { label: 'No interactions yet', value: 'no_interactions' },
];

export const stakeholderTypeValueOptions: {
  value: EnumTableStakeholderTypeEnum;
  label: string;
}[] = [
  { label: 'Pain point owner', value: 'pain_point_owner' },
  { label: 'Supporting function', value: 'supporting_function' },
  { label: 'Venture partner', value: 'venture_partner' },
];

export const deprecatedStakeholderTypeValueOptions: {
  value: EnumTableStakeholderTypeEnum;
  label: string;
}[] = [
  { label: 'Innovation manager', value: 'innovation_manager' },
  { label: 'Pain point owner', value: 'pain_point_owner' },
  { label: 'Supporting function', value: 'supporting_function' },
  { label: 'Venture partner', value: 'venture_partner' },
];

export type TableParams<T extends keyof BusinessUnitPersonRow> =
  GridRenderCellParams<BusinessUnitPersonRow, BusinessUnitPersonRow[T]>;

export function useGetPeopleColumns(): {
  columns: GridColDef<BusinessUnitPersonRow>[];
  row: GridSlotsComponentsProps['row'];
  pinnedRightColumn: string[];
} {
  const { palette } = useTheme();
  const { row, getActionsColumn, pinnedRightColumn } = useRightTableActions();
  const { has_teams } = useCurrentOrganizationFromContext();

  const columns = useMemo(
    () => [
      {
        field: 'full_name',
        headerName: 'Details',
        minWidth: 300,
        filterable: false,
        renderCell: (params: TableParams<'full_name'>) => {
          if (!isString(params.value)) return null;

          return (
            <Stack
              width={'100%'}
              direction='row'
              alignItems={'center'}
              paddingY={0.5}
              paddingRight={1}
            >
              <BaseInitialsAvatar full_name={params.value} />
              <Stack
                sx={{
                  minWidth: 0,
                  flex: 1,
                  paddingY: 0.5,
                }}
              >
                <TypographyWithEllipsis>{params.value}</TypographyWithEllipsis>
                {params.value !== params.row.email && (
                  <EmailField value={params.row.email} />
                )}
              </Stack>
            </Stack>
          );
        },
      },
      {
        field: 'organization_department',
        headerName: 'Department',
        minWidth: 110,
        renderCell: (params: TableParams<'organization_department'>) => {
          const department = params.row.organization_department;

          return (
            <BaseEllipsisTypographyOneLine title={department?.name ?? ''}>
              {department?.name}
            </BaseEllipsisTypographyOneLine>
          );
        },
      },
      ...(has_teams
        ? [
            {
              field: 'team',
              headerName: 'Team',
              minWidth: 130,
              getApplyQuickFilterFn: undefined,
              renderCell: (params: TableParams<'team'>) => {
                const person = params.row;

                return (
                  <AssignTeamButton
                    name={person.full_name}
                    id={person.id}
                    team={person.team}
                    type='stakeholder'
                  />
                );
              },
            },
          ]
        : []),
      {
        field: 'projects',
        headerName: 'Projects',
        minWidth: 110,
        getApplyQuickFilterFn: undefined,
        renderCell: (params: TableParams<'projects'>) => (
          <RenderProjectsCell params={params} />
        ),
      },
      {
        field: 'activeSince',
        headerName: 'Active since',
        type: 'date',
        getApplyQuickFilterFn: undefined,
        minWidth: 150,
        valueGetter: (params: GridRenderCellParams) =>
          params.value ? parseISO(params.value) : null,
        renderCell(
          params: GridRenderCellParams<
            BusinessUnitPersonRow,
            string | number | Date
          >,
        ) {
          if (!params.value) return null;

          return formatDistanceToNow(new Date(params.value));
        },
      },
      {
        field: 'stakeholder_type',
        headerName: 'User type',
        minWidth: 150,
        filterable: true,
        type: 'singleSelect',
        valueOptions: stakeholderTypeValueOptions,
        valueFormatter(
          params: GridValueFormatterParams<EnumTableStakeholderTypeEnum>,
        ) {
          const label = stakeholderTypeValueOptions.find(
            option => option.value === params.value,
          )?.label;

          return label || '';
        },
        renderCell: (
          params: GridRenderCellParams<
            BusinessUnitPersonRow,
            EnumTableStakeholderTypeEnum
          >,
        ) => {
          if (!params.formattedValue) return null;

          return <BaseLabel color='default'>{params.formattedValue}</BaseLabel>;
        },
      },
      {
        field: 'priority',
        headerName: 'Priority',
        minWidth: 60,
        filterable: true,

        type: 'singleSelect',
        valueOptions: priorityValueOptions,
        valueFormatter(
          params: GridValueFormatterParams<EnumTablePriorityEnum>,
        ) {
          return (
            priorityValueOptions.find(option => option.value === params.value)
              ?.label || ''
          );
        },
        renderCell: (
          params: GridRenderCellParams<
            BusinessUnitPersonRow,
            EnumTablePriorityEnum
          >,
        ) => {
          const { value } = params;

          if (!value) return null;

          let backgroundColor = palette.grey[600];

          if (value === 'medium') {
            backgroundColor = palette.grey[400];
          } else if (value === 'low') {
            backgroundColor = palette.grey[200];
          }

          const color = palette.getContrastText(backgroundColor);
          return (
            <BaseLabel sx={{ color, backgroundColor }}>
              {params.formattedValue}
            </BaseLabel>
          );
        },
      },
      {
        field: 'maturity',
        headerName: 'Maturity',
        minWidth: 160,
        filterable: true,

        type: 'singleSelect',
        valueOptions: maturityValueOptions,
        valueFormatter(
          params: GridValueFormatterParams<EnumTableStakeholderMaturityEnum>,
        ) {
          const label = maturityValueOptions.find(
            option => option.value === params.value,
          )?.label;

          return label || '';
        },
        renderCell: (
          params: GridRenderCellParams<
            BusinessUnitPersonRow,
            EnumTableStakeholderMaturityEnum
          >,
        ) => <MaturityCell {...params} />,
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 280,
        getApplyQuickFilterFn: undefined,
        type: 'singleSelect',
        renderCell(
          params: GridRenderCellParams<BusinessUnitPersonRow, PersonTag[]>,
        ) {
          return (
            <>
              {params.value?.map(v => (
                <Chip
                  key={`stakeholder-tag-${v.id}`}
                  sx={theme => ({
                    backgroundColor: theme.palette.grey[300],
                  })}
                  size='small'
                  label={
                    <Stack
                      direction={'row'}
                      spacing={0.5}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{ position: 'relative' }}
                    >
                      <Typography variant='inherit'>{v.name}</Typography>
                    </Stack>
                  }
                />
              ))}
            </>
          );
        },
      },
      {
        field: 'createdAt',
        headerName: 'Created at',
        type: 'date',
        minWidth: 150,
        valueGetter: (params: GridRenderCellParams) =>
          params.value ? parseISO(params.value) : null,
        renderCell: (
          params: GridRenderCellParams<BusinessUnitPersonRow, Date>,
        ) =>
          params.value ? format(new Date(params.value), 'dd.MM.yyyy') : null,
      },
      getActionsColumn({
        minWidth: 110,
        renderCellNode: (params: GridCellParams<BusinessUnitPersonRow>) => {
          const person = params.row;
          const gdUserSelected = person.email.endsWith(
            GLASSDOLLAR_EMAIL_DOMAIN,
          );

          return (
            <Stack direction='row' justifyContent='center' alignItems='center'>
              <Tooltip title='Send email'>
                <Box component='span'>
                  <SendEmailButton emails={[person.email]} />
                </Box>
              </Tooltip>
              <Tooltip
                title={
                  gdUserSelected
                    ? 'You cannot delete GlassDollar users'
                    : 'Delete'
                }
              >
                <Box component='span'>
                  <DeletePersonFromTable
                    disabled={gdUserSelected}
                    peopleIds={[person.id]}
                    onDelete={() => {}}
                  />
                </Box>
              </Tooltip>
            </Stack>
          );
        },
      }) satisfies GridColDef<BusinessUnitPersonRow>,
    ],
    [getActionsColumn, has_teams, palette],
  );

  return { columns, row, pinnedRightColumn };
}

const RenderProjectsCell = ({
  params,
}: {
  params: TableParams<'projects'>;
}) => {
  const { palette, shadows } = useTheme();
  const cellValue = params.value;
  const totalProjects = cellValue?.projects_involved_in.length || 0;

  if (!totalProjects) return '';

  if (totalProjects === 1) {
    if (cellValue?.projects_involved_in.length) {
      const poc = cellValue.projects_involved_in[0];

      if (!poc.project) return '';

      return (
        <LinkToPocOrLead
          item={poc.project}
          to={buildProjectDetailsPath({
            id: poc.project.id,
            stage: poc.project.stage,
          })}
        />
      );
    }
  }

  return (
    <Tooltip
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: palette.grey[0],
            boxShadow: shadows[10],
          },
        },
      }}
      title={
        <ProjectsAndLeadsForPerson
          notes={cellValue?.notes || 0}
          projects={params.value?.projects_involved_in || []}
        />
      }
    >
      <Typography>
        {totalProjects} <InfoOutlined sx={{ fontSize: 18 }} />
      </Typography>
    </Tooltip>
  );
};
