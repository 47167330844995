import {
  Box,
  Button,
  Stack,
  TextField,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';

import { Add, InfoOutlined } from '@mui/icons-material';
import Markdown from 'components/Markdown';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { SourcingOrderState } from 'pages/request-form/sourcingOrderModel';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FieldProps } from '../../pages/request-form/types';

type ProblemSchemaValues = Pick<SourcingOrderState, 'problem'>;

type EditableFieldProps = {
  name: string;
  value: string;
  checked?: boolean;
  multiple?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleToggle?: Dispatch<SetStateAction<boolean>>;
  toggleOpen?: boolean;
};

interface FormLabelWithTooltipProps extends TypographyProps {
  label: string;
  tooltipText?: string;
  isMandatory?: boolean;
  showTooltip?: boolean;
}

export const FormLabelWithTooltip = ({
  label,
  tooltipText,
  isMandatory = false,
  showTooltip = true,
  ...props
}: FormLabelWithTooltipProps) => {
  return (
    <Typography
      gutterBottom
      variant='body2'
      component='div'
      display='flex'
      alignItems='center'
      marginBottom='8px'
      fontWeight={900}
      paddingX={'4px'}
      {...props}
    >
      {label}
      {showTooltip && (
        <Tooltip title={tooltipText} placement='top-start'>
          <Stack>
            {tooltipText && (
              <InfoOutlined
                fontSize='small'
                sx={{
                  marginLeft: 1,
                  cursor: 'help',
                  color: 'text.secondary',
                }}
              />
            )}
          </Stack>
        </Tooltip>
      )}
      {isMandatory && showTooltip && '*'}
    </Typography>
  );
};

export function ProblemField({
  getFieldProps,
  touched,
  isEditMode,
  errors,
  showTooltip = true,
  handleToggle,
  toggleOpen,
}: FieldProps<Pick<ProblemSchemaValues, 'problem'>>) {
  const { isSharedPage } = useSharedPagesContext();
  return (
    <>
      <FormLabelWithTooltip
        label='Need'
        tooltipText={
          isSharedPage
            ? 'Please describe the specific need or challenge you are facing and provide some examples if possible.'
            : 'Please describe the specific need or challenge your stakeholders are facing and provide some examples if possible.'
        }
        isMandatory
        showTooltip={showTooltip}
      />
      {!isEditMode ? (
        <TextField
          rows={4}
          multiline={true}
          fullWidth
          data-test='need--field'
          {...getFieldProps('problem')}
          error={Boolean(touched.problem && errors.problem)}
          helperText={touched.problem && errors.problem}
        />
      ) : (
        <EditableField
          handleToggle={handleToggle}
          data-test='need--field'
          {...getFieldProps('problem')}
          toggleOpen={toggleOpen}
        />
      )}
    </>
  );
}

export function EditableField(props: EditableFieldProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [text, setText] = useState(props.value);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleTextClick = () => {
    setIsExpanded(true);
    setIsEditing(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsEditing(false);
    if (props.handleToggle && !props.toggleOpen) {
      setIsExpanded(false);
    }
    props.onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setText(newValue);
    props.onChange(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (e.key === 'Enter' && e.shiftKey) ||
      (e.key === 'Enter' && (e.metaKey || e.ctrlKey))
    ) {
      e.preventDefault();
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      const length = text.length;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(length, length);
    }
    // should only run when isEditing changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    if (props.value !== text) {
      setText(props.value || '');
    }
  }, [props.value, text]);

  useEffect(() => {
    if (props.toggleOpen) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [props.toggleOpen]);

  return (
    <>
      {!text && !isEditing ? (
        <Button
          sx={{
            padding: '2px',
          }}
          startIcon={<Add />}
          onClick={() => setIsEditing(true)}
        >
          Add
        </Button>
      ) : (
        <Box
          onClick={handleTextClick}
          sx={{
            cursor: isEditing ? 'text' : 'pointer',
            padding: '4px',
            border: `0.5px solid ${isEditing ? '#ddd' : 'transparent'}`,
            boxShadow: isEditing ? ({ shadows }) => shadows[4] : 'none',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: !isEditing ? '#f0f0f0' : 'transparent',
              cursor: !isEditing ? 'text' : 'pointer',
            },
          }}
        >
          {isEditing ? (
            <TextField
              {...props}
              value={text}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              inputRef={inputRef}
              variant='standard'
              onBlur={handleBlur}
              autoFocus
              fullWidth
              multiline
              InputProps={{
                disableUnderline: true,
                sx: { padding: 0, lineHeight: 1.5, fontSize: '14px' },
              }}
              inputProps={{
                style: { lineHeight: '1.5' },
              }}
            />
          ) : (
            <Typography
              variant='body2'
              component='div'
              sx={{
                whiteSpace: 'pre-line',
                lineHeight: '1.5',
                ...(isExpanded || !props.handleToggle
                  ? {}
                  : {
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      WebkitLineClamp: 3,
                      textOverflow: 'ellipsis',
                    }),
              }}
            >
              <Markdown>{text}</Markdown>
            </Typography>
          )}
        </Box>
      )}
    </>
  );
}
