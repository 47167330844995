import { PlaylistAddCheck } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  Fade,
  ListItem,
  ListItemAvatar,
  Typography,
  useTheme,
} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { GetProjectSearchMinimalQuery } from 'apollo/generated/sdkInnovationManager';
import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkShared';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import { AnimatePresence, motion } from 'framer-motion';
import { take } from 'lodash';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useMemo } from 'react';

type Props = {
  onClose: () => void;
  projects: GetProjectSearchMinimalQuery['projects'];
  navigateTo: (id: number, stage: EnumTableProjectStagesEnum) => void;
};

function ProjectResults({ onClose, projects, navigateTo }: Props) {
  const { shadows, spacing, palette } = useTheme();
  const results = useMemo(() => take(projects || [], 5), [projects]);

  return (
    <Fade in={results.length > 0} unmountOnExit mountOnEnter>
      <Box>
        <Divider />
        <MenuList>
          <ListItem sx={{ background: palette.grey[200] }}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: spacing(4),
                  height: spacing(4),
                  background: palette.grey[0],
                  boxShadow: shadows[1],
                }}
              >
                <PlaylistAddCheck />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary='Projects' />
          </ListItem>
          <AnimatePresence>
            {results.map(({ id, title, stage }) => {
              return (
                <motion.div
                  key={id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <MenuItem
                    //Children elements is stopping this event from being triggered onClick
                    onMouseUp={e => {
                      e.stopPropagation();
                      captureAnalyticsEvent('App Search > Result clicked', {
                        type: 'project',
                        recordId: id,
                      });
                      onClose();
                      navigateTo(id, stage);
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}
                      sx={{
                        '& .MuiListItemText-primary': {
                          display: 'flex',
                        },
                      }}
                    >
                      {' '}
                      <TypographyWithEllipsis
                        tooltipTitle={
                          <Typography variant='body2'>{title}</Typography>
                        }
                        tooltipVariant='light'
                        placement='right'
                      >
                        {title || 'Unknown'}
                      </TypographyWithEllipsis>
                    </ListItemText>
                  </MenuItem>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </MenuList>
      </Box>
    </Fade>
  );
}

export { ProjectResults };
