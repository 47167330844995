import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { EnumTableProjectStakeholderRolesEnum } from 'apollo/generated/sdkInnovationManager';
import { Form, FormikProvider, useFormik } from 'formik';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { BaseStakeholdersSelect } from './base/stakeholdersSelect/BaseStakeholdersSelect';

type AddProjectStakeholderModalProps = {
  open: boolean;
  onHide: () => void;
  onSetValues: (f: {
    stakeholder_id?: number;
    role?: keyof PROJECT_ROLES;
  }) => void;
  projectStakeholderIds: number[];
};

export type PROJECT_ROLES = {
  project_sponsor: 'Project Sponsor';
  project_team: 'Other Stakeholders (Business Unit)';
  primary: 'Primary Contact (Startup)';
  other: 'Other Stakeholders (Startup)';
};

type ExtendedProjectRoles = EnumTableProjectStakeholderRolesEnum | 'primary';

export const PROJECT_ROLES_MAP: Record<ExtendedProjectRoles, string> = {
  // Business Unit
  project_leader: 'Pain Point Owner',
  project_sponsor: 'Project Sponsor',
  project_team: 'Other Stakeholders (Business Unit)',
  // Startup
  primary: 'Primary Contact (Startup)',
  other: 'Other Stakeholders (Startup)',
  adoption_committee: 'Adoption Committee',
  adoption_owner: 'Adoption Owner',
  key_stakeholders_gatekeeper: 'Key Stakeholders/Gatekeepers',
  project_owner: 'Project Owner',
};

export const AddProjectStakeholderModal = (
  props: AddProjectStakeholderModalProps,
) => {
  const { open, onHide, onSetValues } = props;

  const handleProjectStakeholderChange = (id: number | null) => {
    setFieldValue('stakeholder_id', id);
  };

  const formik = useFormik<{
    stakeholder_id?: number;
    role?: keyof PROJECT_ROLES;
  }>({
    initialValues: {
      stakeholder_id: undefined,
      role: undefined,
    },
    onSubmit: async (values, { resetForm }) => {
      onSetValues(values);
      if (values.stakeholder_id && values.role) {
        captureAnalyticsEvent('Stakeholder added to PoC scope', {
          stakeholderId: values.stakeholder_id,
          stakeholderRole: values.role,
        });
      }
      resetForm();
      onHide();
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Dialog open={open} fullWidth onClose={onHide}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle>Add stakeholder</DialogTitle>
          <DialogContent sx={{ marginTop: '25px' }}>
            <Stack direction='column' spacing={2} sx={{ marginTop: '5px' }}>
              <BaseStakeholdersSelect
                previouslySelectedStakeholderIds={props.projectStakeholderIds}
                previouslySelectedMessage='ADDED'
                selectedStakeholderId={getFieldProps('stakeholder_id').value}
                onChange={stakeholder => {
                  handleProjectStakeholderChange(stakeholder?.id || null);
                }}
                placeholder='Search for stakeholder by name'
              />
              <TextField
                select
                fullWidth
                label='Role'
                {...getFieldProps('role')}
                helperText={touched.role && errors.role}
              >
                {Object.keys(PROJECT_ROLES_MAP).map((value, index) => {
                  if (
                    [
                      'project_leader',
                      'project_sponsor',
                      'project_team',
                    ].includes(value)
                  ) {
                    return (
                      <MenuItem key={`${value} + ${index}`} value={value}>
                        {PROJECT_ROLES_MAP[value as keyof PROJECT_ROLES]}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </TextField>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' color='inherit' onClick={onHide}>
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={!formik.values.role || !formik.values.stakeholder_id}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};
