import { CheckCircle } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { TypographyProps } from '@mui/material';
import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import {
  EnumTableLeadMaturityEnum,
  EnumTableProjectStagesEnum,
} from 'apollo/generated/sdkInnovationManager';
import { FALLBACK_SUPPLIER_LOGO_URL } from 'components/AvatarGroup';
import {
  BaseStartupAvatar,
  BaseStartupAvatarSize,
} from 'components/base/BaseStartupAvatar';
import { BaseStyledSmallText } from 'components/base/BaseStyledSmallText';
import { useProjectContext } from 'contexts/ProjectContext';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import { startCase } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { LEAD_STAGES } from 'utils/projectStageEnum';

type Startup = {
  domain: string;
  logo_url: string;
  name: string;
  id: number;
};

type CategorizedStartup = {
  startup: Startup;
};

type Project = {
  stage: EnumTableProjectStagesEnum;
  lead_maturity: EnumTableLeadMaturityEnum;
};

type StartupIconAndNameProps = {
  startup: Startup;
  showName?: boolean;
  isGreyedOut?: boolean;
  iconSize?: BaseStartupAvatarSize;
};

type ProjectScopeContext = {
  project: Project;
  selectedStartup: { startup: Startup } | null;
  shortlistedStartups: { startup: Startup }[];
  categorizedStartups: CategorizedStartup[];
};

type LeadStages = 'scoping' | 'sourcing' | 'sourcing_completed';

interface CustomTypographyProps extends Omit<TypographyProps, 'color'> {
  children: ReactNode;
  color?: string;
  textAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  fontWeight?: number;
  sx?: SxProps<Theme>;
}

type SelectedStartupProps = {
  projectStage: EnumTableProjectStagesEnum;
  startup: Startup;
};

type ListedStartupsProps = {
  startups: Startup[];
  label?: string;
  isGreyedOut?: boolean;
};

type ProjectStageTextProps = {
  projectStage: string;
};

export const CustomTypography = ({
  children,
  textAlign = 'center',
  fontWeight = 500,
  sx = {},
  ...props
}: CustomTypographyProps) => {
  const { spacing } = useTheme();
  return (
    <Typography
      variant='body2'
      color={'grey.600'}
      textAlign={textAlign}
      fontSize={spacing(1.6875)}
      fontWeight={fontWeight}
      sx={sx}
      {...props}
    >
      {children}
    </Typography>
  );
};

const MAX_STARTUP_AVATARS = 5;

const ListStartupAvatars = ({
  startups,
  isGreyedOut,
}: {
  startups: Startup[];
  isGreyedOut?: boolean;
}) => {
  const visibleStartups = startups.slice(0, MAX_STARTUP_AVATARS);
  const restOfStartups = startups.slice(MAX_STARTUP_AVATARS);
  const numStartups = startups.length;

  return (
    <Stack
      direction='row'
      gap={numStartups <= 3 ? 1 : 0.5}
      overflow='hidden'
      alignItems='center'
      justifyContent='center'
    >
      {visibleStartups.map(s => (
        <StartupIconAndName
          key={s.domain}
          startup={s}
          showName={numStartups <= 3}
          isGreyedOut={isGreyedOut}
          iconSize={
            [2, 3].includes(numStartups)
              ? 'x-huge'
              : numStartups === 1
                ? 'xx-huge'
                : 'medium'
          }
        />
      ))}
      {restOfStartups.length > 0 && (
        <BaseStyledSmallText>+{restOfStartups.length}</BaseStyledSmallText>
      )}
    </Stack>
  );
};

const NoCompanySelected = () => (
  <Stack justifyContent='center' gap={1}>
    <CustomTypography color='grey.400' marginY={2} fontWeight={800}>
      No company has been selected yet
    </CustomTypography>
  </Stack>
);

const renderListStartupAvatars = (startups: Startup[], isGreyedOut = false) => (
  <ListStartupAvatars startups={startups} isGreyedOut={isGreyedOut} />
);

const StartupIconAndName = ({
  startup,
  showName = true,
  isGreyedOut,
  iconSize,
}: StartupIconAndNameProps) => {
  const { palette, spacing } = useTheme();
  const { openStartupSidePanel } = useStartupSidePanel();
  return (
    <Stack
      alignItems='center'
      gap={1}
      minWidth={0}
      width={showName ? '100%' : 'unset'}
      onClick={() => {
        if (!startup.id) return;
        openStartupSidePanel(startup.id);
      }}
    >
      <BaseStartupAvatar
        size={iconSize ?? 'medium'}
        sx={{
          border: `1px solid ${palette.grey[300]}`,
          cursor: 'pointer',
          filter: isGreyedOut ? 'grayscale(100%)' : '',
        }}
        startup={startup}
      />
      {showName && (
        <CustomTypography
          color={isGreyedOut ? 'grey.400' : 'grey.700'}
          fontSize={iconSize === 'xx-huge' ? spacing(2) : spacing(1.6875)}
          fontWeight={800}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          {startup.name}
        </CustomTypography>
      )}
    </Stack>
  );
};

const SelectedStartup = ({ projectStage, startup }: SelectedStartupProps) => {
  const isDiscardedOrOnHold =
    projectStage === 'discarded' || projectStage === 'on_hold';

  return (
    <Stack justifyContent='center' alignItems='center' gap={1}>
      <CustomTypography marginBottom={1}>
        <ProjectStageText projectStage={projectStage} />
      </CustomTypography>
      <StartupIconAndName
        startup={startup}
        showName
        isGreyedOut={isDiscardedOrOnHold}
        iconSize='xx-huge'
      />
    </Stack>
  );
};

const ListedStartups = ({
  startups,
  label,
  isGreyedOut = false,
}: ListedStartupsProps) => {
  return (
    <Stack justifyContent='center' gap={1}>
      <CustomTypography marginBottom={1}>{label}</CustomTypography>
      {renderListStartupAvatars(startups, isGreyedOut)}
      <CustomTypography
        color={isGreyedOut ? 'grey.400' : 'grey.700'}
        fontSize={theme => theme.spacing(1.6875)}
        marginTop={1}
        fontWeight={800}
      >
        {startups.length >= 4 ? `${startups.length} startups` : ''}
      </CustomTypography>
    </Stack>
  );
};

const ProjectStageText = ({ projectStage }: ProjectStageTextProps) => {
  const renderText = () => {
    switch (projectStage) {
      case 'purchasing':
        return 'Selected for project';
      case 'testing':
        return 'Selected for testing';
      case 'test_success':
        return (
          <CustomTypography
            color='primary.main'
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              fontSize: theme => theme.spacing(1.6875),
              fontWeight: 800,
            }}
          >
            <CheckCircle fontSize='small' /> Successfully tested
          </CustomTypography>
        );
      case 'test_fail':
        return 'Failed test';
      case 'discarded':
        return 'Archived';
      case 'on_hold':
        return 'On hold';
      case 'adopted':
      case 'scaling':
      case 'adoption':
      case 'pilot_completed':
      case 'scaling_completed':
        return (
          <CustomTypography
            color='primary.main'
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              fontSize: theme => theme.spacing(1.6875),
              fontWeight: 800,
            }}
          >
            <CheckCircle fontSize='small' /> Implemented
          </CustomTypography>
        );
      default:
        return '';
    }
  };

  return <>{renderText()}</>;
};

export const ProjectSummaryStartupsAvatars: React.FC = () => {
  const { project, selectedStartup, shortlistedStartups, categorizedStartups } =
    useProjectContext() as ProjectScopeContext;
  const allStartups = useMemo(
    () =>
      categorizedStartups.map(cs => ({
        ...cs.startup,
        logo_url: cs.startup.logo_url || FALLBACK_SUPPLIER_LOGO_URL,
      })),
    [categorizedStartups],
  );

  const isDiscardedOrOnHold = ['on_hold', 'discarded'].includes(project.stage);
  const isDiscardedLead = project.lead_maturity === 'discarded_lead';
  const isLead = LEAD_STAGES.includes(project.stage as LeadStages);
  const companyIsYetToBeSelected =
    categorizedStartups.length === 0 ||
    (!isLead && !selectedStartup?.startup && shortlistedStartups.length === 0);

  const renderContent = () => {
    switch (true) {
      case companyIsYetToBeSelected:
        return <NoCompanySelected />;
      case isDiscardedOrOnHold:
        return (
          <Stack justifyContent='center' gap={1}>
            {selectedStartup?.startup ? (
              <SelectedStartup
                startup={{
                  ...selectedStartup.startup,
                  logo_url:
                    selectedStartup.startup.logo_url ||
                    FALLBACK_SUPPLIER_LOGO_URL,
                }}
                projectStage={project.stage}
              />
            ) : shortlistedStartups.length > 0 ? (
              <ListedStartups
                startups={shortlistedStartups.map(ss => ({
                  ...ss.startup,
                  logo_url: ss.startup.logo_url || FALLBACK_SUPPLIER_LOGO_URL,
                }))}
                label={startCase(project.stage)}
                isGreyedOut
              />
            ) : (
              allStartups.length > 0 && (
                <ListedStartups
                  label={startCase(project.stage)}
                  startups={allStartups}
                  isGreyedOut
                />
              )
            )}
          </Stack>
        );

      case isLead && allStartups.length > 0:
        return (
          <ListedStartups
            label={isDiscardedLead ? 'Archived' : 'In selected list(s)'}
            startups={allStartups}
            isGreyedOut={isDiscardedLead}
          />
        );

      case ![...LEAD_STAGES, 'assessment'].includes(project.stage) &&
        !!selectedStartup?.startup:
        return (
          <SelectedStartup
            projectStage={project.stage}
            startup={{
              ...selectedStartup.startup,
              logo_url:
                selectedStartup.startup.logo_url || FALLBACK_SUPPLIER_LOGO_URL,
            }}
          />
        );

      case project.stage === 'assessment' && shortlistedStartups.length > 0:
        return (
          <ListedStartups
            startups={shortlistedStartups.map(ss => ({
              ...ss.startup,
              logo_url: ss.startup.logo_url || FALLBACK_SUPPLIER_LOGO_URL,
            }))}
            label='Selected for demo'
          />
        );

      default:
        return null;
    }
  };

  return <Box marginY={1}>{renderContent()}</Box>;
};
