import { ArrowBackIos } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { DistanceFromDate } from 'components/shared/DistanceFromDate';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import Page from '../../components/Page';

import { Form, FormikProvider, useFormik } from 'formik';
import useInterval from 'hooks/useInterval';
import _ from 'lodash';
import {
  ProblemScopeProvider,
  useProblemScopeContext,
} from 'pages/request-form-v2/useProblemScopeContext';
import { useProblemScopeHelpers } from 'pages/request-form-v2/useProblemScopeHelpers';
import { identifyAnonymousUser } from 'plugins/Analytics';
import ProblemScopeFields, {
  ProblemScopeFieldsSkeleton,
} from './ProblemScopeFields';
import { SourcingOrderState } from './sourcingOrderModel';
import { EntityType } from 'components/projectLeadDetails/useLinkAndTrackPainPoint';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: { padding: spacing(3, 2) },
  stepper: { marginY: spacing(6) },
  card: { marginY: spacing(2) },
}));

export type RenderFooterProps = {
  isLoading?: boolean;
  errorMessage?: string;
};

export type UploadedFileId = { id: number | undefined } | undefined;
export type SourcingOrderRedirectOrigin =
  | 'lead'
  | 'list'
  | 'project'
  | 'new'
  | 'draft'
  | 'order-in-progress';

type ProblemScopeComponentProps = {
  preloadedState: SourcingOrderState;
  shownInModal?: boolean;
  closeOrderModal?: () => void;
  isByStakeholder?: boolean;
  categoryId?: number;
  isBUPage?: boolean;
  renderedAsSection?: boolean;
  initialPlaceToAssociatePainPoint?: 'startup_list' | 'project' | 'lead';
  setShowMoreOpen?: Dispatch<SetStateAction<boolean>>;
  showMoreOpen?: boolean;
};

export const ProblemScopeComponent = forwardRef<
  HTMLDivElement,
  ProblemScopeComponentProps
>(
  (
    {
      preloadedState,
      shownInModal,
      closeOrderModal,
      isByStakeholder = false,
      categoryId,
      isBUPage,
      renderedAsSection,
      initialPlaceToAssociatePainPoint,
      setShowMoreOpen,
      showMoreOpen,
    },
    ref, // ref is the second argument
  ) => {
    const contextValue = {
      preloadedState,
      shownInModal,
      closeOrderModal,
      isByStakeholder,
      categoryId,
      isBUPage,
      renderedAsSection,
      initialPlaceToAssociatePainPoint,
      setShowMoreOpen,
      showMoreOpen,
    };

    return (
      <ProblemScopeProvider value={contextValue}>
        <div {...(ref ? { ref } : {})}>
          <ProblemScopeContent />
        </div>
      </ProblemScopeProvider>
    );
  },
);

ProblemScopeComponent.displayName = 'ProblemScopeComponent';

function ProblemScopeContent() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { backToPage, orderOrigin } =
    (location.state as {
      backToPage?: string;
      orderOrigin?: SourcingOrderRedirectOrigin;
    }) || {};

  const currentOrganization = useCurrentOrganizationFromContext();
  const {
    preloadedState,
    shownInModal,
    closeOrderModal,
    isByStakeholder,
    categoryId,
    isBUPage,
    renderedAsSection,
    initialPlaceToAssociatePainPoint,
  } = useProblemScopeContext();

  const [state, setState] = useState<SourcingOrderState>(preloadedState);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isUploadingScopeFile, setIsUploadingScopeFile] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [whereToAssociatePainPoint, setWhereToAssociatePainPoint] = useState<
    'startup_list' | 'project' | 'lead' | undefined
  >(initialPlaceToAssociatePainPoint);

  const isRedirectFromAListOrNewDraft = [
    'draft',
    'new',
    'order-in-progress',
    'list',
  ].includes(orderOrigin ?? '');

  const navigateBack = useCallback(() => {
    if (backToPage) {
      navigate(backToPage);
    } else {
      navigate(-1);
    }
  }, [backToPage, navigate]);

  useEffect(() => {
    if (preloadedState.problemScopeId !== state.problemScopeId) {
      setState(preloadedState);
    }
  }, [preloadedState.problemScopeId, preloadedState, state.problemScopeId]);

  const {
    formik,
    isFormValuesUnchanged,
    sourcingOrderStatus,
    handleSaveFormData,
  } = useProblemScopeHelpers(
    state,
    setState,
    isByStakeholder,
    categoryId,
    setIsSaving,
    setIsUploadingScopeFile,
    navigateBack,
    whereToAssociatePainPoint,
  );

  const initialNonMutatedState = useRef(formik.initialValues).current;
  const valuesHaveChanged = !_.isEqual(initialNonMutatedState, formik.values);

  const shouldShowExtraConfirmationStep =
    // If a scope exists and has been updated
    (initialNonMutatedState.problemScopeId ? true : false) &&
    valuesHaveChanged &&
    !shownInModal &&
    !isRedirectFromAListOrNewDraft;

  // TODO: @fegoworks - autosave should be handled at each field level
  const shouldAutoSave =
    (!isFormValuesUnchanged && !shouldShowExtraConfirmationStep) ||
    (!isFormValuesUnchanged && (renderedAsSection || isBUPage));

  useEffect(() => {
    if (state?.stakeholder) {
      identifyAnonymousUser(
        {
          ...state.stakeholder,
          name: state.stakeholder.full_name || 'Unknown name',
        },
        {
          subdomain: currentOrganization.subdomain,
          id: currentOrganization.id,
        },
      );
    }
  }, [
    state?.stakeholder,
    currentOrganization.subdomain,
    currentOrganization.id,
  ]);

  useInterval(
    () => {
      if (shouldAutoSave) {
        handleSaveFormData();
      }
    },
    1000,
    [formik.values, orderOrigin],
  );

  const renderFooter = useCallback(
    (props: RenderFooterProps) => (
      <RenderFooterComponent
        {...props}
        props={props}
        sourcingOrderStatus={sourcingOrderStatus}
        isUploadingScopeFile={isUploadingScopeFile}
        isSaving={isSaving}
        formik={formik}
        state={state}
        closeOrderModal={closeOrderModal}
        navigateBack={navigateBack}
        handleSaveFormData={handleSaveFormData}
        showSaveConfirmation={showSaveConfirmation}
        setShowSaveConfirmation={setShowSaveConfirmation}
        shouldShowExtraConfirmationStep={shouldShowExtraConfirmationStep}
        setWhereToAssociatePainPoint={setWhereToAssociatePainPoint}
      />
    ),
    [
      sourcingOrderStatus,
      isUploadingScopeFile,
      isSaving,
      formik,
      state,
      closeOrderModal,
      navigateBack,
      handleSaveFormData,
      showSaveConfirmation,
      shouldShowExtraConfirmationStep,
    ],
  );

  if (!currentOrganization) return <ProblemScopeFieldsSkeleton />;

  return (
    <PageContent {...{ classes, navigateBack, formik, state, renderFooter }} />
  );
}

function PageContent({
  classes,
  navigateBack,
  formik,
  state,
  renderFooter,
}: {
  classes: Record<'root' | 'stepper' | 'card', string>;
  navigateBack: () => void;
  formik: ReturnType<typeof useFormik<SourcingOrderState>>;
  state: SourcingOrderState;
  renderFooter: (props: RenderFooterProps) => JSX.Element;
}) {
  const { shownInModal, isBUPage, renderedAsSection } =
    useProblemScopeContext();

  if (shownInModal) {
    return (
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate>
          <ProblemScopeFields state={state} renderFooter={renderFooter} />
        </Form>
      </FormikProvider>
    );
  }

  if (renderedAsSection) {
    return (
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate>
          <ProblemScopeFields state={state} />
        </Form>
      </FormikProvider>
    );
  }

  if (isBUPage) {
    return (
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate>
          <ProblemScopeFields state={state} />
        </Form>
      </FormikProvider>
    );
  }

  return (
    <Page
      title='Request form | GlassDollar'
      trackingTitle='Request Form'
      className={classes.root}
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography
          align='left'
          variant='h4'
          style={{ padding: 0, margin: 0, cursor: 'pointer' }}
          onClick={navigateBack}
        >
          <ArrowBackIos
            style={{
              position: 'relative',
              top: '-3px',
              width: '10px',
              fontSize: 'bold',
              verticalAlign: 'middle',
              marginRight: '5px',
            }}
          />
          Order a sourcing
        </Typography>
        {formik.values.updated_at && (
          <DistanceFromDate date={formik.values.updated_at} />
        )}
      </Stack>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate>
          <ProblemScopeFields state={state} renderFooter={renderFooter} />
        </Form>
      </FormikProvider>
    </Page>
  );
}

function RenderFooterComponent({
  props,
  sourcingOrderStatus,
  isUploadingScopeFile,
  isSaving,
  formik,
  state,
  closeOrderModal,
  navigateBack,
  handleSaveFormData,
  showSaveConfirmation,
  setShowSaveConfirmation,
  shouldShowExtraConfirmationStep = false,
  setWhereToAssociatePainPoint,
}: {
  props: RenderFooterProps;
  sourcingOrderStatus?: string | null;
  isUploadingScopeFile: boolean;
  isSaving: boolean;
  formik: ReturnType<typeof useFormik<SourcingOrderState>>;
  state: SourcingOrderState;
  closeOrderModal?: () => void;
  navigateBack: () => void;
  handleSaveFormData: (shouldUpdateExistingScope?: boolean) => void;
  showSaveConfirmation: boolean;
  setShowSaveConfirmation: (show: boolean) => void;
  shouldShowExtraConfirmationStep?: boolean;
  setWhereToAssociatePainPoint: React.Dispatch<
    React.SetStateAction<EntityType | undefined>
  >;
}) {
  const performAction = (afterSaveAction: () => void) => {
    if (shouldShowExtraConfirmationStep) {
      setShowSaveConfirmation(true);
      setWhereToAssociatePainPoint('startup_list');
    } else {
      handleSaveFormData();
      afterSaveAction();
    }
  };

  const handleSave = () =>
    performAction(() => (closeOrderModal ? closeOrderModal() : navigateBack()));

  const handleSubmit = () => performAction(() => formik.submitForm());

  const handleScopeAction = (shouldUpdateExistingScope = true) => {
    handleSaveFormData(shouldUpdateExistingScope);
    closeOrderModal ? closeOrderModal() : navigateBack();
  };

  return (
    <Stack direction='row' justifyContent='flex-end' marginLeft='auto' gap={1}>
      <LoadingButton
        variant={sourcingOrderStatus === 'draft' ? 'outlined' : 'contained'}
        onClick={handleSave}
        disabled={isUploadingScopeFile}
        loading={isUploadingScopeFile || isSaving || formik.isSubmitting}
      >
        {isUploadingScopeFile
          ? 'Uploading...'
          : !state.problemScopeId
            ? 'Save'
            : sourcingOrderStatus === 'draft'
              ? 'Save for later'
              : 'Update'}
      </LoadingButton>

      {sourcingOrderStatus === 'draft' && (
        <Tooltip title={props.errorMessage || ''} placement='top'>
          <span>
            <LoadingButton
              variant='contained'
              onClick={handleSubmit}
              disabled={
                isUploadingScopeFile ||
                formik.isSubmitting ||
                !formik.isValid ||
                isSaving
              }
              loading={isUploadingScopeFile || isSaving || formik.isSubmitting}
            >
              Submit
            </LoadingButton>
          </span>
        </Tooltip>
      )}

      <ScopeUpdateDialog
        open={showSaveConfirmation}
        onClose={() => setShowSaveConfirmation(false)}
        onUpdateScope={() => handleScopeAction()}
        onCreateScope={() => handleScopeAction(false)}
        scopeName={formik.values.title}
      />
    </Stack>
  );
}

const ScopeUpdateDialog = ({
  open,
  onClose,
  onUpdateScope,
  onCreateScope,
  scopeName,
}: {
  open: boolean;
  onClose: () => void;
  onUpdateScope: () => void;
  onCreateScope: () => void;
  scopeName: string;
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm'>
      <DialogTitle sx={{ marginBottom: 2 }}>Sourcing submitted!</DialogTitle>
      <DialogContent>
        <Typography variant='body1' sx={{ marginBottom: 3 }}>
          Would you like to update the scope <strong>{scopeName}</strong> or
          create a new scope?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={onCreateScope}>
          Create a new scope
        </Button>
        <Button variant='contained' onClick={onUpdateScope}>
          Update scope
        </Button>
      </DialogActions>
    </Dialog>
  );
};
