import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyTypeMap,
} from '@mui/material';
import LightTooltip from 'components/LightTooltip';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import { ReactNode, useEffect, useRef, useState } from 'react';

const TypographyWithEllipsis = ({
  tooltipTitle,
  tooltipVariant = 'default',
  children,
  numberOfLines = 1,
  placement = 'bottom',
  tooltipComponentProps,
  ...typographyProps
}: {
  tooltipComponentProps?: TooltipProps['slotProps'];
  numberOfLines?: number;
  tooltipTitle?: ReactNode;
  tooltipVariant?: 'default' | 'light';
  children: ReactNode;
  placement?: TooltipProps['placement'];
} & DefaultComponentProps<
  TypographyTypeMap<Record<string, unknown>, 'span'>
>) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(
        textElementRef.current.scrollWidth >
          textElementRef.current.clientWidth ||
          textElementRef.current.innerText.length > 100 ||
          (numberOfLines === 2 && textElementRef.current.innerText.length > 65),
      );
    }
  }, [textElementRef, numberOfLines]);

  const tooltipSharedProps = {
    title: tooltipTitle || '',
    disableHoverListener: !isOverflowed,
    placement,
    slotProps: tooltipComponentProps,
  };

  const typographySharedProps = {
    ...typographyProps,
    noWrap: true,
    ref: textElementRef,
    component: 'div' as const,
    sx: {
      '&:hover': {
        color: typographyProps.contentEditable ? 'secondary.main' : 'inherit',
      },
      ...typographyProps.sx,
    },
  };

  return tooltipVariant === 'light' ? (
    <LightTooltip {...tooltipSharedProps}>
      <Typography {...typographySharedProps}>{children}</Typography>
    </LightTooltip>
  ) : (
    <Tooltip {...tooltipSharedProps}>
      <Typography {...typographySharedProps}>{children}</Typography>
    </Tooltip>
  );
};

export default TypographyWithEllipsis;
