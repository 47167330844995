import {
  Box,
  Button,
  Divider,
  Skeleton,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useGetSourcingOrderByStartupListIdQuery } from 'apollo/generated/sdkInnovationManager';
import Markdown from 'components/Markdown';
import { BaseShowMoreOrLess } from 'components/base/BaseShowMoreLess';
import { motion } from 'framer-motion';
import useAuth from 'hooks/useAuth';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import React, {
  MouseEventHandler,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import SectionFieldLayout from './SectionFieldLayout';
import { ScopeHeader } from './sourcingOrder/SourcingOrderHeader';
import { SourcingOrderScopeContent as ScopeContent } from './sourcingOrder/SourcingOrderScopeContent';
import {
  AboutContext,
  EditDescriptionDialog,
  EditScopeDialog,
} from './startupList/details/AboutSection';
import { GLASSDOLLAR_EMAIL_DOMAIN } from 'config';

export const DEFAULT_COLLAPSE_MIN_HEIGHT = 100;
const MAX_CHARTS_INITIAL_HEIGHT = 200;
const MAX_CHARTS_INITIAL_HEIGHT_DIVIDER = 1.2685;
const CHARTS_HEIGHT_DIVIDER = 1.45;

type StyledBoxProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

type CollapsedScopeAndDescriptionProps = {
  label?: string;
  sx?: SxProps;
  className?: string;
  markup?: string | null;
  chartsHeight?: number | null;
  shouldAdjustToSibling: boolean;
  shouldRenderMetrics?: boolean;
  allowEdit?: boolean;
  offsetTop?: number;
  isDescriptionEmpty?: boolean;
  alwaysExpanded?: boolean;
  startupListId: number;
};

export const ActionButton = ({
  title,
  open = false,
  sx,
  onClick,
  onMouseUp,
}: {
  title: string;
  open?: boolean;
  sx?: SxProps;
  onClick?: () => void;
  onMouseUp?: MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  const theme = useTheme();
  const buttonStyles = {
    marginTop: open ? 0 : -4,
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightRegular,
    width: '200px',
    paddingX: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
    ...sx,
  };

  return (
    <Button
      sx={buttonStyles}
      style={{ marginTop: 0.5 }}
      onClick={onClick}
      onMouseUp={onMouseUp}
      size='small'
      variant='text'
    >
      {title}
    </Button>
  );
};

export const StyledBox = forwardRef<HTMLDivElement, StyledBoxProps>(
  ({ children, sx }, ref) => {
    return (
      <Box
        component={motion.div}
        ref={ref}
        sx={{
          ...(sx && { ...sx }),
          paddingBottom: 0,
          margin: 0,
        }}
      >
        {children}
      </Box>
    );
  },
);

StyledBox.displayName = 'StyledBox';

const CollapsedScopeAndDescription = ({
  label,
  sx,
  className,
  markup,
  chartsHeight,
  shouldAdjustToSibling,
  shouldRenderMetrics = false,
  allowEdit = false,
  offsetTop = 0,
  isDescriptionEmpty,
  startupListId,
}: CollapsedScopeAndDescriptionProps) => {
  const { user } = useAuth();
  const { setState, problemScope, problemScopeLoading } =
    useContext(AboutContext);
  const [loading, setLoading] = useState(true);
  const [collapseMinHeight, setCollapseMinHeight] = useState(
    DEFAULT_COLLAPSE_MIN_HEIGHT,
  );

  const { data: sourcingOrderData } = useGetSourcingOrderByStartupListIdQuery({
    variables: { startupListId: startupListId! },
    skip: !startupListId,
  });

  const openDescriptionEditModal = useCallback(
    () =>
      setState(prevState => ({
        ...prevState,
        isEditDialogOpen: true,
      })),
    [setState],
  );

  const openScopeEditModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      isEditScopeDialogOpen: true,
    }));
    captureAnalyticsEvent('Open add problem scope', {
      startupListId,
    });
  }, [setState, startupListId]);

  useEffect(() => {
    if (!shouldRenderMetrics && loading) {
      setLoading(false);
    }
  }, [shouldRenderMetrics, loading]);

  const isOneOfScopeOrDescriptionEmpty =
    !problemScope?.id || isDescriptionEmpty;

  useEffect(() => {
    if (!shouldAdjustToSibling) {
      setLoading(false);
      return;
    }

    if (chartsHeight) {
      let computedHeight = 0;
      const LARGE_CHARTS_HEIGHT_DIVIDER = 1.2599;
      // Set only once => when we have the initial height of the sibling
      if (collapseMinHeight !== DEFAULT_COLLAPSE_MIN_HEIGHT) return;

      if (isOneOfScopeOrDescriptionEmpty) {
        computedHeight =
          chartsHeight >= MAX_CHARTS_INITIAL_HEIGHT
            ? chartsHeight / LARGE_CHARTS_HEIGHT_DIVIDER
            : chartsHeight - offsetTop;
      } else {
        computedHeight =
          chartsHeight > MAX_CHARTS_INITIAL_HEIGHT
            ? chartsHeight / MAX_CHARTS_INITIAL_HEIGHT_DIVIDER
            : chartsHeight / CHARTS_HEIGHT_DIVIDER;
      }

      setCollapseMinHeight(computedHeight);
      setLoading(false);
    }
  }, [
    collapseMinHeight,
    offsetTop,
    shouldAdjustToSibling,
    chartsHeight,
    isDescriptionEmpty,
    problemScope?.id,
    isOneOfScopeOrDescriptionEmpty,
  ]);

  const isScopeContentEmpty = !problemScope?.id;
  const isDescriptionContentEmpty = !markup || isDescriptionEmpty;

  const isDescriptionEmptyAndScopeEmpty =
    isDescriptionContentEmpty && isScopeContentEmpty;

  const shouldAllowScopeEdit =
    allowEdit &&
    (sourcingOrderData?.sourcing_order[0]?.status !== 'in_progress' ||
      user?.email.includes(GLASSDOLLAR_EMAIL_DOMAIN));

  const scopeContent = useMemo(
    () => (
      <BaseShowMoreOrLess
        content={
          <>
            {!isScopeContentEmpty && (
              <ScopeHeader
                sourcingOrder={sourcingOrderData?.sourcing_order[0]}
                // currentStartupListId={startupListId}
                // currentPainPointId={problemScope?.id}
              />
            )}
            <ScopeContent
              problemScope={!problemScopeLoading ? problemScope : null}
            />
          </>
        }
        editDialogContent={<EditScopeDialog startupListId={startupListId} />}
        onOpenEditDialog={openScopeEditModal}
        editTitle={isScopeContentEmpty ? 'Add pain point' : 'Edit'}
        allowEdit={shouldAllowScopeEdit}
        nonAllowEditText='No problem scope provided'
        collapsedSize={
          shouldRenderMetrics
            ? `${collapseMinHeight}px`
            : `${DEFAULT_COLLAPSE_MIN_HEIGHT}`
        }
      />
    ),
    [
      sourcingOrderData,
      problemScope,
      problemScopeLoading,
      openScopeEditModal,
      isScopeContentEmpty,
      shouldAllowScopeEdit,
      shouldRenderMetrics,
      collapseMinHeight,
      startupListId,
    ],
  );

  const descriptionContent = useMemo(
    () => (
      <BaseShowMoreOrLess
        content={
          <>
            {!isDescriptionContentEmpty && (
              <Typography variant='h5' fontWeight={500} marginBottom={1}>
                Description
              </Typography>
            )}
            <Typography variant='body2' whiteSpace='pre-line' component={'div'}>
              <Markdown className={className}>{markup || ''}</Markdown>
            </Typography>
          </>
        }
        editDialogContent={<EditDescriptionDialog />}
        allowEdit={allowEdit}
        onOpenEditDialog={openDescriptionEditModal}
        editTitle={isDescriptionContentEmpty ? 'Add a description' : 'Edit'}
        nonAllowEditText='No description provided'
        collapsedSize={
          shouldRenderMetrics
            ? `${collapseMinHeight}px`
            : `${DEFAULT_COLLAPSE_MIN_HEIGHT}`
        }
      />
    ),
    [
      className,
      markup,
      allowEdit,
      openDescriptionEditModal,
      isDescriptionContentEmpty,
      shouldRenderMetrics,
      collapseMinHeight,
    ],
  );

  const contentDivider = useMemo(
    () =>
      shouldRenderMetrics &&
      !isDescriptionContentEmpty &&
      !isScopeContentEmpty && <Divider sx={{ height: 15, marginBottom: 2 }} />,
    [shouldRenderMetrics, isDescriptionContentEmpty, isScopeContentEmpty],
  );

  if (loading || problemScopeLoading)
    return (
      <Stack height={150}>
        <Skeleton variant='text' height='100%' />
        <Skeleton variant='text' height='100%' />
        <Skeleton variant='text' height='100%' />
        <Skeleton variant='text' height='100%' />
        <Skeleton variant='text' height='100%' />
      </Stack>
    );

  const boxFieldStyling = {
    flex: '1 1 0',
    wordWrap: 'break-word',
    width: shouldRenderMetrics || isOneOfScopeOrDescriptionEmpty ? '100%' : 0,
  };

  return (
    <SectionFieldLayout
      title={label}
      sx={{
        ...sx,
        ...(isDescriptionEmptyAndScopeEmpty && !shouldRenderMetrics
          ? {
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
            }
          : isOneOfScopeOrDescriptionEmpty && !shouldRenderMetrics
            ? {
                display: 'flex',
                flexDirection: 'column',
              }
            : !shouldRenderMetrics
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 2,
                }
              : {}),
      }}
    >
      {isScopeContentEmpty && !isDescriptionContentEmpty ? (
        <>
          <Box sx={{ ...boxFieldStyling }}>{descriptionContent}</Box>
          {contentDivider}
          <Box sx={{ ...boxFieldStyling }}>{scopeContent}</Box>
        </>
      ) : (
        <>
          <Box sx={{ ...boxFieldStyling }}>{scopeContent}</Box>
          {contentDivider}
          <Box sx={{ ...boxFieldStyling }}>{descriptionContent}</Box>
        </>
      )}
    </SectionFieldLayout>
  );
};

export default CollapsedScopeAndDescription;
