import {
  Domain,
  DomainDisabled,
  ExpandLess,
  ExpandMore,
  FactCheck,
  Merge,
} from '@mui/icons-material';
import { Collapse, MenuItem, MenuList, Typography } from '@mui/material';
import {
  CustomEnumHealthStatus,
  StartupSignalLabels,
} from 'apollo/generated/sdkInnovationManager';
import { useSetHealthStatusMutation } from 'apollo/generated/sdkShared';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type SignalLabel = Pick<StartupSignalLabels, 'id' | 'value'>;

export const useToggleInactiveLabel = ({
  startupId,
  signalLabels,
  color,
}: {
  startupId: number;
  signalLabels: SignalLabel[];
  color: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isMarkStartupSubmenuOpen, setIsMarkStartupSubmenuOpen] =
    useState(false);

  const [setHealthStatus] = useSetHealthStatusMutation();

  const handleMarkStartupSubmenuClick = () => {
    setIsMarkStartupSubmenuOpen(!isMarkStartupSubmenuOpen);
  };

  const handleStatusChange = (status: CustomEnumHealthStatus) => {
    setHealthStatus({
      variables: {
        startupId,
        status,
      },
    }).catch(() => {
      enqueueSnackbar('Failed to update startup status.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    });
  };

  const status = signalLabels.some(l => l.value === 'Inactive')
    ? 'DEAD'
    : signalLabels.some(l => l.value === 'Acquired')
      ? 'ACQUIRED'
      : 'ACTIVE';

  const ToggleInactiveLabelMenu = () => (
    <MenuList>
      <MenuItem
        sx={{
          color,
        }}
        onClick={handleMarkStartupSubmenuClick}
      >
        <FactCheck fontSize='small' sx={{ marginRight: 0.5 }} />
        <Typography variant='body2'>Mark as...</Typography>
        {isMarkStartupSubmenuOpen ? (
          <ExpandLess
            fontSize='small'
            sx={{
              marginLeft: 'auto',
            }}
          />
        ) : (
          <ExpandMore
            fontSize='small'
            sx={{
              marginLeft: 'auto',
            }}
          />
        )}
      </MenuItem>
      <Collapse in={isMarkStartupSubmenuOpen} timeout='auto' unmountOnExit>
        <MenuItem
          onClick={() => {
            handleStatusChange('ACTIVE');
          }}
          disabled={status === 'ACTIVE'}
          key='mark_as_active'
          sx={{
            color,
          }}
        >
          <Domain fontSize='small' sx={{ marginLeft: 2.5, marginRight: 0.5 }} />
          <Typography variant='body2'>Active</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleStatusChange('DEAD');
          }}
          disabled={status === 'DEAD'}
          key='mark_as_inactive'
          sx={{
            color,
          }}
        >
          <DomainDisabled
            fontSize='small'
            sx={{ marginLeft: 2.5, marginRight: 0.5 }}
          />
          <Typography variant='body2'>Dead</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleStatusChange('ACQUIRED');
          }}
          disabled={status === 'ACQUIRED'}
          key='mark_as_inactive'
          sx={{
            color,
          }}
        >
          <Merge fontSize='small' sx={{ marginLeft: 2.5, marginRight: 0.5 }} />
          <Typography variant='body2'>Acquired</Typography>
        </MenuItem>
      </Collapse>
    </MenuList>
  );

  return { ToggleInactiveLabelMenu };
};
