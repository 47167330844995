import { AutoAwesome, CopyAll } from '@mui/icons-material';
import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  EnumTablePriorityEnum,
  EnumTableStakeholderMaturityEnum,
} from 'apollo/generated/sdkInnovationManager';
import {
  EnumTableStakeholderTypeEnum,
  PeopleSetInput,
  PersonDataFragment,
  useCreatePersonNoteMutation,
  useEnrichStakeholderMutation,
  useGetPersonDetailsQuery,
  usePersonProfileMutation,
} from 'apollo/generated/sdkShared';
import { varFadeIn } from 'components/animate';
import BaseErrorMessage from 'components/base/BaseErrorMessage';
import ContentEditableText from 'components/shared/ContentEditableText';
import SendEmailButton from 'components/shared/SendEmailButton';
import AssignTeamButton from 'components/teams/AssignTeamButton';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { format, formatDistance } from 'date-fns';
import { motion } from 'framer-motion';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useCallback, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useLocation, useNavigate, useParams } from 'react-router';
import { PATH_PARAMS, PATH_ROOT } from 'routes/paths';
import { PersonTagsMultiselect } from '../PersonTagsMultiselect';
import {
  maturityValueOptions,
  priorityValueOptions,
  stakeholderTypeValueOptions,
} from '../useGetColumns';
import DetailsDrawerSkeleton from './DetailsDrawerSkeleton';
import ProjectsAndRequestsForPerson from '../ProjectsAndLeadsForPerson';
import { PersonNotesSection } from 'components/notes/PersonNotesSection';
import { PersonInterestsAutocomplete } from './PersonInterestsAutocomplete';
import { LoadingButton } from '@mui/lab';
import MoreMenu from 'components/MoreMenu';
import { captureException } from '@sentry/react';
import LeadsAssistantIcon from 'layouts/dashboard/assets/leads-assistant.svg?react';
import { useDebouncedCallback } from 'use-debounce';
import SelectDepartment from './SelectDepartment';

const DrawerContent = ({ personId }: { personId: number }) => {
  const theme = useTheme();
  const { has_teams, leadgen_copilot_enabled } =
    useCurrentOrganizationFromContext();
  const [updatePerson] = usePersonProfileMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, error } = useGetPersonDetailsQuery({
    variables: { id: personId },
    skip: !personId,
  });
  const navigate = useNavigate();
  const [createPersonNote] = useCreatePersonNoteMutation();
  const [refreshNotes, setRefreshNotes] = useState(false);
  const [enrichStakeholder] = useEnrichStakeholderMutation();

  const isUserProfile = data?.people_by_pk?.user?.id;

  const handleEdit = useCallback(
    (object: PeopleSetInput) => {
      updatePerson({
        variables: {
          person_id: personId,
          object,
        },
      });
    },
    [personId, updatePerson],
  );

  const handleStakeholderEnrich = async () => {
    enqueueSnackbar('Enriching stakeholder...', {
      variant: 'info',
    });

    try {
      const { data } = await enrichStakeholder({
        variables: {
          personId,
        },
      });

      if (!data?.enrich_stakeholder?.success) {
        throw new Error('Action failed to enrich stakeholder');
      }

      await createPersonNote({
        variables: {
          personId,
          body: data?.enrich_stakeholder?.note,
        },
      });
    } catch (error) {
      enqueueSnackbar('Error enriching stakeholder', {
        variant: 'error',
      });
      captureException(error);
    }

    setRefreshNotes(prev => !prev);
  };

  if (loading) return <DetailsDrawerSkeleton />;

  if (error || !data?.people_by_pk) return <BaseErrorMessage />;

  const person = data.people_by_pk;

  const isInnovationUnitMember = person.user?.type === 'innovation_manager';
  const addressingTheStakeholderPart = person.full_name.includes('@')
    ? 'this stakeholder'
    : person.full_name;

  return (
    <motion.div {...varFadeIn}>
      <Stack gap={2}>
        <Stack marginTop={2} paddingX={2} direction='row' gap={2}>
          <Stack>
            <Stack direction='row' gap={0.5}>
              <Stack direction='row' gap={1}>
                <ContentEditableText
                  variant='body2'
                  sx={{ color: 'text.secondary', fontSize: '1.2rem' }}
                  isEditable
                  text={person.full_name}
                  onBlur={event => {
                    handleEdit({ full_name: event.target.textContent! });
                  }}
                />
                <SendEmailButton
                  size='small'
                  emails={[person.email]}
                  onClick={() => {
                    captureAnalyticsEvent('Stakeholder contacted via email', {
                      stakeholderEmail: person.email,
                      stakeholderId: person.id,
                      stakeholderName: person.full_name,
                    });
                  }}
                />
              </Stack>
            </Stack>
            <SelectDepartment
              department={person.organization_department}
              personId={person.id}
            />

            {has_teams && (
              <AssignTeamButton
                name={person.full_name}
                readonly
                id={person.id}
                team={person.team}
                sxProps={{
                  alignSelf: 'flex-start',
                  paddingY: 0,
                  fontWeight: 300,
                  color: 'grey.600',
                  fontSize: '0.8rem',
                }}
              />
            )}
            {!isUserProfile && (
              <PersonTagsMultiselect
                stakeholderId={person.id}
                stakeholderTags={
                  person.tags.map(t => ({
                    id: t.id,
                    name: t.tag.name,
                  })) || []
                }
                sx={{ margin: '8px 0px', maxWidth: 'unset' }}
              />
            )}
          </Stack>
          {leadgen_copilot_enabled && (
            <Stack marginLeft='auto' alignContent='center'>
              <Tooltip
                title={`Start a Leads Assistant conversation based on ${addressingTheStakeholderPart}'s department, interest and tags.`}
              >
                <span>
                  <LoadingButton
                    sx={{
                      '& path': {
                        fill: theme.palette.primary.main,
                      },
                      '&:disabled path': {
                        display: 'none',
                      },
                    }}
                    startIcon={<LeadsAssistantIcon height={24} width={24} />}
                    onClick={() =>
                      navigate(
                        PATH_ROOT.leadsAssistant.newConversation(person.id),
                      )
                    }
                  >
                    Generate outreach email
                  </LoadingButton>
                </span>
              </Tooltip>
            </Stack>
          )}
          <Stack>
            <MoreMenu>
              <Tooltip
                title={
                  !Boolean(person.full_name)
                    ? 'Stakeholder must have a full name to be enriched'
                    : 'Enrich stakeholder'
                }
              >
                <MenuItem
                  onClick={handleStakeholderEnrich}
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                  disabled={!Boolean(person.full_name)}
                >
                  <AutoAwesome />
                  <Typography variant='body2' sx={{ marginLeft: 0.5 }}>
                    Enrich
                  </Typography>
                </MenuItem>
              </Tooltip>
            </MoreMenu>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          paddingX={2}
        >
          <Typography variant='body2' fontSize='0.8rem'>
            {person.email}
            <CopyToClipboard
              text={person.email}
              onCopy={() => {
                enqueueSnackbar(`${person.email} copied`, {
                  variant: 'success',
                });
                captureAnalyticsEvent('Stakeholder email copied', {
                  stakeholderEmail: person.email,
                  stakeholderId: person.id,
                  stakeholderName: person.full_name,
                });
              }}
            >
              <IconButton size='small' color='secondary'>
                <CopyAll fontSize='small' />
              </IconButton>
            </CopyToClipboard>
          </Typography>
          <Tooltip title={format(new Date(person.created_at), 'yyyy-MM-dd')}>
            <Typography variant='body2' fontSize='0.7rem'>
              Joined{' '}
              {formatDistance(new Date(person.created_at), new Date(), {
                addSuffix: true,
              })}
            </Typography>
          </Tooltip>
        </Stack>
        <Stack gap={4} paddingX={2} paddingBottom={2}>
          {!isInnovationUnitMember && (
            <LeadData person={person} handleEdit={handleEdit} />
          )}
          <ProjectsAndRequestsForPerson
            notes={
              (person.user?.startup_notes?.length || 0) +
              (person.user?.project_notes?.length || 0)
            }
            projects={person.projects_involved_in}
          />
        </Stack>
      </Stack>
      <Stack gap={4} paddingX={2} paddingBottom={2}>
        <PersonNotesSection personId={person.id} refresh={refreshNotes} />
      </Stack>
    </motion.div>
  );
};

const PersonDetailsDrawer = () => {
  const params = useParams();
  const personId = params[PATH_PARAMS.personId];

  const navigate = useNavigate();
  const { isSharedPage } = useSharedPagesContext();
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };

  if (isSharedPage) {
    return null;
  }

  const handleClose = () => {
    if (state?.backgroundLocation) {
      navigate(-1);
    } else {
      navigate(PATH_ROOT.stakeholders.root);
    }
  };

  return (
    <Drawer
      slotProps={{
        backdrop: { className: 'stakeholder-details-drawer-backdrop' },
      }}
      data-testid='person-details-drawer'
      open
      onClose={handleClose}
      anchor='right'
      sx={{ width: 800 }}
      PaperProps={{ sx: { width: 800 } }}
    >
      {personId && <DrawerContent personId={Number(personId)} />}
    </Drawer>
  );
};

function LeadData({
  person,
  handleEdit,
}: {
  person: PersonDataFragment;
  handleEdit: (object: PeopleSetInput) => void;
}) {
  const handleDebouncedEdit = useDebouncedCallback(handleEdit, 1000);
  const [jobTitle, setJobTitle] = useState(person.job_title || '');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label='Job title'
          variant='standard'
          fullWidth
          data-testid='person-details__job-title'
          value={jobTitle}
          onChange={event => {
            const newValue = event.target.value;
            setJobTitle(newValue);
            handleDebouncedEdit({ job_title: newValue });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='User type'
          select
          fullWidth
          data-testid='person-details__user-type'
          variant='standard'
          value={
            // Allow us to deprecated the innovation_manager type without deleting it from the DB
            !!person.stakeholder_type &&
            person.stakeholder_type !== 'innovation_manager'
              ? person.stakeholder_type
              : ''
          }
          onChange={event => {
            if (!event.target.value) return;

            handleEdit({
              stakeholder_type: event.target
                .value as EnumTableStakeholderTypeEnum,
            });

            captureAnalyticsEvent('Stakeholder metadata updated', {
              stakeholderEmail: person.email,
              stakeholderId: person.id,
              stakeholderName: person.full_name,
              dataType: 'stakeholder_type',
            });
          }}
        >
          {stakeholderTypeValueOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Priority'
          select
          variant='standard'
          data-testid='person-details__priority'
          fullWidth
          value={person.priority || ''}
          onChange={event => {
            if (!event.target.value) return;

            handleEdit({
              priority: event.target.value as EnumTablePriorityEnum,
            });

            captureAnalyticsEvent('Stakeholder metadata updated', {
              stakeholderEmail: person.email,
              stakeholderId: person.id,
              stakeholderName: person.full_name,
              dataType: 'priority',
            });
          }}
        >
          {priorityValueOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Maturity'
          select
          data-testid='person-details__maturity'
          fullWidth
          variant='standard'
          value={person.maturity || ''}
          onChange={event => {
            if (!event.target.value) return;

            handleEdit({
              maturity: event.target.value as EnumTableStakeholderMaturityEnum,
            });

            captureAnalyticsEvent('Stakeholder metadata updated', {
              stakeholderEmail: person.email,
              stakeholderId: person.id,
              stakeholderName: person.full_name,
              dataType: 'maturity',
            });
          }}
        >
          {maturityValueOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <PersonInterestsAutocomplete person={person} />
      </Grid>
    </Grid>
  );
}

export default PersonDetailsDrawer;
