import { useApolloClient } from '@apollo/client';
import {
  GetSourcingOrdersDocument,
  useSubmitDraftSourcingOrderMutation,
  useUpdateProjectMutation,
  useUpdateStartupListMutation,
} from 'apollo/generated/sdkInnovationManager';
import {
  GetFilesByProblemScopeIdDocument,
  GetProjectForScopeDocument,
  GetProjectForScopeQuery,
  GetStartupListForScopeDocument,
  GetStartupListForScopeQuery,
  useCreateOrUpdateProblemScopeMutation,
} from 'apollo/generated/sdkShared';
import { ProblemScope } from 'components/dashboard/sourcingOrder/types';
import { useUpdateStartupListActivities } from 'components/dashboard/startupList/useUpdateStartupListActivities';
import { ORGANIZATION_QUERY_FOR_REFRESH } from 'contexts/CurrentOrganizationContext';
import { useFormik } from 'formik';
import useAddStartupToList from 'hooks/useAddStartupToList';
import _, { differenceBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { SourcingOrderState } from 'pages/request-form/sourcingOrderModel';
import { useUploadProblemScopeFile } from 'pages/request-form/useScopeFiles';
import { validationSchema } from 'pages/request-form/validationSchema';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { KnownStartup } from '../../@types/startupList';
import { EntityType } from 'components/projectLeadDetails/useLinkAndTrackPainPoint';

export type UploadedFileId = { id: number | undefined } | undefined;

export function useProblemScopeHelpers(
  state: SourcingOrderState,
  setState: React.Dispatch<React.SetStateAction<SourcingOrderState>>,
  isByStakeholder: boolean,
  categoryId: number | undefined,
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
  setIsUploadingScopeFile: React.Dispatch<React.SetStateAction<boolean>>,
  navigateBack: () => void,
  whereToAssociatePainPoint: EntityType | undefined,
) {
  const [createOrUpdateProblemScope] = useCreateOrUpdateProblemScopeMutation();
  const [updateStartupListWithScope] = useUpdateStartupListMutation();
  const [submitDraftSourcingOrder] = useSubmitDraftSourcingOrderMutation();
  const [updateProjectWithScope] = useUpdateProjectMutation();
  const { addStartupToList } = useAddStartupToList();
  const uploadProblemScopeFile = useUploadProblemScopeFile();
  const { logStartupListActivity } = useUpdateStartupListActivities();
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();

  const validationSchemas = validationSchema();
  const currentValidationSchema = validationSchemas['Briefing Form Validation'];

  const formik = useFormik<SourcingOrderState>({
    enableReinitialize: true,
    initialValues: state,
    validationSchema: currentValidationSchema,
    onSubmit: async (values, actions) => {
      await handleFormSubmit(values, actions);
    },
  });

  const isFormValuesUnchanged = _.isEqual(formik.initialValues, formik.values);
  const sourcingOrderStatus = formik.values.status;

  const getWhereToAssociatePainPoint = () => {
    let associationType: 'project' | 'startup_list' | undefined = undefined;

    if (
      whereToAssociatePainPoint === 'lead' ||
      whereToAssociatePainPoint === 'project'
    ) {
      associationType = 'project';
    } else if (whereToAssociatePainPoint === 'startup_list') {
      associationType = 'startup_list';
    } else if (formik.values.startupListId && !formik.values.projectId) {
      associationType = 'startup_list';
    } else if (formik.values.projectId) {
      associationType = 'project';
    }

    return associationType;
  };

  async function handleFormSubmit(
    values: SourcingOrderState,
    actions: { setSubmitting: (isSubmitting: boolean) => void },
  ) {
    await submitDraftSourcingOrder({
      variables: {
        object: {
          sourcing_order_id: values.id,
        },
      },
      refetchQueries: [
        { query: ORGANIZATION_QUERY_FOR_REFRESH },
        { query: GetSourcingOrdersDocument },
      ],
    });
    actions.setSubmitting(false);
    enqueueSnackbar('Your sourcing has been ordered', { variant: 'success' });
    navigateBack();
  }

  async function handleSaveFormData(shouldUpdateExistingScope = true) {
    setIsSaving(true);
    setState(formik.values);
    const associationType = getWhereToAssociatePainPoint();

    const { problemScopeId, problemScope } =
      await createOrUpdateProblemScopeEntry(
        formik.values,
        shouldUpdateExistingScope,
      );

    if (!problemScopeId) {
      enqueueSnackbar('Failed to create or update problem scope', {
        variant: 'error',
      });
      setIsSaving(false);
      return;
    }

    const fileIdsUploaded = await uploadProblemScopeFiles(problemScopeId);
    await addKnownStartupsToListIfRequired();

    if (associationType === 'project') {
      await associateProblemScopeWithProject(problemScopeId);
    } else if (associationType === 'startup_list') {
      await associateProblemScopeWithStartupList(problemScopeId);
      await logStartupListActivityEntries(problemScopeId, fileIdsUploaded);
    } else {
      console.warn('No valid association type found for problem scope.');
    }

    updateStateAndCacheData(problemScope, fileIdsUploaded);
    setIsSaving(false);
  }

  async function createOrUpdateProblemScopeEntry(
    values: SourcingOrderState,
    shouldUpdateExistingScope = true,
  ) {
    const fieldsToInclude: (keyof KnownStartup)[] = [
      'domain',
      'name',
      'product',
      'logo',
      'excludeWhenBenchmarking',
    ];

    const scopedKnownStartups: KnownStartup[] = values.known_startups.map(
      (startup: KnownStartup) =>
        Object.fromEntries(
          fieldsToInclude
            .map(key => [key, startup[key]])
            .filter(([, value]) => value !== undefined),
        ) as KnownStartup,
    );

    const createdScope = await createOrUpdateProblemScope({
      variables: {
        object: {
          ...(shouldUpdateExistingScope ? { id: values.problemScopeId } : {}),
          additional_info: values.additional_info,
          desired_solution: values.desired_solution,
          must_have_features: values.must_have_features,
          nice_to_have_features: values.nice_to_have_features,
          problem: values.problem,
          known_startups: scopedKnownStartups,
        },
      },
    });

    return {
      problemScope: createdScope.data?.insert_problem_scopes_one,
      problemScopeId: createdScope.data?.insert_problem_scopes_one?.id,
    };
  }

  async function associateProblemScopeWithStartupList(problemScopeId: number) {
    if (formik.values.startupListId) {
      await updateStartupListWithScope({
        variables: {
          id: formik.values.startupListId,
          payload: {
            scope_id: problemScopeId,
          },
        },
      });
    }
  }

  async function associateProblemScopeWithProject(problemScopeId: number) {
    if (formik.values.projectId) {
      await updateProjectWithScope({
        variables: {
          id: formik.values.projectId,
          payload: {
            scope_id: problemScopeId,
          },
        },
      });
    }
  }

  async function addKnownStartupsToListIfRequired() {
    if (formik.values.known_startups.length === 0 || !isByStakeholder) return;

    const newStartupsToAdd = differenceBy(
      formik.values.known_startups as KnownStartup[],
      (formik.initialValues?.known_startups as KnownStartup[]) || [],
      'domain',
    );

    for (const startup of newStartupsToAdd) {
      await addStartupToList({
        selectedStartup: {
          ...startup,
        },
        listId: formik.values.startupListId,
        categoryId: categoryId,
      });
    }
  }

  async function uploadProblemScopeFiles(problemScopeId: number) {
    if (formik.values.scope_files.length === 0) return [];

    const newFilesToUpload = differenceBy(
      formik.values.scope_files,
      formik.initialValues.scope_files,
      'name',
    );

    if (newFilesToUpload.length === 0) return [];

    setIsUploadingScopeFile(true);

    try {
      const fileIds = await Promise.all(
        newFilesToUpload.map(file =>
          uploadProblemScopeFile(file, problemScopeId),
        ),
      );
      captureAnalyticsEvent('File added to scope', {
        listId: formik.values.startupListId!,
      });
      return fileIds;
    } catch (error) {
      enqueueSnackbar('Failed to upload file', { variant: 'error' });
      return [];
    } finally {
      setIsUploadingScopeFile(false);
    }
  }

  function updateStateAndCacheData(
    problemScope: ProblemScope,
    fileIds: UploadedFileId[],
  ) {
    if (!formik.values.problemScopeId && problemScope?.id) {
      setState(prevState => ({
        ...prevState,
        problemScopeId: problemScope?.id,
      }));

      if (getWhereToAssociatePainPoint() === 'startup_list') {
        const observableQuery =
          apolloClient.watchQuery<GetStartupListForScopeQuery>({
            query: GetStartupListForScopeDocument,
            variables: {
              startupListId: formik.values.startupListId,
              publicUUID: '',
            },
          });

        observableQuery.refetch();
      } else {
        const observableQuery =
          apolloClient.watchQuery<GetProjectForScopeQuery>({
            query: GetProjectForScopeDocument,
            variables: { projectId: formik.values.projectId },
          });

        observableQuery.refetch();
      }
    }

    if (fileIds.length > 0) {
      const observableQuery = apolloClient.watchQuery({
        query: GetFilesByProblemScopeIdDocument,
        variables: { problemScopeId: formik.values.problemScopeId },
      });
      observableQuery.refetch();
    }
  }

  async function logStartupListActivityEntries(
    problemScopeId: number,
    fileIds: UploadedFileId[],
  ) {
    const activityLogs: {
      action: string;
      entityIds: number | number[];
      entityType: string;
    }[] = [
      {
        action: 'created',
        entityIds: problemScopeId,
        entityType: 'problem_scopes',
      },
    ];

    if (fileIds.length > 0) {
      activityLogs.push({
        action: 'created',
        entityIds: fileIds
          .map(file => file?.id)
          .filter((id): id is number => id !== undefined),
        entityType: 'problem_scope_files',
      });
    }

    await logStartupListActivity({
      logs: activityLogs,
      startupListId: formik.values.startupListId!,
    });
  }

  return {
    formik,
    handleSaveFormData,
    isFormValuesUnchanged,
    sourcingOrderStatus,
  };
}
