import { DOMAIN_COLORS } from 'theme/palette';
import {
  EnumTableProjectHealthStatusesEnum,
  EnumTableProjectCategoriesEnum,
} from 'apollo/generated/sdkInnovationManager';

export type StatusMap = {
  [key in EnumTableProjectHealthStatusesEnum]: string;
};

export const STATUS_COLOR_MAPPING: StatusMap = {
  green: DOMAIN_COLORS.projectStatus.green,
  amber: DOMAIN_COLORS.projectStatus.amber,
  red: DOMAIN_COLORS.projectStatus.red,
};

export const PROJECT_STATUSES: EnumTableProjectHealthStatusesEnum[] = [
  'green',
  'amber',
  'red',
];

export type PROJECT_CATEGORIES_TYPE = Extract<
  EnumTableProjectCategoriesEnum,
  'product_innovation' | 'process_innovation'
>;

export const PROJECT_CATEGORIES: PROJECT_CATEGORIES_TYPE[] = [
  'product_innovation',
  'process_innovation',
];

export const PROJECT_CATEGORIES_MAP: Record<PROJECT_CATEGORIES_TYPE, string> = {
  product_innovation: 'Product Innovation',
  process_innovation: 'Process Innovation',
};

export const HIDE_FROM_LIBRARY_TAG = 'One-startup lead';
