import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';
import { TabType as LeadTabType } from 'components/projectLeadDetails/ProjectLeadDetails';
import { SEARCH_PARAMS, SEARCH_TYPES } from 'config';
import { TabType } from 'pages/dashboard/ProjectDetailsPage';
import { indexOfStage } from 'utils/projectStageEnum';
import { buildStartupPlatformDomain, getRootUrl } from 'utils/url';

/**
 * This Params identified specific entities. E.g. if you use startupListId
 * you are telling the code that it is in the context of a Startup List right now
 */
export const PATH_PARAMS = {
  startupListId: 'startupListId',
  categoryId: 'categoryId',
  personId: 'personId',
  startup_id: 'startup_id',
  projectId: 'projectId',
  currentStage: 'currentStage',
  nextStage: 'nextStage',
  challengeId: 'challengeId',
};

export const PATH_NAME = {
  addToList: 'add-to-list',
  analytics: 'analytics',
  auth: 'auth',
  shared: 'shared',
  portalRoot: 'o',
  briefUs: 'brief-us',
  category: 'category',
  createList: 'create-project',
  createProjectFromInspiration: 'create-project-from-inspiration',
  discovery: 'discovery',
  // Engagement
  requests: 'requests',
  personProfile: 'person-profile',
  portalHome: 'explore',
  portalAbout: 'about',
  //
  projectLeads: 'project-leads',
  pocs: 'pocs',
  adoptions: 'adoptions',
  list: 'list',
  lists: 'lists',
  startups: 'startups',
  login: 'login',
  loginUnprotected: 'login-unprotected',
  orderSourcing: 'order-sourcing',
  preview: 'preview',
  redirectToLogin: 'redirect-to-login',
  scout: 'scout',
  scoping: 'scoping',
  sourcings: 'sourcings',
  pitchForm: 'pitch',
  scope: 'scope',
  listScope: 'list-scope',
  settings: 'settings',
  organization: 'organization',
  users: 'users',
  teams: 'teams',
  departments: 'departments',
  projectStageChange: 'project-stage-change',
  challenges: 'challenges',
  painPoints: 'pain-points',
} as const;

export const PATH_ROOT = {
  root: '/',
  auth: {
    login: `/${PATH_NAME.auth}/${PATH_NAME.login}`,
  },
  scout: {
    root: `/${PATH_NAME.scout}`,
    searchFor: (query: string) =>
      `/${PATH_NAME.scout}#${SEARCH_PARAMS.searchQuery}=${query}`,
    similarTo: (domain: string) =>
      `/${PATH_NAME.scout}#${SEARCH_PARAMS.searchQuery}=${SEARCH_TYPES.similarTo}%253A${domain}`,
  },
  organizationPortalBuilder: {
    home: (teamSlug?: string) =>
      `/portal-builder/${teamSlug ?? 'default'}/home`,
    about: (teamSlug?: string) =>
      `/portal-builder/${teamSlug ?? 'default'}/about`,
    requestSolutions: (teamSlug?: string) =>
      `/portal-builder/${teamSlug ?? 'default'}/request-solutions`,
    root: (teamSlug?: string) => `/portal-builder/${teamSlug ?? 'default'}`,
    customPage: (teamSlug: string | null = 'default', pageSlug?: string) =>
      `/portal-builder/${teamSlug}/${pageSlug}`,
  },
  organizationPortal: {
    home: (teamSlug: string | null = 'default') =>
      `/${PATH_NAME.portalRoot}/${teamSlug ?? 'default'}/${PATH_NAME.portalHome}`,
    search: (teamSlug: string | null = 'default') =>
      `/${PATH_NAME.portalRoot}/${teamSlug}/${PATH_NAME.scout}`,
    searchFor: (query: string, teamSlug: string | null = 'default') =>
      `/${PATH_NAME.portalRoot}/${teamSlug}/${PATH_NAME.scout}#${SEARCH_PARAMS.searchQuery}=${query}`,
    similarTo: (domain: string, teamSlug: string | null = 'default') =>
      `/${PATH_NAME.portalRoot}/${teamSlug}/${PATH_NAME.scout}#${SEARCH_PARAMS.searchQuery}=${SEARCH_TYPES.similarTo}%253A${domain}`,
    about: (teamSlug?: string) =>
      `/${PATH_NAME.portalRoot}/${teamSlug ?? 'default'}/${PATH_NAME.portalAbout}`,
    requestSolutions: (teamSlug?: string) =>
      `/${PATH_NAME.portalRoot}/${teamSlug ?? 'default'}/${PATH_NAME.briefUs}`,
    customPage: (teamSlug: string | null = 'default', pageSlug?: string) =>
      `/${PATH_NAME.portalRoot}/${teamSlug}/${pageSlug}`,
  },
  requests: {
    list: `/${PATH_NAME.requests}`,
    details: (id: number) => `/${PATH_NAME.requests}/${id}`,
  },
  personProfile: {
    details: (id: number | string) => `/${PATH_NAME.personProfile}/${id}`,
  },
  changeProjectStage: {
    dialog: (
      projectId: number | string,
      currentStage: EnumTableProjectStagesEnum,
      nextStage: EnumTableProjectStagesEnum,
    ) =>
      `/${PATH_NAME.projectStageChange}/${projectId}/${currentStage}/${nextStage}`,
  },
  // -----
  briefUs: (teamSlug?: string) =>
    `/${PATH_NAME.portalRoot}/${teamSlug ?? 'default'}/${PATH_NAME.briefUs}`,
  briefUsFromPortal: (title: string, teamSlug?: string) =>
    `/${PATH_NAME.portalRoot}/${teamSlug ?? 'default'}/${PATH_NAME.briefUs}?${SEARCH_PARAMS.portalTitle}=${title}`,
  lists: {
    root: `/${PATH_NAME.lists}`,
    details: (id: number, isDiscovery = false) =>
      `/${isDiscovery ? PATH_NAME.discovery : PATH_NAME.lists}/${id}`,
    scopeAndOrderSourcing: (id: number) =>
      `/${PATH_NAME.lists}/${id}/${PATH_NAME.orderSourcing}`,
    preview_create_from_inspiration: (categoryId: number, projectId: number) =>
      `/${PATH_NAME.preview}/${PATH_NAME.list}/${categoryId}/${PATH_NAME.createProjectFromInspiration}/${projectId}`,
    sharedCategory: ({
      publicUUID,
      categoryId,
      shareSlug,
    }: {
      categoryId: number;
      publicUUID: string;
      shareSlug: string;
    }) =>
      `/${PATH_NAME.shared}/${
        PATH_NAME.lists
      }/${publicUUID}/${encodeURIComponent(shareSlug)}/${
        PATH_NAME.category
      }/${categoryId}`,
    shared: (publicUUID: string, slug: string) =>
      `/${PATH_NAME.shared}/${
        PATH_NAME.lists
      }/${publicUUID}/${encodeURIComponent(slug)}`,
    portalShared: (
      publicUUID: string,
      slug: string,
      teamSlug?: string | null,
    ) =>
      `/${PATH_NAME.portalRoot}/${teamSlug ?? 'default'}/${
        PATH_NAME.lists
      }/${publicUUID}/${encodeURIComponent(slug)}`,
    portalSharedCategory: ({
      publicUUID,
      categoryId,
      shareSlug,
      teamSlug = 'default',
    }: {
      categoryId: number;
      publicUUID: string;
      shareSlug: string;
      teamSlug?: string | null;
    }) =>
      `/${PATH_NAME.portalRoot}/${teamSlug}/${
        PATH_NAME.lists
      }/${publicUUID}/${encodeURIComponent(shareSlug)}/${
        PATH_NAME.category
      }/${categoryId}`,
    sharedListScopeDetails: (
      publicUUID: string,
      teamSlug: string | null = 'default',
    ) =>
      `/${PATH_NAME.portalRoot}/${teamSlug}/${PATH_NAME.listScope}/${publicUUID}`,
    createList: `/${PATH_NAME.createList}`,
  },
  projects: {
    // TODO: change to projects
    pocs: `/${PATH_NAME.pocs}`,
    adoptions: `/${PATH_NAME.adoptions}`,
    // USE buildProjectDetailsPath instead
    _pocDetails: (id: number | string, tab?: TabType) =>
      tab
        ? `/${PATH_NAME.pocs}/${id}?${SEARCH_PARAMS.tab}=${tab}`
        : `/${PATH_NAME.pocs}/${id}`,
    // USE buildProjectDetailsPath instead
    _adoptionDetails: (id: number | string) => `/${PATH_NAME.adoptions}/${id}`,
    scoping: `/${PATH_NAME.scoping}`,
    sharedProjectScopeDetails: (
      publicUUID: string,
      teamSlug: string | null = 'default',
    ) =>
      `/${PATH_NAME.portalRoot}/${teamSlug}/${PATH_NAME.scope}/${publicUUID}`,
    requestSolutionsSteps: (teamSlug: string | null = 'default') =>
      `/${PATH_NAME.portalRoot}/${teamSlug}/${PATH_NAME.briefUs}/fill-scope`,
  },
  projectLeads: {
    list: `/${PATH_NAME.projectLeads}`,
    // USE buildProjectDetailsPath instead
    _details: (id: number | string, tab?: LeadTabType) =>
      `/${PATH_NAME.projectLeads}/${id}${tab ? `?${SEARCH_PARAMS.tab}=${tab}` : ''}`,
    publicDetailsView: (
      publicUUID: string,
      teamSlug: string | null = 'default',
    ) =>
      `/${PATH_NAME.portalRoot}/${teamSlug}/${PATH_NAME.projectLeads}/${publicUUID}`,
  },
  startups: {
    root: `/${PATH_NAME.startups}`,
    all: `/${PATH_NAME.startups}/all`,
    inbox: `/${PATH_NAME.startups}/inbox`,
    requests: `/${PATH_NAME.startups}/requests`,
    details: (startupId: number) =>
      `/${PATH_NAME.startups}?${PATH_PARAMS.startup_id}=${startupId}`,
  },
  analytics: `/${PATH_NAME.analytics}`,
  categoryDetails: (listId: number, categoryId: number, isDiscovery: boolean) =>
    `/${PATH_NAME[isDiscovery ? 'discovery' : 'lists']}/${listId}/${
      PATH_NAME.category
    }/${categoryId}`,
  briefUsLink: (teamSlug?: string) =>
    `${getRootUrl()}${PATH_ROOT.briefUs(teamSlug)}`,
  scopeLink: (teamSlug: string | null = 'default') =>
    `${getRootUrl()}/${PATH_NAME.portalRoot}/${teamSlug}/${PATH_NAME.scope}`,
  listScopeLink: (teamSlug: string | null = 'default') =>
    `${getRootUrl()}/${PATH_NAME.portalRoot}/${teamSlug}/${PATH_NAME.listScope}`,
  startupPitchFormLink: (orgUUID: string, startupDomain?: string) =>
    `${buildStartupPlatformDomain()}/${
      PATH_NAME.pitchForm
    }?referrer=${orgUUID}` + (startupDomain ? `&website=${startupDomain}` : ''),
  startupPortalAccessLink: (accessKey: string) =>
    `${buildStartupPlatformDomain()}/profile?access-key=${accessKey}`,
  sourcings: {
    root: `/${PATH_NAME.lists}`,
    list: `/${PATH_NAME.lists}?tab=${PATH_NAME.sourcings}`,
    orderOnList: (id: number) =>
      `/${PATH_NAME.orderSourcing}?${SEARCH_PARAMS.listId}=${id}`,
    requestForm: (listId: number) =>
      `/${PATH_NAME.lists}/${listId}/${PATH_NAME.orderSourcing}`,
  },
  settings: {
    root: `/${PATH_NAME.settings}`,
    users: `/${PATH_NAME.settings}/${PATH_NAME.users}`,
    organization: `/${PATH_NAME.settings}/${PATH_NAME.organization}`,
    teams: `/${PATH_NAME.settings}/${PATH_NAME.teams}`,
    departments: `/${PATH_NAME.settings}/${PATH_NAME.departments}`,
  },
  stakeholders: {
    root: '/stakeholders',
  },
  activity: {
    root: '/activity',
  },
  leadsAssistant: {
    root: '/leads-assistant',
    newConversation: (stakeholderId: number) =>
      `/leads-assistant?${SEARCH_PARAMS.newConversationWithStakeholderId}=${stakeholderId}`,
  },
  challenges: {
    root: '/challenges',
    _challengeDetails: (id: number | string) =>
      `/${PATH_NAME.challenges}/${id}`,
    shared: ({ publicUUID }: { publicUUID: string }) =>
      `/${PATH_NAME.shared}/${PATH_NAME.challenges}/${publicUUID}`,
  },
  painPoints: {
    root: `/${PATH_NAME.painPoints}`,
    details: (id: number) => `/${PATH_NAME.painPoints}/${id}`,
  },
} as const;

// Remove the first slash from the path +
export const pathForRoutes = (path: string) => path.slice(1);

export const buildProjectDetailsPath = ({
  id,
  stage,
  tab,
}: {
  id: number;
  stage: EnumTableProjectStagesEnum;
  tab?: TabType;
}) =>
  indexOfStage(stage) < indexOfStage('assessment')
    ? PATH_ROOT.projectLeads._details(id, tab as LeadTabType)
    : indexOfStage(stage) < indexOfStage('adoption')
      ? PATH_ROOT.projects._pocDetails(id, tab)
      : PATH_ROOT.projects._adoptionDetails(id);
