import { CircularProgress, Stack, Tooltip, TooltipProps } from '@mui/material';
import { StartupEnrichmentProgressContext } from 'components/enrichmentProgress/StartupEnrichmentProgressContext';
import { useContext, useEffect, useState } from 'react';

const useEnrichmentProgressSpinner = ({
  startupDomain,
  defaultOpen = false,
  placement = 'bottom',
}: {
  startupDomain: string;
  defaultOpen?: boolean;
  placement?: TooltipProps['placement'];
}) => {
  const context = useContext(StartupEnrichmentProgressContext);
  if (!context) {
    throw new Error(
      'useEnrichmentProgressForStartup must be used within a StartupEnrichmentProgressProvider',
    );
  }
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // This is intended to enable the use of the hook in the startup drawer. Due to transition of the drawer
  // the defaultOpen prop is send too early and the spinner is shown in a wrong position.
  // This is a workaround to fix the issue.
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (defaultOpen) {
      timeout = setTimeout(() => {
        setTooltipOpen(defaultOpen);
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
    // We want this to run exactly once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEnriching = context.enrichmentProgressPool.some(
    item => item.domain === startupDomain,
  );

  return isEnriching ? (
    <Stack direction='row' marginLeft={1}>
      <Tooltip
        title='Enriching profile...'
        open={tooltipOpen}
        placement={placement}
      >
        <Stack direction='row' alignItems='center'>
          <CircularProgress size={16} variant='indeterminate' />
        </Stack>
      </Tooltip>
    </Stack>
  ) : null;
};

export { useEnrichmentProgressSpinner };
