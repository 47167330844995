import { GridEditCellProps, useGridApiContext } from '@mui/x-data-grid-pro';
import {
  CustomDimensionCellJSON,
  CustomFeatureHeader,
} from '../../@types/shared';
import { MenuItem, TextField, Typography } from '@mui/material';
import { OnChangeCustomField } from './CustomColumnCell';

export const EditCustomColumnCell = ({
  type,
  params,
}: {
  type: CustomFeatureHeader['type'];
  params: GridEditCellProps<string | number>;
}) => {
  return type === 'tribool' ? (
    <CustomEditTriboolComponent {...params} />
  ) : type === 'number' ? (
    <CustomEditNumberCell {...params} />
  ) : (
    <CustomEditText {...params} />
  );
};

const CustomEditTriboolComponent = (
  props: GridEditCellProps<string | number>,
) => {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();

  const rowIndex = apiRef.current
    .getAllRowIds()
    .findIndex(rowId => rowId === id);
  const nextRowId = apiRef.current.getAllRowIds()[rowIndex + 1];

  const handleValueChange = ({ value: newValue }: { value: string }) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    apiRef.current.stopCellEditMode({ id, field });
    if (nextRowId) apiRef.current.startCellEditMode({ id: nextRowId, field });
  };

  return (
    <CustomFeatureTriboolField
      value={value || ''}
      onChange={handleValueChange}
      focused={hasFocus as boolean}
    />
  );
};

const CustomFeatureTriboolField = ({
  value,
  onChange,
  focused,
}: {
  value: CustomDimensionCellJSON['value'];
  onChange: OnChangeCustomField;
  focused: boolean;
}) => {
  return (
    <TextField
      select
      fullWidth
      focused={focused}
      // Due to the automatic datagrid cell editing shortcuts, in order "Enter" and "Tab" to work, need to make sure
      // that always will be a "new" value to be selected from the dropdown, so clicking "Enter" to directly "save" + move
      // the user to the next cell.
      value=''
      onChange={e => {
        onChange({ value: e.target.value });
      }}
      sx={{
        '& fieldset': {
          border: 'none !important',
        },
      }}
      slotProps={{
        select: {
          displayEmpty: true,
          defaultOpen: true,
          renderValue: () => <Typography>{value || 'Unknown'}</Typography>,
        },
      }}
    >
      {['yes', 'no', 'unknown'].map(v => (
        <MenuItem value={v} key={v} sx={{ width: '100%' }}>
          {v}
        </MenuItem>
      ))}
    </TextField>
  );
};

const CustomEditText = (props: GridEditCellProps<string | number>) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = ({ value: newValue }: { value: string }) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <TextField
      autoFocus
      onFocus={event => {
        const lengthOfInput = event.target.value.length;

        event.target.scrollTo(lengthOfInput, lengthOfInput);
        return event.target.setSelectionRange(lengthOfInput, lengthOfInput);
      }}
      sx={{
        height: '100%',
        '& fieldset': {
          border: 'none !important',
        },
        '& textarea': {
          height: '100% !important',
        },
        '& .MuiInputBase-root': {
          padding: '0',
          fontSize: theme => theme.typography.body2.fontSize,
          height: '100%',
        },
      }}
      multiline
      maxRows={3}
      value={value}
      onKeyDown={e => {
        if (e.key === 'Enter' && (e.shiftKey || e.ctrlKey || e.metaKey)) {
          e.stopPropagation();
        }
      }}
      onChange={e => {
        handleValueChange({ value: e.currentTarget.value });
      }}
    />
  );
};

const CustomEditNumberCell = (props: GridEditCellProps<string | number>) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (newValue: string) => {
    // Convert the string value to a number before setting it, using null for empty strings
    const numericValue = newValue !== '' ? Number(newValue) : null;
    apiRef.current.setEditCellValue({ id, field, value: numericValue });
  };

  return (
    <TextField
      autoFocus
      type='number'
      inputProps={{ step: 'any' }} // Allow decimal numbers
      sx={{
        height: '100%',
        '& fieldset': {
          border: 'none !important',
        },
        '& input': {
          height: '100% !important',
        },
        '& .MuiInputBase-root': {
          padding: '0',
          fontSize: theme => theme.typography.body2.fontSize,
          height: '100%',
        },
      }}
      value={value !== null ? value : ''} // Display an empty string for null values
      onKeyDown={e => {
        if (e.key === 'Enter' && (e.shiftKey || e.ctrlKey || e.metaKey)) {
          e.stopPropagation();
        }
      }}
      onChange={e => {
        const newValue = e.target.value;
        // Only allow changes if the new value is a valid number, empty string, or a single minus sign (for negative numbers)
        if (newValue === '' || newValue === '-' || !isNaN(Number(newValue))) {
          handleValueChange(newValue);
        }
      }}
    />
  );
};
