import { Avatar, SxProps, Theme } from '@mui/material';
import { stringToColor } from 'utils/general';

function stringAvatar(name: string, doubleInitials = true) {
  const colorObject = stringToColor(name);
  const backgroundColor = colorObject[100]; // Light shade for background
  const textColor = colorObject[700]; // Darker shade for text

  const splitName = name.split(' ');
  const firstLetter = splitName[0] ? splitName[0][0] : 'A';
  const lastLetter = splitName[1] ? splitName[1][0] : '';

  return {
    sx: {
      bgcolor: backgroundColor,
      color: textColor,
      marginRight: 1,
      fontWeight: 500,
    },
    children: `${firstLetter}${doubleInitials ? lastLetter : ''}`.toUpperCase(),
  };
}

export const avatarSizeSmall = {
  width: 32,
  height: 32,
  fontSize: '12px',
};

const BaseInitialsAvatar = ({
  full_name,
  sx,
  src,
  doubleInitials,
}: {
  full_name: string;
  sx?: SxProps<Theme>;
  src?: string;
  doubleInitials?: boolean;
}) => {
  const avatarSx = stringAvatar(full_name, doubleInitials).sx;
  return (
    <Avatar
      {...stringAvatar(full_name, doubleInitials)}
      {...(src ? { src, children: null } : {})}
      sx={{
        ...avatarSx,
        ...sx,
      }}
    />
  );
};

export default BaseInitialsAvatar;
