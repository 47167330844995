import { useMediaQuery, useTheme } from '@mui/material';

const useBreakpoints = () => {
  const { breakpoints } = useTheme();

  return {
    isBelowXs: useMediaQuery(breakpoints.down('xs')),
    isBelowSm: useMediaQuery(breakpoints.down('sm')),
    isBelowMd: useMediaQuery(breakpoints.down('md')),
    isBelowL: useMediaQuery(breakpoints.down('customL')),
    isBelowLg: useMediaQuery(breakpoints.down('lg')),
    isBelowXl: useMediaQuery(breakpoints.down('xl')),
    isBelowXxl: useMediaQuery(breakpoints.down('xxl')),
  };
};

export default useBreakpoints;
