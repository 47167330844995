import { useNavigate } from 'react-router-dom';
// material
import {
  Drawer,
  GlobalStyles,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import BaseScrollbar from '../../components/base/BaseScrollbar';
import LogoLong from '../../components/LogoLong';
import LogoShort from '../../components/LogoShort';
import NavSection, { NAV_TEXT_GREY } from '../../components/NavSection';
import useBuildSidebarConfig from './MenuSidebarConfig';

import TeamAvatar from 'components/teams/TeamAvatar';
import { useGetCurrentUserTeamAvatarUrl } from 'components/teams/useGetCurrentUserTeamAvatarUrl';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useFeaturesUpdatesBanner } from 'contexts/FeaturesUpdatesBannerContext';
import { PATH_ROOT } from 'routes/paths';
import { UPDATES_BANNER_HEIGHT } from '.';
import MenuClosedIcon from './assets/menu-close.svg?react';
import MenuOpenIcon from './assets/menu-open.svg?react';

// ----------------------------------------------------------------------
export const DRAWER_WIDTH = 260;
export const COLLAPSE_WIDTH = 76;
export const DRAWER_TRANSITION_LENGTH = '1s';
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      '.woot--bubble-holder': {
        display: 'none !important',
      },
      '.woot-widget-holder': {
        bottom: '32px !important',
      },
    }}
  />
);
// ----------------------------------------------------------------------

export default function MenuSidebar() {
  const theme = useTheme();
  const { isCollapsed, shouldShowToggle, toggleCollapse } = useCollapseDrawer();
  const teamAvatarUrl = useGetCurrentUserTeamAvatarUrl();
  const currentOrganization = useCurrentOrganizationFromContext();
  const { spacing } = useTheme();
  const { bottomNavConfig, mainNavConfig } = useBuildSidebarConfig();
  const navigate = useNavigate();
  const isBelowCustomL = useMediaQuery(theme.breakpoints.down('customL'));
  const { isFeaturesUpdatesBannerVisible } = useFeaturesUpdatesBanner();

  const glassdollarLogo = isCollapsed ? (
    <LogoShort
      style={{ position: 'relative', left: '-10px' }}
      width={35}
      height={35}
    />
  ) : (
    <LogoLong />
  );
  let logo;
  if (!currentOrganization) {
    logo = glassdollarLogo;
  } else {
    logo = (
      <TeamAvatar
        onClick={() => navigate(PATH_ROOT.root)}
        alt='organization-logo'
        src={teamAvatarUrl || currentOrganization.logo_url}
        sx={{
          maxHeight: '35px !important',
          borderRadius: `${spacing(0.5)} !important`,
          width: 'auto !important',
          height: '100% !important',
          cursor: 'pointer',
          '& img': {
            width: 'auto',
            height: '100% !important',
            borderRadius: `${spacing(0.5)} !important`,
            objectFit: 'contain',
          },
        }}
      />
    );
  }

  let logoPaddingLeft = 0;

  if (isCollapsed && !isBelowCustomL) {
    logoPaddingLeft = 1;
  }

  if (!isCollapsed && !isBelowCustomL) {
    logoPaddingLeft = 4;
  }

  if (isBelowCustomL) {
    logoPaddingLeft = 0;
  }

  const renderContent = (
    <BaseScrollbar
      sx={{
        paddingX: 1,
        background: '#F8F8F8',
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        '&[data-simplebar]': {
          overflowX: 'hidden',
        },
        '& .sidebar-bottom-nav': {
          marginTop: 'auto',
          marginBottom: `clamp(${spacing(2)}, ${spacing(3)}, ${spacing(3)})`,
        },
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          paddingLeft: logoPaddingLeft,
          marginTop: '12px',
        }}
      >
        {shouldShowToggle ? (
          <>
            {!isCollapsed && logo}
            <IconButton color='default' onClick={toggleCollapse}>
              {isCollapsed ? (
                <MenuOpenIcon style={{ fill: NAV_TEXT_GREY }} />
              ) : (
                <MenuClosedIcon style={{ fill: NAV_TEXT_GREY }} />
              )}
            </IconButton>
          </>
        ) : (
          <>
            <Stack
              justifyContent='center'
              alignItems='center'
              height={35}
              width='100%'
            >
              {logo}
            </Stack>
          </>
        )}
      </Stack>

      <NavSection navConfig={mainNavConfig} isCollapsed={isCollapsed} />
      <NavSection
        navConfig={bottomNavConfig}
        isCollapsed={isCollapsed}
        className='sidebar-bottom-nav'
      />
    </BaseScrollbar>
  );
  const lgWidth = isCollapsed ? COLLAPSE_WIDTH : DRAWER_WIDTH;

  return (
    <RootStyle sx={{ width: { lg: lgWidth } }}>
      {inputGlobalStyles}
      <Drawer
        open
        variant='persistent'
        PaperProps={{
          sx: {
            transition: `width ${DRAWER_TRANSITION_LENGTH}`,
            width: lgWidth,
            bgcolor: 'background.default',
            ...(isCollapsed && { width: COLLAPSE_WIDTH }),
            ...(isFeaturesUpdatesBannerVisible && {
              top: UPDATES_BANNER_HEIGHT,
              height: `calc(100vh - ${UPDATES_BANNER_HEIGHT}px)`,
            }),
          },
        }}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
}
