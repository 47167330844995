import {
  ProblemScope,
  SourcingOrderForBriefingForm,
} from 'components/dashboard/sourcingOrder/types';

// TODO: Move to appropriate folder
export type SourcingOrderState = ReturnType<typeof sourcingOrderModel> & {
  scope_files: ArrayLike<File> | null;
};

export type SourcingOrderModel = {
  projectId?: number;
  startupListId?: number;
  sourcingOrder?: SourcingOrderForBriefingForm;
  problemScope: ProblemScope;
};

export const sourcingOrderModel = (props: SourcingOrderModel) => {
  const { problemScope, projectId, sourcingOrder, startupListId } = props;

  // if (!startupListId && !projectId) {
  //   throw new Error('Either startupListId or projectId must be defined.');
  // }

  return {
    startupListId,
    projectId,
    // sourcing order
    id: sourcingOrder?.id || 0,
    title: sourcingOrder?.startup_list?.title || '',
    requested_result_type: sourcingOrder?.requested_result_type || 'benchmark',
    delivery_type: sourcingOrder?.delivery_type || 'standard',
    due_date: sourcingOrder?.due_date,
    department:
      sourcingOrder?.created_by?.person.organization_department?.name || '',
    public_uuid: sourcingOrder?.startup_list?.public_uuid,
    stakeholder: sourcingOrder?.created_by?.person,
    status: sourcingOrder?.status,
    updated_at: sourcingOrder?.updated_at,
    // problem scope
    problem: problemScope?.problem || '',
    additional_info: problemScope?.additional_info || '',
    desired_solution: problemScope?.desired_solution || '',
    must_have_features: problemScope?.must_have_features || [],
    nice_to_have_features: problemScope?.nice_to_have_features || [],
    known_startups: problemScope?.known_startups || [],
    problemScopeId: problemScope?.id,
    scope_files: [],
  };
};
