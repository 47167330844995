import { useMemo } from 'react';
import {
  GetPersonDetailsDocument,
  UpdatePersonDepartmentMutation,
  usePersonDepartmentAutocompleteGetDepartmentsQuery,
  useUpdatePersonDepartmentMutation,
} from 'apollo/generated/sdkShared';
import { ApolloCache } from '@apollo/client';
import { People } from 'apollo/generated/sdkInnovationManager';

const updatePersonDetailsCache = (
  cache: ApolloCache<People>,
  personId: number,
  data: UpdatePersonDepartmentMutation | null | undefined,
) => {
  cache.writeQuery({
    query: GetPersonDetailsDocument,
    variables: { id: personId },
    data: data?.update_people_by_pk,
  });
};

export const usePersonDepartmentActions = (
  organizationId: number,
  personId: number,
) => {
  const { data: departmentData } =
    usePersonDepartmentAutocompleteGetDepartmentsQuery({
      variables: { organization_id: organizationId },
    });

  const [updatePersonDepartment] = useUpdatePersonDepartmentMutation();

  const departments = useMemo(
    () =>
      departmentData?.organization_departments.map(d => ({
        id: d.id,
        name: d.name,
        description: d.description,
      })) || [],
    [departmentData],
  );

  const updateDepartment = async (departmentId: number | undefined) => {
    await updatePersonDepartment({
      variables: { personId, departmentId },
      update: (cache, { data }) =>
        updatePersonDetailsCache(cache, personId, data),
    });
  };

  const unlinkDepartment = async () => {
    await updatePersonDepartment({
      variables: { personId, departmentId: null },
      update: (cache, { data }) => {
        updatePersonDetailsCache(cache, personId, data);
      },
    });
  };

  return {
    departments,
    updateDepartment,
    unlinkDepartment,
  };
};
