import { PersonOutlined, QueryStatsOutlined } from '@mui/icons-material';
import { Divider, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { OpenLinkHoverButton } from 'components/OpenLinkHoverButton';
import StartupSignalChip, {
  StartupSignal,
} from 'components/startups/StartupSignalLabel';
import useAuth from 'hooks/useAuth';
import { getCountryFlag } from 'utils/formatLocation';
import { formatMoney } from 'utils/formatNumber';

export const BaseCompanyDetails = ({
  company: {
    name,
    website,
    founded_year,
    employees_count,
    funding,
    hq,
    case_study_count,
    signal,
  },
  showWebsiteLink = false,
  showCaseStudies = true,
}: {
  company: {
    name?: string | null;
    website?: string | null;
    founded_year?: number | null;
    employees_count?: number | string | null;
    funding?: number | null;
    hq?: string | null;
    case_study_count?: number | null;
    signal?: StartupSignal | null;
  };
  showWebsiteLink?: boolean;
  showCaseStudies?: boolean;
}) => {
  const { user } = useAuth();
  const { palette, spacing } = useTheme();
  const dividerSx = {
    background: palette.grey[300],
    height: spacing(1.5),
    marginY: 'auto !important',
  };

  const showOpenLinkHoverButton = showWebsiteLink && website;

  return (
    <Stack
      direction='row'
      spacing={1}
      color={palette.text.secondary}
      width='max-content'
      alignItems='center'
    >
      <Typography noWrap variant='caption'>
        {founded_year || '-'}
      </Typography>
      <Divider flexItem sx={dividerSx} orientation='vertical' />
      {hq ? (
        <Tooltip title={hq}>
          <Typography variant='caption' sx={{ fontSize: 16 }}>
            {getCountryFlag(hq)}
          </Typography>
        </Tooltip>
      ) : (
        <Typography noWrap variant='caption'>
          {'-'}
        </Typography>
      )}
      <Divider flexItem sx={dividerSx} orientation='vertical' />
      <Stack direction='row' alignItems='center' gap={0.5}>
        <PersonOutlined sx={{ fontSize: 16 }} />
        <Typography noWrap variant='caption'>
          {employees_count ?? '-'}
        </Typography>
      </Stack>
      <Divider flexItem sx={dividerSx} orientation='vertical' />
      <Typography noWrap variant='caption'>
        {funding ? formatMoney(funding) : '$ n/a'}
      </Typography>
      {showCaseStudies && case_study_count && case_study_count > 0 ? (
        <>
          <Tooltip title={case_study_count + ' case studies'}>
            <Stack direction='row' alignItems='center' gap={0.5}>
              <Divider flexItem sx={dividerSx} orientation='vertical' />
              <QueryStatsOutlined sx={{ fontSize: 15 }} />
              <Typography noWrap variant='caption'>
                {case_study_count}
              </Typography>
            </Stack>
          </Tooltip>
        </>
      ) : null}
      {signal && name && (
        <Stack direction='row' alignItems='center' gap={0.5}>
          <Divider flexItem sx={dividerSx} orientation='vertical' />

          <StartupSignalChip signal={signal} startupName={name} />
        </Stack>
      )}
      {user.isContentEditor && showOpenLinkHoverButton && (
        <OpenLinkHoverButton website={website} />
      )}
    </Stack>
  );
};
