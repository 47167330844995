import { ReactNode } from 'react';

export const removeBorders = {
  includeLeftBorder: false,
  includeRightBorder: false,
  includeTopBorder: false,
  includeBottomBorder: false,
};

export const truncateWithEllipsis = (text: string, length: number) => {
  return text.length > length ? text.substring(0, length) + '...' : text;
};

const getDecimal = (num: number) => {
  return num === Math.floor(num) ? num.toFixed(0) : num.toFixed(1);
};

export const formatMoney = (
  money: number | null | undefined,
  currency?: string,
) => {
  if (!money) return '-';
  if (money >= 1_000_000_000) {
    return `${currency ?? '$'}${getDecimal(money / 1000000000)}B`;
  } else if (money >= 1_000_000) {
    return `${currency ?? '$'}${getDecimal(money / 1000000)}M`;
  } else if (money >= 1_000) {
    return `${currency ?? '$'}${getDecimal(money / 1_000)}K`;
  } else {
    return '-';
  }
};

export const isValidUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

const getDomainName = (url: string) => {
  const { hostname } = new URL(url);
  return hostname.replace(/.+\/\/|www.|\..+/g, '');
};

export const parseLinksInText = ({
  textToParse,
  renderLink,
}: {
  textToParse: string;
  renderLink: (link: string, domain: string) => ReactNode;
}) => {
  return String(textToParse)
    ?.split(/\s+/g)
    .map((possibleHyperLink: string) => {
      return isValidUrl(possibleHyperLink) && getDomainName(possibleHyperLink)
        ? renderLink(possibleHyperLink, getDomainName(possibleHyperLink)!)
        : `${possibleHyperLink + ' '}`;
    });
};

export function isHTMLString(string: string): boolean {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlRegex.test(string);
}

export const isTodayOrInThePast = (toBeCompletedAt: string) => {
  const futureDeadline = Date.parse(toBeCompletedAt);
  const today = Date.now();
  return today >= futureDeadline;
};
