import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { AddFeatureButton } from 'components/dashboard/sourcingOrder/SourcingOrderScopeContent';
import { StartupListFeatureBox } from 'components/dashboard/startupList/StartupListFeatureBox';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { SourcingOrderState } from 'pages/request-form/sourcingOrderModel';
import { Dispatch, SetStateAction, useState } from 'react';
import * as Yup from 'yup';
import { StartupListFeature } from '../../@types/startupList';
import { FormLabelWithTooltip } from './ProblemField';

const FEATURE_SCHEMA = Yup.object({
  must_have_features: Yup.array().of(Yup.string()).optional(),
  nice_to_have_features: Yup.array().of(Yup.string()).optional(),
});

export type StartupListFeaturesOptionType = {
  name: string;
  mustHave: boolean;
} | null;

type StartupsAndFeaturesFieldSchemaValue = Pick<
  SourcingOrderState,
  'nice_to_have_features' | 'must_have_features'
>;

export type FeatureTypes = keyof StartupsAndFeaturesFieldSchemaValue;

type FeaturesFieldProps = {
  setFieldValue: (name: string, value: string | string[]) => void;
  must_have_features: string[];
  nice_to_have_features: string[];
  isEditable?: boolean;
  showTooltip?: boolean;
};

const EditableFeatureSection = ({
  label,
  tooltipText,
  features,
  featureType,
  editFeatures,
  setEditFeatures,
  setFieldValue,
  setSelectedFeature,
  setIsEditMode,
  showTooltip,
}: {
  label: string;
  tooltipText: string;
  features: string[];
  featureType: 'must_have_features' | 'nice_to_have_features';
  editFeatures: string | null;
  setEditFeatures: React.Dispatch<
    React.SetStateAction<'must_have_features' | 'nice_to_have_features' | null>
  >;
  setFieldValue: (name: string, value: string[]) => void;
  setSelectedFeature: React.Dispatch<
    React.SetStateAction<{ mustHave: boolean; name: string } | null>
  >;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  showTooltip?: boolean;
}) => {
  const isEditing = editFeatures === featureType;
  const hasFeatures = features.length !== 0;
  return (
    <Stack gap={0.75}>
      <FormLabelWithTooltip
        label={label}
        tooltipText={tooltipText}
        sx={{ marginBottom: 0, marginLeft: '2px' }}
        showTooltip={showTooltip}
      />
      <Stack
        direction='row'
        alignItems='center'
        flexWrap='wrap'
        gap={1}
        sx={{
          padding: '4px 2px',
          borderRadius: '8px',
          '&:hover': {
            background: !isEditing && hasFeatures ? '#F8F8F8' : 'none',
            cursor: 'text',
          },
          boxShadow:
            isEditing && hasFeatures ? ({ shadows }) => shadows[4] : 'none',
        }}
        onClick={() => setEditFeatures(featureType)}
        onMouseLeave={() => setEditFeatures(null)}
      >
        {features.map(feature => (
          <StartupListFeatureBox
            editModeEnabled
            key={feature}
            {...(isEditing
              ? {
                  onDelete: () => {
                    setFieldValue(
                      featureType,
                      features.filter(f => f !== feature),
                    );
                  },
                  onClick: () => {
                    setIsEditMode(true);
                    setSelectedFeature({
                      mustHave: featureType === 'must_have_features',
                      name: feature,
                    });
                  },
                }
              : {})}
            featureText={feature}
          />
        ))}
        {(isEditing || !hasFeatures) && (
          <AddFeatureButton
            onClick={() => {
              setSelectedFeature({
                mustHave: featureType === 'must_have_features',
                name: '',
              });
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

const FeatureSection = ({
  label,
  tooltipText,
  features,
  featureType,
  setFieldValue,
  setSelectedFeature,
  setIsEditMode,
  showTooltip,
}: {
  label: string;
  tooltipText: string;
  features: string[];
  featureType: 'must_have_features' | 'nice_to_have_features';
  setFieldValue: (name: string, value: string[]) => void;
  setSelectedFeature: React.Dispatch<
    React.SetStateAction<{ mustHave: boolean; name: string } | null>
  >;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  showTooltip?: boolean;
}) => (
  <Stack>
    <FormLabelWithTooltip
      label={label}
      tooltipText={tooltipText}
      sx={{ marginBottom: 1 }}
      showTooltip={showTooltip}
    />
    <Stack direction='row' alignItems='center' flexWrap='wrap' gap={1}>
      {(features || []).map(feature => (
        <StartupListFeatureBox
          editModeEnabled
          key={feature}
          onDelete={() => {
            setFieldValue(
              featureType,
              features.filter(f => f !== feature),
            );
          }}
          onClick={() => {
            setIsEditMode(true);
            setSelectedFeature({
              mustHave: featureType === 'must_have_features',
              name: feature,
            });
          }}
          featureText={feature}
        />
      ))}
      <AddFeatureButton
        onClick={() => {
          setSelectedFeature({
            mustHave: featureType === 'must_have_features',
            name: '',
          });
        }}
      />
    </Stack>
  </Stack>
);

export function FeaturesField(props: FeaturesFieldProps) {
  const {
    setFieldValue,
    must_have_features,
    nice_to_have_features,
    showTooltip,
  } = props;

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState<{
    mustHave: boolean;
    name: string;
  } | null>(null);
  const [editFeatures, setEditFeatures] = useState<
    'must_have_features' | 'nice_to_have_features' | null
  >(null);

  const addFeature = (
    feature: string,
    type: 'must_have_features' | 'nice_to_have_features',
  ) => {
    setFieldValue(type, [...props[type], feature]);
  };

  const mustHaveTooltipText =
    'What features should a startup offer? Are there any technical requirements or deployment preferences the startup should meet?';
  const niceToHaveTooltipText =
    'What features would be beneficial but not essential for the startup?';

  return (
    <>
      {selectedFeature && (
        <FeaturesPopup
          open
          isEditMode={isEditMode}
          onHide={() => {
            setSelectedFeature(null);
            setIsEditMode(false);
          }}
          onSetValues={addFeature}
          selectedFeature={selectedFeature}
          setSelectedFeature={setSelectedFeature}
          setFeaturesFieldValue={setFieldValue}
          must_have_features={must_have_features}
          nice_to_have_features={nice_to_have_features}
        />
      )}
      {props.isEditable && (
        <Stack gap={1.25} width='100%'>
          <EditableFeatureSection
            label='Must-Have Features'
            tooltipText={mustHaveTooltipText}
            features={must_have_features}
            featureType='must_have_features'
            editFeatures={editFeatures}
            setEditFeatures={setEditFeatures}
            setFieldValue={setFieldValue}
            setSelectedFeature={setSelectedFeature}
            setIsEditMode={setIsEditMode}
            showTooltip={showTooltip}
          />
          <EditableFeatureSection
            label='Nice-To-Have Features'
            tooltipText={niceToHaveTooltipText}
            features={nice_to_have_features}
            featureType='nice_to_have_features'
            editFeatures={editFeatures}
            setEditFeatures={setEditFeatures}
            setFieldValue={setFieldValue}
            setSelectedFeature={setSelectedFeature}
            setIsEditMode={setIsEditMode}
            showTooltip={showTooltip}
          />
        </Stack>
      )}
      {!props.isEditable && (
        <Stack gap={4} width='100%'>
          <FeatureSection
            label='Must-Have Features'
            tooltipText={mustHaveTooltipText}
            features={must_have_features}
            featureType='must_have_features'
            setFieldValue={setFieldValue}
            setSelectedFeature={setSelectedFeature}
            setIsEditMode={setIsEditMode}
            showTooltip={showTooltip}
          />
          <FeatureSection
            label='Nice-To-Have Features'
            tooltipText={niceToHaveTooltipText}
            features={nice_to_have_features}
            featureType='nice_to_have_features'
            setFieldValue={setFieldValue}
            setSelectedFeature={setSelectedFeature}
            setIsEditMode={setIsEditMode}
            showTooltip={showTooltip}
          />
        </Stack>
      )}
    </>
  );
}

type FeaturesPopupProps = {
  open: boolean;
  onHide: () => void;
  onSetValues: (
    features: StartupListFeature[number],
    type: FeatureTypes,
  ) => void;
  selectedFeature: StartupListFeaturesOptionType;
  setFeaturesFieldValue: (name: string, value: string | string[]) => void;
  setSelectedFeature: Dispatch<SetStateAction<StartupListFeaturesOptionType>>;
  must_have_features: StartupListFeature;
  nice_to_have_features: StartupListFeature;
  isEditMode: boolean;
  handleUpdate?: ({
    fieldName,
    fieldValue,
  }: {
    fieldName: 'must_have_features' | 'nice_to_have_features';
    fieldValue: StartupListFeature;
  }) => void;
};

export function FeaturesPopup(props: FeaturesPopupProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    open,
    onHide,
    onSetValues,
    selectedFeature,
    setSelectedFeature,
    setFeaturesFieldValue,
    isEditMode,
    handleUpdate,
  } = props;
  const isMustHave = selectedFeature?.mustHave;
  const targetArray = isMustHave
    ? 'must_have_features'
    : 'nice_to_have_features';
  const formik = useFormik<{
    name: string;
    mustHave: boolean;
  }>({
    enableReinitialize: true,
    initialValues: {
      name: selectedFeature?.name || '',
      mustHave: selectedFeature ? selectedFeature?.mustHave : true,
    },
    validationSchema: FEATURE_SCHEMA,
    onSubmit: (values, { resetForm }) => {
      console.log({ b: props[targetArray] }, targetArray);
      try {
        if (isEditMode) {
          const newFeatures = props[targetArray]?.map(x =>
            x === selectedFeature?.name ? values.name : x,
          );

          console.log('newFeatures', newFeatures);

          setFeaturesFieldValue(targetArray, newFeatures);

          if (handleUpdate) {
            handleUpdate({
              fieldName: targetArray,
              fieldValue: newFeatures,
            });
          }
        } else {
          onSetValues(values.name, targetArray);
        }

        resetForm();
        onHide();
        setSelectedFeature(null);
      } catch (e) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog open={open} fullWidth onClose={onHide}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle>
            {`${isEditMode ? 'Edit' : 'Add'} a ${
              isMustHave ? '"Must-Have"' : '"Nice-To-Have"'
            } feature`}
          </DialogTitle>
          <DialogContent sx={{ marginTop: 3 }}>
            <Stack direction='column' spacing={2} sx={{ marginTop: 1 }}>
              <TextField
                autoFocus
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                label='Name your features, requirements, wanted technologies, startup locations, or maturity'
                data-testid='features-modal__feature'
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant='text'
              color='inherit'
              onClick={() => {
                setSelectedFeature(null);
                onHide();
              }}
            >
              Cancel
            </Button>
            <Button type='submit' variant='contained'>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
