import { Stack, SxProps, Theme } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { MouseEvent, ReactNode, useCallback, useState } from 'react';

export const useRightTableActions = () => {
  const [hoveredRow, setHoveredRow] = useState<number | string | null>(null);
  const onMouseEnterRow = (event: MouseEvent<HTMLElement>) => {
    const dataId = event?.currentTarget?.getAttribute('data-id');

    if (!dataId) return;

    const id = Number.isNaN(Number(dataId)) ? dataId : Number(dataId);

    setHoveredRow(id);
  };

  const getActionsColumn = useCallback(
    <T extends GridValidRowModel>({
      // eslint-disable-next-line
      field,
      // eslint-disable-next-line
      cellClassName,
      // eslint-disable-next-line
      cellClassNamePredicate = true,
      cellRenderPredicate,
      renderCellNode = () => null,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      width,
      sx,
      ...rest
    }: Partial<GridColDef> & {
      cellClassNamePredicate?: boolean;
      cellRenderPredicate?: (params: GridRenderCellParams) => boolean;
      sx?: SxProps<Theme>;
      renderCellNode: (params: GridRenderCellParams) => ReactNode;
    }): GridColDef<T> => {
      return {
        field: 'actions_column',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        editable: false,
        hideable: false,
        renderHeader: () => null,
        renderCell: (params: GridRenderCellParams) => {
          return (!cellRenderPredicate || cellRenderPredicate(params)) &&
            hoveredRow === params.id ? (
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={1}
              data-test='table-floating-actions'
              sx={{
                boxShadow: ({ shadows }) => shadows['10'],
                borderRadius: 1,
                padding: 1,
                paddingX: 2,
                marginX: 'auto',
                background: ({ palette }) => palette.grey[0],
                ...sx,
              }}
            >
              {renderCellNode(params)}
            </Stack>
          ) : null;
        },
        ...rest,
      };
    },
    [hoveredRow],
  );

  return {
    /**
     * Example usage:
     * <DataGrid
          initialState={{
            pinnedColumns: {
              right: ['actions_column'],
            },
          }}
          componentsProps={{ row ... }}
          ....
     */
    row: {
      onMouseEnter: onMouseEnterRow,
      onMouseLeave: () => setHoveredRow(null),
    },
    pinnedRightColumn: ['actions_column'],
    // Use this to build actions column
    getActionsColumn,
  };
};
