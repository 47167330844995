import { Typography } from '@mui/material';
import Markdown from 'components/Markdown';

export const PitchMarkdown = ({ content }: { content: string }) => {
  return (
    <Typography whiteSpace='pre-wrap' variant='body2' component={'div'}>
      {content.startsWith('<') ? <Markdown>{content}</Markdown> : content}
    </Typography>
  );
};
