import {
  ArrowBackIos,
  ArrowForwardIos,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import {
  Button,
  Divider,
  IconButton,
  Stack,
  SxProps,
  Typography,
  useTheme,
  Zoom,
} from '@mui/material';
import {
  GetPitchForStartupProfileQuery,
  OrganizationStartupPitches,
  useArchivePitchMutation,
  useGetPitchForStartupProfileQuery,
  useUpdatePitchStatusMutation,
  useUpdatePitchTitleMutation,
} from 'apollo/generated/sdkShared';
import FilePreview from 'components/base/upload/FilePreview';
import EmptyContent from 'components/EmptyContent';
import ContentEditableText from 'components/shared/ContentEditableText';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import CustomPitchSubmission from './CustomPitchSubmission';
import DefaultPitchSubmission from './DefaultPitchSubmission';
import BaseLabel from 'components/base/BaseLabel';

type StartupPitch = Pick<
  OrganizationStartupPitches,
  | 'id'
  | 'startup_id'
  | 'title'
  | 'status'
  | 'contact_email'
  | 'elevator_pitch'
  | 'differentiation'
  | 'customer_references'
  | 'enterprise_pricing_model'
>;

const pitchSchema = z.object({
  pitchVideoUrl: z.string().optional(),
  fields: z.array(
    z.object({
      id: z.string(),
      label: z.string(),
      description: z.string(),
      type: z.enum(['textarea', 'multi_select']),
      required: z.boolean(),
      options: z
        .array(z.object({ description: z.string(), value: z.string() }))
        .optional(),
    }),
  ),
});

export type PitchSchema = z.infer<typeof pitchSchema>;
export type Pitch =
  GetPitchForStartupProfileQuery['organization_startup_pitches_by_pk'];

const PitchCarousel = ({ pitchIds }: { pitchIds: number[] }) => {
  const [activePitchId, setActivePitchId] = useState(pitchIds[0]);
  const { isSharedPage } = useSharedPagesContext();
  const { spacing } = useTheme();
  const { data } = useGetPitchForStartupProfileQuery({
    variables: { pitchId: activePitchId },
  });

  const pitch = data?.organization_startup_pitches_by_pk;

  if (!pitch) {
    return null;
  }

  const couldEdit = !isSharedPage;
  const schema = data?.organization_startup_pitch_schema?.[0]?.form_schema;
  const isValidSchema = schema && pitchSchema.safeParse(schema).success;

  return (
    <Stack marginTop={2} gap={2}>
      <Stack direction='row' alignItems='center' gap={2}>
        <IconButton
          sx={{
            '& svg > path': {
              // Weird bug with the arrow icon, so we need to translate it a bit
              transform: `translateX(${spacing(0.5)})`,
            },
          }}
          disabled={activePitchId === pitchIds[0]}
          onClick={() => {
            const index = pitchIds.indexOf(activePitchId);
            if (index > 0) {
              setActivePitchId(pitchIds[index - 1]);
            }
          }}
        >
          <ArrowBackIos />
        </IconButton>
        <Stack direction='row' alignItems='center' gap={1}>
          <Typography sx={{ margin: '0 !important' }}>
            <EditableTitle
              pitch={pitch}
              editable={pitch.status !== 'rejected'}
            />
          </Typography>
          {pitch.source && <BaseLabel>{pitch.source}</BaseLabel>}
        </Stack>
        <IconButton
          disabled={activePitchId === pitchIds[pitchIds.length - 1]}
          onClick={() => {
            const index = pitchIds.indexOf(activePitchId);
            if (index < pitchIds.length - 1) {
              setActivePitchId(pitchIds[index + 1]);
            }
          }}
        >
          <ArrowForwardIos />
        </IconButton>
        {couldEdit && <PitchCTAs pitch={pitch} sx={{ marginLeft: 'auto' }} />}
      </Stack>
      {isValidSchema && !!pitch.submission_json ? (
        <CustomPitchSubmission
          submission={JSON.parse(pitch.submission_json)}
          schema={schema}
        />
      ) : (
        <Stack gap={2}>
          <DefaultPitchSubmission pitch={pitch} />
        </Stack>
      )}
      {pitch.documents.length > 0 && (
        <Stack spacing={2}>
          <Typography variant='subtitle1'>Sales documents</Typography>
          <Stack direction='row' spacing={1}>
            {pitch.documents.map(document => (
              <FilePreview
                key={pitch.id}
                document={{
                  name: document.name,
                  url: document.url,
                }}
                readonly
              />
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export const StartupPitchDetailsTab = ({
  pitchIds,
}: {
  pitchIds: number[];
}) => {
  if (pitchIds.length === 0) {
    return <EmptyContent title='No pitches found' />;
  }

  return <PitchCarousel pitchIds={pitchIds} />;
};

const PitchCTAs = ({ sx, pitch }: { sx?: SxProps; pitch: StartupPitch }) => {
  const [updateStatus] = useUpdatePitchStatusMutation();
  const [archivePitch] = useArchivePitchMutation();

  const markAsAccepted = async () => {
    await updateStatus({
      variables: { pitchId: pitch.id, status: 'accepted' },
    });
  };

  const confirm = useConfirm();

  const handleDelete = async () => {
    await confirm({
      title: 'Do you want to delete this Pitch?',
      content: 'This action cannot be undone.',
      contentProps: { color: 'text.secondary', sx: { marginTop: 2 } },
      confirmationText: 'Delete',
      confirmationButtonProps: { variant: 'contained', color: 'error' },
      cancellationText: 'cancel',
      cancellationButtonProps: { color: 'inherit' },
    });
    await archivePitch({
      variables: { pitchId: pitch.id },
      update: (cache, { data }) => {
        if (data?.update_organization_startup_pitches_by_pk?.archived_at) {
          cache.evict({ id: cache.identify(pitch) });
        }
      },
    });
  };

  return (
    <Stack direction='row' spacing={1} alignItems='center' sx={sx}>
      {pitch.status === 'new' ? (
        <>
          <Button onClick={markAsAccepted}>Accept</Button>
          <Divider orientation='vertical' />
          <Button
            variant='text'
            color='error'
            onClick={() =>
              updateStatus({
                variables: { pitchId: pitch.id, status: 'rejected' },
              })
            }
          >
            Reject
          </Button>
        </>
      ) : (
        <IconButton>
          <DeleteIcon onClick={handleDelete} />
        </IconButton>
      )}
    </Stack>
  );
};

const EditableTitle = ({
  editable,
  pitch: { id, title },
}: {
  editable: boolean;
  pitch: { id: number; title: string };
}) => {
  const [updateTitle] = useUpdatePitchTitleMutation();
  const [showAlert, setShowAlert] = useState(false);
  const handleUpdate = (newTitle: string) => {
    if (newTitle !== title) {
      updateTitle({ variables: { pitchId: id, title: newTitle || '' } }).then(
        () => {
          setShowAlert(true);
        },
      );
    }
  };
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => setShowAlert(false), 2000);
    }
  }, [showAlert]);

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <ContentEditableText
        variant='h5'
        fontWeight={400}
        isEditable={editable}
        text={title}
        onBlur={event => handleUpdate(event.target.textContent || '')}
      />
      {editable && (
        <Zoom in={showAlert} mountOnEnter unmountOnExit>
          <Typography color='primary' variant='caption'>
            Updated
          </Typography>
        </Zoom>
      )}
    </Stack>
  );
};
