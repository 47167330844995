import { motion } from 'framer-motion';
// material
import { Box, BoxProps } from '@mui/material';
//
import { varWrapEnter } from './variants';

// ----------------------------------------------------------------------

interface MotionContainerProps extends BoxProps {
  initial?: boolean | string;
  open?: boolean;
  variants?: object;
}

export default function MotionContainer({
  open,
  children,
  // eslint-disable-next-line
  variants = varWrapEnter,
  ...other
}: MotionContainerProps) {
  return (
    <motion.div animate={open ? 'animate' : 'exit'} variants={varWrapEnter}>
      <Box initial={false} {...other}>
        {children}
      </Box>
    </motion.div>
  );
}
