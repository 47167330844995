import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { OrganizationDepartments } from 'apollo/generated/sdkInnovationManager';
import {
  useCreateDepartmentMutation,
  useDeleteDepartmentMutation,
  useGetOrganizationDepartmentsQuery,
} from 'apollo/generated/sdkShared';
import { BaseDialog } from 'components/base/BaseDialog';
import LoadingScreen from 'components/LoadingScreen';
import { TypeSafeStyledDataGrid } from 'components/StyledDataGrid';
import { Dispatch, SetStateAction, useState } from 'react';
import { CustomToolbar } from './users/CustomToolbar';
import { Delete } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import EmptyContent from 'components/EmptyContent';

type DepartmentsProps = Pick<
  OrganizationDepartments,
  'id' | 'name' | 'description' | 'division_name'
>;

function useGetColumns({
  setDepartmentIdToDelete,
}: {
  setDepartmentIdToDelete: Dispatch<SetStateAction<number | null>>;
}) {
  const columns: GridColDef<DepartmentsProps>[] = [
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'division_name', headerName: 'Division/Brand', width: 250 },
    { field: 'description', headerName: 'Description', flex: 1 },
    {
      field: 'actions_column',
      headerName: '',
      width: 30,
      flex: 0.4,
      renderCell: (params: GridRenderCellParams<DepartmentsProps>) => (
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          width='100%'
        >
          <IconButton
            onClick={() => setDepartmentIdToDelete(params.row.id)}
            size='small'
            color='error'
          >
            <Delete fontSize='small' />
          </IconButton>
        </Stack>
      ),
    },
  ].map(column => ({
    ...column,
    filterable: false,
    sortable: false,
  }));

  return { columns };
}

function DeleteDepartmentConfirmDialog({
  departmentIdToDelete,
  setDepartmentIdToDelete,
}: {
  departmentIdToDelete: number;
  setDepartmentIdToDelete: Dispatch<SetStateAction<number | null>>;
}) {
  const [deleteDepartment] = useDeleteDepartmentMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteDepartment = async () => {
    try {
      await deleteDepartment({
        variables: { id: departmentIdToDelete },
        update: cache => {
          cache.evict({
            id: cache.identify({
              __typename: 'organization_departments',
              id: departmentIdToDelete,
            }),
          });
          cache.gc();
        },
      });

      setDepartmentIdToDelete(null);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error deleting department', { variant: 'error' });
    }
  };

  return (
    <BaseDialog
      heading='Delete department?'
      confirmColor='error'
      onConfirm={handleDeleteDepartment}
      onHide={() => setDepartmentIdToDelete(null)}
    >
      <Typography>Are you sure you want to delete this department?</Typography>
    </BaseDialog>
  );
}

export function AddDepartmentDialog({
  setAddDepartmentDialogOpen,
  open = true,
  setSelectedDepartment = async () => {},
  defaultName = '',
}: {
  setAddDepartmentDialogOpen: Dispatch<SetStateAction<boolean>>;
  open?: boolean;
  setSelectedDepartment?: (id: number | undefined) => Promise<void>;
  defaultName?: string;
}) {
  const [name, setName] = useState(defaultName);
  const [description, setDescription] = useState('');
  const [createDepartment, { loading }] = useCreateDepartmentMutation();
  const { enqueueSnackbar } = useSnackbar();
  const isFormValid = name.length > 0;

  const handleCreateDepartment = async () => {
    if (!isFormValid) return;

    try {
      const { data } = await createDepartment({
        variables: {
          payload: { name, description },
        },
        optimisticResponse: {
          insert_organization_departments_one: {
            id: 0,
            __typename: 'organization_departments',
            name,
            description,
          },
        },
        update: (cache, { data }) => {
          if (!data?.insert_organization_departments_one?.id) {
            return;
          }

          const newRef = cache.writeFragment({
            id: cache.identify(data?.insert_organization_departments_one),
            data: data.insert_organization_departments_one,
            fragment: gql`
              fragment OrganizationDepartment on organization_departments {
                ...OrganizationDepartment
              }
            `,
          });

          cache.modify({
            fields: {
              organization_departments: existing => [...existing, newRef],
            },
          });
        },
      });
      setAddDepartmentDialogOpen(false);
      await setSelectedDepartment(
        data?.insert_organization_departments_one?.id,
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error creating department', { variant: 'error' });
    }
  };

  return (
    <BaseDialog
      heading='Add department'
      onConfirm={handleCreateDepartment}
      disableConfirmButton={loading || !isFormValid}
      onHide={() => setAddDepartmentDialogOpen(false)}
      confirmCTA='Save'
      open={open}
    >
      <Stack spacing={2} pt={2}>
        <TextField
          label='Code'
          value={name}
          onChange={e => setName(e.target.value)}
          autoFocus
        />
        <TextField
          label='Description'
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </Stack>
    </BaseDialog>
  );
}

export default function Departments() {
  const { data, loading } = useGetOrganizationDepartmentsQuery();
  const [addDepartmentDialogOpen, setAddDepartmentDialogOpen] = useState(false);
  const [departmentIdToDelete, setDepartmentIdToDelete] = useState<
    number | null
  >(null);
  const { columns } = useGetColumns({ setDepartmentIdToDelete });

  if (loading) {
    return <LoadingScreen sx={{ paddingTop: 20 }} />;
  }

  return (
    data?.organization_departments && (
      <Box sx={{ paddingTop: 2 }}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ mb: 3 }}
        >
          <Stack>
            <Typography variant='h4'>
              Departments ({data.organization_departments.length})
            </Typography>
          </Stack>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setAddDepartmentDialogOpen(true)}
          >
            Add department
          </Button>{' '}
        </Stack>
        <TypeSafeStyledDataGrid<DepartmentsProps>
          rows={data.organization_departments}
          columns={columns}
          isRowClickable={false}
          getRowId={row => row.id}
          autoHeight
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: () => (
              <EmptyContent title='There are no departments yet' />
            ),
          }}
          disableColumnSelector
          disableColumnReorder
          hideFooter
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
        />
        {addDepartmentDialogOpen && (
          <AddDepartmentDialog
            setAddDepartmentDialogOpen={setAddDepartmentDialogOpen}
          />
        )}

        {departmentIdToDelete && (
          <DeleteDepartmentConfirmDialog
            departmentIdToDelete={departmentIdToDelete}
            setDepartmentIdToDelete={setDepartmentIdToDelete}
          />
        )}
      </Box>
    )
  );
}
