import { Link as LinkIcon } from '@mui/icons-material';
import { Box, Button, Card, Stack } from '@mui/material';
import { useGetStartupPitchesQuery } from 'apollo/generated/sdkShared';
import EmptyContent from 'components/EmptyContent';
import { TypeSafeStyledDataGrid } from 'components/StyledDataGrid';
import BaseCopyToClipboard from 'components/base/BaseCopyToClipboard';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import { useState } from 'react';
import { PATH_ROOT } from 'routes/paths';
import { PitchForPitchInbox } from './types';
import { useGetColumns } from './useGetColumns';
import { StartupFunnelLabel } from 'components/dashboard/StartupInfoSidePanel/StartupLabelChipSelector';

const DEFAULT_SORTING_MODEL = [
  {
    field: 'status',
    sort: 'asc' as const,
  },
  {
    field: 'created_at',
    sort: 'desc' as const,
  },
];

const PitchInboxTable = () => {
  const { data } = useGetStartupPitchesQuery();
  const { openStartupSidePanel } = useStartupSidePanel();
  const columns = useGetColumns();
  const rows = (data?.organization_startup_pitches || [])
    .filter(p => !p.archived_at)
    .map(p => ({
      ...p,
      created_at: new Date(p.created_at),
      startup: {
        ...p.startup,
        // Fixing unperfected type generation of startup_labels
        // TODO: Review with Kam
        funnel_labels: (p.startup.funnel_labels || []) as Array<{
          value: StartupFunnelLabel;
        }>,
      },
    }));

  // We use this over initialState.sorting.sortingModel because we want to keep the sorting model
  // in sync with cache updates
  // eslint-disable-next-line
  const [sortingModel, setSortingModel] = useState<any[]>(
    DEFAULT_SORTING_MODEL,
  );

  return (
    <Card>
      <TypeSafeStyledDataGrid<PitchForPitchInbox>
        rows={rows}
        loading={data === undefined}
        sortModel={sortingModel}
        onSortModelChange={setSortingModel}
        columns={columns}
        columnVisibilityModel={{ id: false, created_at: false }}
        onRowClick={value => {
          openStartupSidePanel(value.row.startup.id, {
            startupListId: undefined,
            pitchId: value.row.id,
          });
        }}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        disableRowSelectionOnClick
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 30 } },
        }}
        isRowClickable={false}
      />
    </Card>
  );
};

const CustomNoRowsOverlay = () => {
  const currentOrganization = useCurrentOrganizationFromContext();
  const organizationUUID = currentOrganization.uuid;

  return (
    <EmptyContent
      title={
        <Stack
          component='div'
          sx={{
            // This allow the content to be selectable/clickable
            zIndex: 5,
          }}
          spacing={2}
          direction='column'
          alignItems='center'
        >
          <Box component='span'>Let startups pitch directly to your inbox</Box>
          <BaseCopyToClipboard
            text={PATH_ROOT.startupPitchFormLink(organizationUUID)}
            successMessage='Link copied'
          >
            <Button startIcon={<LinkIcon />} variant='outlined'>
              Copy link
            </Button>
          </BaseCopyToClipboard>
        </Stack>
      }
    />
  );
};

export { PitchInboxTable };
