import { useEffect, useRef } from 'react';

function usePrevious<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function usePreviousTruthy<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    if (value) ref.current = value;
  });
  return ref.current;
}

export { usePrevious, usePreviousTruthy };
