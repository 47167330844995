import {
  Alert,
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popper,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';

import { ExpandLess, ExpandMore, InfoOutlined } from '@mui/icons-material';
import { Maybe } from 'apollo/generated/sdkInnovationManager';
import {
  EnumTableEntityVisibilityEnum,
  EnumTableProjectStagesEnum,
  GetProjectStartupListQuery,
  useDeleteProjectStartupListMutation,
  useGetProjectsAndLeadsMinimalQuery,
  useGetProjectStartupListQuery,
  useInsertProjectStartupListMutation,
} from 'apollo/generated/sdkShared';
import BaseAddButtonForAutocomplete from 'components/base/BaseAddButtonForAutocomplete';
import { BaseSnackbarActionNavigateToProject } from 'components/base/BaseSnackbarActions';
import { useServerFiltersContext } from 'components/base/serverFilters/BaseServerFiltersContext';
import { WARNING_TEXT_COLOR } from 'components/base/typography/constants';
import RequestProjectPermissionsButton from 'components/permissions/projects/RequestProjectPermissionsButton';
import { HTMLAttributes, RefObject, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { buildProjectDetailsPath } from 'routes/paths';
import { useOwnerButtonStyles } from './EditOwner';
import { useCreateLeadForList } from './useCreateLeadForList';
import { useUpdateStartupListActivities } from '../useUpdateStartupListActivities';

type StartupList = {
  id: number;
  title: string;
  projectId?: number;
  visibility: EnumTableEntityVisibilityEnum;
  scope_id?: number | null;
};

type Props = {
  startupList: StartupList;
};

const elementWidth = '300px';

const textFieldSx: SxProps<Theme> = ({ palette, spacing }) => ({
  height: '100%',
  background: palette.grey[200],
  paddingRight: `${spacing(3)} !important`,
  fontSize: '14px',
  minWidth: '210px',
  color: 'grey.800',
  marginTop: 0,
  '& input': {
    marginTop: 0,
    width: 'fit-content !important',
  },
});

const CreateNewLead = ({
  selectedList,
  onCreate,
  currentProjectsStartupListId,
}: {
  currentProjectsStartupListId?: number;
  selectedList: StartupList;
  onCreate: () => void;
}) => {
  const [title, setTitle] = useState('');
  const [shouldLinkPainPoint, setShouldLinkPainPoint] = useState(false);
  const [inheritPainPointModalOpen, setInheritPainPointModalOpen] =
    useState(false);
  const [createNewLeadModalOpen, setCreateNewLeadModalOpen] = useState(false);

  const [deleteProjectStartupList] = useDeleteProjectStartupListMutation();
  const { handleCreateLead } = useCreateLeadForList({
    ...(shouldLinkPainPoint ? { painPointId: selectedList.scope_id } : {}),
    startupListId: selectedList.id,
    title,
  });

  const { logStartupListActivity } = useUpdateStartupListActivities();
  const { enqueueSnackbar } = useSnackbar();

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log({ e });
    setTitle(e.target.value);
  };

  const handleCreate = async () => {
    if (currentProjectsStartupListId) {
      const { errors } = await deleteProjectStartupList({
        variables: { id: currentProjectsStartupListId },
      });

      if (errors) {
        enqueueSnackbar('Error unlinking list from lead', {
          variant: 'error',
        });
        return;
      }
    }

    const response = await handleCreateLead();

    if (!response?.projectId || !response?.stage) {
      return;
    }

    await logStartupListActivity({
      logs: [
        {
          action: 'project_leads',
          entityIds: response.projectId,
          entityType: 'project_leads',
        },
      ],
      startupListId: selectedList.id,
    });

    captureAnalyticsEvent('Project Lead Created', {
      projectId: response.projectId,
      source: 'Link List to Lead Button',
    });

    enqueueSnackbar('Saved', {
      variant: 'success',
      action: (
        <BaseSnackbarActionNavigateToProject
          projectLeadId={response.projectId}
          stage={response.stage}
        />
      ),
    });

    onCreate();
  };

  return (
    <>
      <BaseAddButtonForAutocomplete
        onClick={e => {
          e.stopPropagation();
          if (selectedList.scope_id) {
            setInheritPainPointModalOpen(true);
          } else {
            setCreateNewLeadModalOpen(true);
          }
        }}
        text='New Lead'
      />
      <InheritPainPointModal
        open={inheritPainPointModalOpen}
        onHide={() => setInheritPainPointModalOpen(false)}
        onConfirm={(shouldLinkPainPoint: boolean) => {
          setShouldLinkPainPoint(shouldLinkPainPoint);
          setCreateNewLeadModalOpen(true);
          setInheritPainPointModalOpen(false);
        }}
      />
      <CreateNewLeadModal
        open={createNewLeadModalOpen}
        onClose={() => {
          setCreateNewLeadModalOpen(false);
          setTitle('');
        }}
        title={title}
        onCreateLead={handleCreate}
        handleTitleChange={handleTitleChange}
      />
    </>
  );
};

const RenderInput = (params: AutocompleteRenderInputParams) => {
  return (
    <TextField
      {...params}
      autoFocus
      sx={{ width: '100%' }}
      InputProps={{
        sx: textFieldSx,
        ...params.InputProps,
      }}
      variant='standard'
      placeholder='Search or create new lead'
    />
  );
};

const RenderOption = ({
  htmlProps,
  option,
  startupList,
  onCreateNewLead,
  isSelected,
  currentProjectsStartupListId,
  onUnlink,
  listHasLimitedVisibility,
}: {
  htmlProps: HTMLAttributes<HTMLLIElement>;
  option: {
    __typename?: 'projects' | undefined;
    id: number;
    title: string;
    stage: EnumTableProjectStagesEnum;
  };
  currentProjectsStartupListId?: number;
  startupList: StartupList;
  onCreateNewLead: () => void;
  onUnlink: () => void;
  isSelected: boolean;
  listHasLimitedVisibility: boolean;
}) => {
  // @ts-expect-error: data-option-index is not a valid prop
  // eslint-disable-next-line react/prop-types
  const index = htmlProps?.['data-option-index'];
  const navigate = useNavigate();
  const path = buildProjectDetailsPath({ id: option.id, stage: option.stage });

  const [hovered, setHovered] = useState(false);

  return (
    <>
      {index === 0 && (
        <>
          {listHasLimitedVisibility && (
            <Alert
              icon={<InfoOutlined sx={{ fill: WARNING_TEXT_COLOR }} />}
              sx={{ marginX: 2, fontWeight: 'bold', color: WARNING_TEXT_COLOR }}
              severity='warning'
            >
              This list has limited visibility
            </Alert>
          )}
          <CreateNewLead
            selectedList={startupList}
            currentProjectsStartupListId={currentProjectsStartupListId}
            onCreate={onCreateNewLead}
          />
        </>
      )}
      <Box
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        component='li'
        sx={({ spacing }) => ({ height: spacing(5) })}
        {...htmlProps}
      >
        <Typography fontSize={14} noWrap>
          {option.title}
        </Typography>
        {(hovered || isSelected) && (
          <Stack direction='row' sx={{ marginLeft: 'auto' }}>
            {isSelected && (
              <Button
                size='small'
                onClick={e => {
                  e.stopPropagation();
                  onUnlink();
                }}
              >
                Unlink
              </Button>
            )}
            <Button
              sx={{ marginLeft: 'auto' }}
              size='small'
              onClick={e => {
                e.stopPropagation();
                navigate(path);
              }}
            >
              Open
            </Button>
          </Stack>
        )}
      </Box>
    </>
  );
};

const NoLinkedItem = ({
  isOpen,
  anchorEl,
  onClick,
  projectsStartupList,
}: {
  isOpen: boolean;
  anchorEl: RefObject<HTMLButtonElement>;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  projectsStartupList?: Maybe<
    GetProjectStartupListQuery['projects_startup_lists'][number]
  >;
}) => {
  const { commonButtonStyles, noOwnerButtonStyles, iconStyles } =
    useOwnerButtonStyles();
  const ExpandElement = isOpen ? ExpandLess : ExpandMore;
  const hasLinkedHiddenProject =
    !!projectsStartupList?.project_id && !projectsStartupList?.project;

  if (hasLinkedHiddenProject) {
    return (
      <RequestProjectPermissionsButton
        projectId={projectsStartupList.project_id}
      />
    );
  }

  return (
    <Button
      sx={{ ...commonButtonStyles, ...noOwnerButtonStyles }}
      endIcon={
        <ExpandElement
          sx={{ ...iconStyles, height: ({ spacing }) => spacing(3.5) }}
        />
      }
      ref={anchorEl}
      data-testid='link-list-to-lead-button'
      onClick={onClick}
    >
      <Typography
        fontSize={14}
        sx={{
          lineHeight: '2.099',
          color: 'grey.600',
          fontWeight: 500,
          '& hover': { backgroundColor: 'grey.50012' },
        }}
      >
        None
      </Typography>
    </Button>
  );
};

export default function LinkListToLead({ startupList }: Props) {
  const { normalizedDefaultFilter: leadsFilter } = useServerFiltersContext(
    'projectLeadsFilters',
  );
  const { normalizedDefaultFilter: projectsFilter } =
    useServerFiltersContext('projectPoCFilters');
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const {
    data,
    refetch,
    loading: projectStartupListLoading,
    previousData,
  } = useGetProjectStartupListQuery({
    variables: { startupListId: startupList.id },
    fetchPolicy: 'cache-and-network',
  });

  const listHasLimitedVisibility = startupList.visibility !== 'organization';
  const { commonButtonStyles, ownerButtonStyles, iconStyles } =
    useOwnerButtonStyles();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [insert] = useInsertProjectStartupListMutation();
  const { logStartupListActivity } = useUpdateStartupListActivities();
  const [deleteProjectStartupList, { loading: deleteLoading }] =
    useDeleteProjectStartupListMutation();
  const {
    data: projectLeadsData,
    loading: projectLeadsLoading,
    error,
  } = useGetProjectsAndLeadsMinimalQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: { _or: [leadsFilter, projectsFilter] },
    },
  });

  const projectsStartupList = data?.projects_startup_lists?.[0];

  const loading =
    projectStartupListLoading || projectLeadsLoading || deleteLoading;

  const value = useMemo(
    () => projectsStartupList?.project,
    [projectsStartupList?.project],
  );
  // Sort the options: selected first
  const options = useMemo(
    () =>
      projectLeadsData?.projects
        .slice()
        .sort(a => (a.id === value?.id ? -1 : 1)) || [],
    [projectLeadsData?.projects, value?.id],
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setIsOpen(prevState => !prevState);
  };

  const handleAddStartupToLead = async ({
    selectedLeadId,
    selectedLeadStage,
    shouldUnlink,
  }: {
    selectedLeadId?: number;
    selectedLeadStage?: EnumTableProjectStagesEnum;
    shouldUnlink?: boolean;
  }) => {
    try {
      const projectStartupListId = data?.projects_startup_lists?.[0]?.id;

      if (value && projectStartupListId) {
        const { errors } = await deleteProjectStartupList({
          variables: { id: projectStartupListId },
        });

        if (errors) {
          enqueueSnackbar('Error unlinking list from lead', {
            variant: 'error',
          });
          return;
        }
      }

      if (shouldUnlink && projectStartupListId) {
        await logStartupListActivity({
          logs: [
            {
              action: 'updated',
              entityIds: projectStartupListId,
              entityType: 'projects_startup_lists',
            },
          ],
          startupListId: startupList.id,
        });
        enqueueSnackbar('Unlinked', { variant: 'success' });
        await refetch();

        return;
      }

      if (!selectedLeadId || !selectedLeadStage) return;

      const { errors, data: linkedProjectData } = await insert({
        variables: {
          object: {
            startup_list_id: startupList.id,
            project_id: selectedLeadId,
          },
        },
      });

      const newProjectsStartupListsId =
        linkedProjectData?.insert_projects_startup_lists_one?.id;

      if (!errors && newProjectsStartupListsId) {
        enqueueSnackbar('Saved', {
          variant: 'success',
          action: (
            <BaseSnackbarActionNavigateToProject
              projectLeadId={selectedLeadId}
              stage={selectedLeadStage}
            />
          ),
        });
        await logStartupListActivity({
          logs: [
            {
              action: 'updated',
              entityIds: newProjectsStartupListsId,
              entityType: 'projects_startup_lists',
            },
          ],
          startupListId: startupList.id,
        });
        captureAnalyticsEvent('Lead/Project Linked to List', {
          projectId: selectedLeadId,
          source: 'Link List to Lead Button',
        });
        await refetch();
      }
    } catch (error) {
      captureException(error);
      enqueueSnackbar(`Error trying to link list with lead`, {
        variant: 'error',
      });
    }
  };

  if (loading && !previousData) return <Skeleton sx={{ minWidth: '170px' }} />;
  if (error) return <Typography color='error'>Error</Typography>;

  return (
    <>
      {!value?.title ? (
        <NoLinkedItem
          isOpen={isOpen}
          anchorEl={anchorEl}
          onClick={handleClick}
          projectsStartupList={projectsStartupList}
        />
      ) : (
        <Button
          variant='text'
          ref={anchorEl}
          onClick={handleClick}
          data-testid='link-list-to-lead-button'
          sx={{
            ...commonButtonStyles,
            ...ownerButtonStyles,
            justifyContent: 'flex-start',
            padding: '0px 2px',
            borderRadius: '8px',
          }}
          endIcon={
            !isOpen ? (
              <ExpandMore sx={iconStyles} />
            ) : (
              <ExpandLess sx={iconStyles} />
            )
          }
        >
          <Tooltip title={value?.title}>
            <Typography
              variant='inherit'
              lineHeight='2.099'
              color='#212b36'
              fontWeight={500}
            >
              {value?.title}
            </Typography>
          </Tooltip>
        </Button>
      )}
      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          open={isOpen}
          anchorEl={anchorEl.current}
          placement={'bottom-start'}
          sx={({ zIndex }) => ({ zIndex: zIndex.tooltip })}
        >
          <Autocomplete
            onKeyDown={e => {
              if (e.key === 'Escape') {
                setIsOpen(false);
              }
            }}
            disablePortal
            open={isOpen}
            disableListWrap
            disableCloseOnSelect
            clearOnEscape
            autoHighlight
            popupIcon={null}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            options={options}
            sx={{ minWidth: elementWidth, marginTop: 1 }}
            getOptionLabel={option => option.title}
            value={value}
            noOptionsText={<Typography>No options</Typography>}
            onChange={(_, newValue) => {
              if (newValue) {
                handleAddStartupToLead({
                  selectedLeadId: newValue?.id,
                  selectedLeadStage: newValue?.stage,
                });
              }
            }}
            data-testid='link-list-to-lead-autocomplete'
            slotProps={{
              paper: { sx: { width: elementWidth, whiteSpace: 'nowrap' } },
            }}
            renderOption={(htmlProps, option) => (
              <RenderOption
                key={`option-link-list-to-lead-${option.id}`}
                listHasLimitedVisibility={listHasLimitedVisibility}
                htmlProps={htmlProps}
                startupList={startupList}
                option={option}
                isSelected={option.id === value?.id}
                currentProjectsStartupListId={
                  data?.projects_startup_lists?.[0]?.id
                }
                onCreateNewLead={async () => {
                  await refetch();
                  setIsOpen(false);
                }}
                onUnlink={async () => {
                  await handleAddStartupToLead({ shouldUnlink: true });
                }}
              />
            )}
            renderInput={RenderInput}
          />
        </Popper>
      </ClickAwayListener>
    </>
  );
}

const InheritPainPointModal = ({
  open,
  onHide,
  onConfirm,
}: {
  open: boolean;
  onHide: () => void;
  onConfirm: (shouldLinkPainPoint: boolean) => void;
}) => {
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      onClose={onHide}
      sx={{
        zIndex: ({ zIndex }) => zIndex.tooltip,
      }}
    >
      <DialogTitle sx={{ paddingTop: 3 }}>Add new lead</DialogTitle>
      <DialogContent sx={{ marginTop: 3 }}>
        <Typography variant='body2'>
          Would you like your new lead to inherit the Pain Point from this list
        </Typography>
      </DialogContent>
      <DialogActions sx={{ paddingTop: '0px !important' }}>
        <Button
          variant='text'
          sx={{ color: 'grey.700' }}
          onClick={() => onConfirm(false)}
        >
          No
        </Button>
        <Button variant='contained' onClick={() => onConfirm(true)}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateNewLeadModal = ({
  open,
  onClose,
  title,
  onCreateLead,
  handleTitleChange,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  onCreateLead: () => void;
  handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        zIndex: ({ zIndex }) => zIndex.tooltip,
      }}
      fullWidth
    >
      <DialogTitle sx={{ paddingTop: 3 }}>Add new lead</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ marginTop: 4, width: '100%' }}
          autoFocus
          fullWidth
          label='New lead title'
          value={title}
          onChange={handleTitleChange}
        />
      </DialogContent>
      <DialogActions sx={{ paddingTop: '0px !important' }}>
        <Button variant='text' sx={{ color: 'grey.700' }} onClick={onClose}>
          Cancel
        </Button>
        <Button variant='contained' onClick={onCreateLead} disabled={!title}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
