import React, {
  createContext,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { SourcingOrderState } from '../request-form/sourcingOrderModel';

interface ProblemScopeContextType {
  preloadedState: SourcingOrderState;
  shownInModal?: boolean;
  closeOrderModal?: () => void;
  isByStakeholder: boolean;
  categoryId?: number;
  isBUPage?: boolean;
  renderedAsSection?: boolean;
  initialPlaceToAssociatePainPoint?: 'startup_list' | 'project' | 'lead';
  setShowMoreOpen?: Dispatch<SetStateAction<boolean>>;
  showMoreOpen?: boolean;
}

const ProblemScopeContext = createContext<ProblemScopeContextType | undefined>(
  undefined,
);

export const useProblemScopeContext = () => {
  const context = useContext(ProblemScopeContext);
  if (!context) {
    throw new Error(
      'useProblemScopeContext must be used within a ProblemScopeProvider',
    );
  }
  return context;
};

export const ProblemScopeProvider: React.FC<{
  children: ReactNode;
  value: ProblemScopeContextType;
}> = ({ children, value }) => {
  return (
    <ProblemScopeContext.Provider value={value}>
      {children}
    </ProblemScopeContext.Provider>
  );
};
