import { Divider, Stack } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { StakeholdersGridToolbarQuickFilter } from 'components/engagement/people/StakeholdersGridToolbarQuickFilter';

export function CustomToolbar() {
  return (
    <>
      <GridToolbarContainer
        sx={theme => ({
          '&.MuiDataGrid-toolbarContainer': {
            backgroundColor: theme.palette.grey[200],
          },
        })}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          width='100%'
        >
          <StakeholdersGridToolbarQuickFilter placeholder='Search' />
        </Stack>
      </GridToolbarContainer>
      <Divider />
    </>
  );
}
