import closeFill from '@iconify/icons-eva/close-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import { Icon as IconifyIcon } from '@iconify/react';
import { Add } from '@mui/icons-material';
import { Box, Button, ListItemText, Stack, Tooltip } from '@mui/material';
import { captureException } from '@sentry/react';
import {
  useDeleteFileMutation,
  useFilesWithSignedUrlsQuery,
  useGetFilesByProblemScopeIdQuery,
  useInsertProblemScopeFileByPkMutation,
} from 'apollo/generated/sdkShared';
import { MIconButton } from 'components/@material-extend';
import { BaseConfirmDeleteFileModal } from 'components/base/upload/BaseConfirmDeleteFileModal';
import UploadMultipleFiles from 'components/upload/UploadMultipleFiles';
import { FileError } from 'errors';
import useFiles from 'hooks/useFiles';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { fData } from 'utils/formatNumber';

export type UploadedFile = {
  id: number;
  attachment_url: string;
  file_id: number;
  name: string;
};

type FileToShow = UploadedFile | File;

export const useScopeFiles = ({
  problemScopeId,
}: {
  problemScopeId: number;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: filesData,
    loading: loadingfiles,
    error,
  } = useGetFilesByProblemScopeIdQuery({
    variables: {
      problemScopeId: problemScopeId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !problemScopeId,
  });

  const [deleteFile] = useDeleteFileMutation();

  const fileIds = useMemo(
    () => filesData?.problem_scopes_by_pk?.files.map(pf => pf.file_id),
    [filesData?.problem_scopes_by_pk?.files],
  );

  const skipFetchingSignedUrls = !fileIds;

  const { data: filesWithSignedUrlsData, loading: loadingFilesWithSignedUrls } =
    useFilesWithSignedUrlsQuery({
      variables: {
        files_ids: fileIds!,
      },
      skip: skipFetchingSignedUrls,
    });

  const files = useMemo(() => {
    const filesWithSignedUrls =
      filesWithSignedUrlsData?.files_with_signed_urls?.data;

    if (!filesData?.problem_scopes_by_pk || !Array.isArray(filesWithSignedUrls))
      return [];

    const signedUrlByFileId = filesWithSignedUrls.reduce<
      Record<number, string>
    >((acc, file) => {
      acc[file.id] = file.signed_url;
      return acc;
    }, {});

    const problemScopeFiles = filesData.problem_scopes_by_pk.files.map(
      ({ id, file_id, file }) => ({
        id,
        attachment_url: signedUrlByFileId[file_id],
        file_id,
        name: file.name,
      }),
    );

    return [...problemScopeFiles];
  }, [filesData, filesWithSignedUrlsData?.files_with_signed_urls?.data]);

  const uploadProblemScopeFile = useUploadProblemScopeFile();

  const deleteScopeFile = async (
    fileId: number,
    problemScopeFileId: number,
  ) => {
    try {
      await deleteFile({
        variables: {
          fileId: fileId,
        },
        update: (cache, { data }) => {
          if (!data) return;

          cache.evict({
            id: cache.identify({
              __typename: 'problem_scope_files',
              id: problemScopeFileId,
            }),
          });
          cache.gc();
        },
      });
    } catch (error) {
      captureException(error);
      enqueueSnackbar('Error deleting file', {
        variant: 'error',
      });
    }
  };

  return {
    files,
    loading: loadingfiles || loadingFilesWithSignedUrls,
    error,
    uploadProblemScopeFile,
    deleteScopeFile,
  };
};

export const useUploadProblemScopeFile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { uploadFile } = useFiles({ filePrefix: 'project_documents' });
  const [insertProblemScopeFileByPk] = useInsertProblemScopeFileByPkMutation();

  const uploadProblemScopeFile = async (file: File, problemScopeId: number) => {
    if (!file) return;

    try {
      const insertedFile = await uploadFile(file, 50);

      if (!insertedFile) return;

      const uploadedFileData = await insertProblemScopeFileByPk({
        variables: {
          object: {
            problem_scope_id: problemScopeId,
            file_id: insertedFile.id,
          },
        },
      });

      return { id: uploadedFileData.data?.insert_problem_scope_files_one?.id };
    } catch (error) {
      if (error instanceof FileError) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      } else {
        captureException(error);
        enqueueSnackbar('Error uploading file', {
          variant: 'error',
        });
      }
    }
  };

  return uploadProblemScopeFile;
};
type UploadScopeFileProps = {
  fieldName?: string;
  setFieldValue: (field: string, value: FileToShow[]) => void;
  problemScopeId?: number;
  isReadOnly?: boolean;
};

const useFileManagement = (
  problemScopeId: number,
  setFieldValue: (field: string, value: FileToShow[]) => void,
  fieldName: string,
) => {
  const { files, deleteScopeFile } = useScopeFiles({ problemScopeId });
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileToBeDeleted, setFileToBeDeleted] = useState<UploadedFile | null>(
    null,
  );

  useEffect(() => {
    const uploadedFileNames = files.map(file => file.name);
    setSelectedFiles(prev =>
      prev.filter(file => !uploadedFileNames.includes(file.name)),
    );
  }, [files]);

  const handleDropFiles = useCallback(
    (acceptedFiles: File[]) => {
      setSelectedFiles(prev => [...prev, ...acceptedFiles]);
      setFieldValue(fieldName, acceptedFiles);
    },
    [setFieldValue, fieldName],
  );

  const handleRemoveFile = (file: FileToShow) => {
    file instanceof File
      ? setSelectedFiles(prev => prev.filter(_file => _file !== file))
      : setFileToBeDeleted(file);
  };

  const handleRemoveUploadedFile = async (file: UploadedFile) => {
    await deleteScopeFile(file.file_id, file.id);
    setFileToBeDeleted(null);
  };

  return {
    files,
    selectedFiles,
    fileToBeDeleted,
    setFileToBeDeleted,
    handleDropFiles,
    handleRemoveFile,
    handleRemoveUploadedFile,
  };
};

const FileList = ({
  filesToShow,
  fileToBeDeleted,
  handleRemoveFile,
  isEditing,
}: {
  filesToShow: FileToShow[];
  fileToBeDeleted: UploadedFile | null;
  handleRemoveFile: (file: FileToShow) => void;
  isEditing: boolean;
}) => (
  <Stack
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px',
      flexDirection: 'row',
    }}
  >
    {filesToShow.map((file, i) => (
      <Box
        key={`selected-files-${i}`}
        sx={{
          width: 'fit-content',
          padding: 0.5,
          display: 'flex',
          alignItems: 'center',
          borderRadius: 1,
          bgcolor: '#EEEFF1',
          opacity: fileToBeDeleted === file ? 0.5 : 1,
          transition: 'opacity 0.3s',
        }}
      >
        <Box
          sx={{
            marginRight: 0.5,
            filter: fileToBeDeleted === file ? 'blur(2px)' : 'none',
            lineHeight: 0,
          }}
        >
          <IconifyIcon icon={fileFill} width={18} height={18} />
        </Box>
        <Tooltip title={file.name}>
          <ListItemText
            {...('attachment_url' in file
              ? {
                  primary: (
                    <a
                      href={file.attachment_url}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {file.name}
                    </a>
                  ),
                }
              : {
                  primary: file.name,
                })}
            secondary={file instanceof File ? fData(file.size) || 0 : ''}
            primaryTypographyProps={{
              variant: 'body2',
              sx: {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '6rem',
              },
            }}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </Tooltip>
        {isEditing && fileToBeDeleted !== file && (
          <MIconButton
            edge='end'
            size='small'
            onClick={() => handleRemoveFile(file)}
            sx={{
              padding: '0px',
            }}
          >
            <IconifyIcon icon={closeFill} />
          </MIconButton>
        )}
      </Box>
    ))}
  </Stack>
);

export const UploadScopeFile = ({
  fieldName = 'scope_files',
  setFieldValue,
  problemScopeId,
  isReadOnly = false,
}: UploadScopeFileProps) => {
  const {
    files,
    selectedFiles,
    fileToBeDeleted,
    setFileToBeDeleted,
    handleDropFiles,
    handleRemoveFile,
    handleRemoveUploadedFile,
  } = useFileManagement(problemScopeId!, setFieldValue, fieldName);

  return (
    <>
      {!isReadOnly && (
        <UploadMultipleFiles files={selectedFiles} onDrop={handleDropFiles} />
      )}
      <Box marginTop={1}>
        <FileList
          filesToShow={[...files, ...selectedFiles]}
          fileToBeDeleted={fileToBeDeleted}
          handleRemoveFile={handleRemoveFile}
          isEditing={!isReadOnly}
        />
      </Box>
      {fileToBeDeleted && (
        <BaseConfirmDeleteFileModal
          title={fileToBeDeleted.name}
          onConfirm={() => handleRemoveUploadedFile(fileToBeDeleted)}
          onHide={() => setFileToBeDeleted(null)}
        />
      )}
    </>
  );
};

export const UploadScopeFileEditable = (props: UploadScopeFileProps) => {
  const {
    files,
    selectedFiles,
    fileToBeDeleted,
    setFileToBeDeleted,
    handleRemoveFile,
    handleRemoveUploadedFile,
    handleDropFiles,
  } = useFileManagement(
    props.problemScopeId!,
    props.setFieldValue,
    props.fieldName ?? 'scope_files',
  );

  const [isEditing, setIsEditing] = useState(false);
  const { getInputProps } = useDropzone({
    multiple: true,
    onDrop: handleDropFiles,
  });

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <Stack
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '4px 2px',
          borderRadius: '8px',
          '&:hover': {
            background: !isEditing && files.length ? '#F8F8F8' : 'none',
            cursor: 'text',
          },
          boxShadow:
            isEditing && files.length ? ({ shadows }) => shadows[4] : 'none',
        }}
        onClick={() => setIsEditing(true)}
        onMouseLeave={() => setIsEditing(false)}
      >
        <FileList
          filesToShow={[...files, ...selectedFiles]}
          fileToBeDeleted={fileToBeDeleted}
          handleRemoveFile={handleRemoveFile}
          isEditing={isEditing}
        />
        {(isEditing || !files.length) && (
          <Button
            variant='text'
            sx={{ padding: '2px' }}
            startIcon={<Add />}
            onClick={() => fileInputRef.current?.click()}
          >
            Add
          </Button>
        )}
        <input {...getInputProps()} ref={fileInputRef} />
      </Stack>
      {fileToBeDeleted && (
        <BaseConfirmDeleteFileModal
          title={fileToBeDeleted.name}
          onConfirm={() => handleRemoveUploadedFile(fileToBeDeleted)}
          onHide={() => setFileToBeDeleted(null)}
        />
      )}
    </>
  );
};
