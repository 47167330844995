import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EnumTableStakeholderMaturityEnum } from 'apollo/generated/sdkInnovationManager';
import { BusinessUnitPersonRow } from 'contexts/PersonTableContext';
import React from 'react';
import { maturityValueOptions } from './useGetColumns';
import { Box, LinearProgress, Stack, Tooltip, Typography } from '@mui/material';

const getMaturityLabel = (
  maturity: EnumTableStakeholderMaturityEnum,
): string => {
  return (
    maturityValueOptions.find(option => option.value === maturity)?.label || ''
  );
};

const MaturityCell = (
  params: GridRenderCellParams<
    BusinessUnitPersonRow,
    EnumTableStakeholderMaturityEnum
  >,
) => {
  const { value } = params;

  if (!value) return null;

  const index = [...maturityValueOptions]
    .reverse()
    .findIndex(({ value: valueOfIndex }) => value === valueOfIndex);

  const toPercentage =
    Math.round((index / (maturityValueOptions.length - 1)) * 100) || 0;

  return (
    <Tooltip
      title={
        <Stack>
          <Typography variant='caption'>
            0%: {getMaturityLabel('no_interactions')}
          </Typography>
          <Typography variant='caption'>
            33%: {getMaturityLabel('requests_submitted')}
          </Typography>
          <Typography variant='caption'>
            67%: {getMaturityLabel('projects_started')}
          </Typography>
          <Typography variant='caption'>
            100%: {getMaturityLabel('pocs_completed')}
          </Typography>
        </Stack>
      }
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant='determinate' value={toPercentage} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography
              variant='body2'
              color='text.secondary'
            >{`${toPercentage}%`}</Typography>
          </Box>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default MaturityCell;
