import { CloseOutlined } from '@mui/icons-material';
import { useTheme, Chip } from '@mui/material';
import { NAV_TEXT_GREY } from 'components/NavSection';

export function StartupListFeatureBox({
  editModeEnabled,
  featureText,
  onDelete,
  onClick,
}: {
  editModeEnabled?: boolean;
  featureText: string;
  onDelete?: () => void;
  onClick?: () => void;
}) {
  const { spacing } = useTheme();

  return (
    <Chip
      onClick={onClick}
      label={
        featureText.length > 40 ? `${featureText.slice(0, 40)}...` : featureText
      }
      variant='outlined'
      onDelete={editModeEnabled ? onDelete : undefined}
      sx={{
        background: '#EEEFF1',
        borderRadius: spacing(1),
        border: 0,
        height: spacing(3.5),
      }}
      deleteIcon={
        <CloseOutlined
          fontSize='small'
          sx={({ spacing }) => ({
            width: spacing(2),
            height: spacing(2),
            fill: NAV_TEXT_GREY,
            marginRight: 1,
          })}
        />
      }
    />
  );
}
