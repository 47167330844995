import { styled, useTheme } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';

export function TypeSafeStyledDataGrid<T extends GridValidRowModel>(
  params: DataGridProProps<T> & { isRowClickable?: boolean },
) {
  const { palette } = useTheme();
  const rows = params.rows;

  return (
    <DataGridPro
      {...{
        disableColumnMenu: true,
        ...params,
        autoHeight: rows.length > 0,
        sx: {
          ...(params.rows.length === 0 ? { height: 600 } : {}),
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .BaseBadge-root .BaseBadge-badge': {
            display: 'none !important',
          },
          '& .MuiDataGrid-cell--editing': {
            backgroundColor: 'rgb(255,215,115, 0.19)',
            color: '#1A3E72',
            '& .MuiInputBase-root': {
              height: '90%',
              borderRadius: '5px',
              'input[type=text]': {
                height: '100%',
                borderRadius: '5px',
                border: '1px solid lightgrey',
                fontSize: '1rem',
                '&:hover': {
                  border: `1px solid ${palette.grey[800]}`,
                },
                '&:focus': {
                  border: `2px solid ${palette.primary.main}`,
                  height: '95%',
                },
              },
            },
            '& .MuiTextField-root': {
              height: '100%',
              borderRadius: '5px',
              marginTop: '5px',
            },
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            cursor: params.isRowClickable ? 'pointer' : 'default',
            '&.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus-visible':
              {
                borderRadius: 'unset',
              },
            '&.MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root ': {
              borderRadius: 'unset',
            },
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },

          '& .MuiDataGrid-pinnedColumnHeaders--right': {
            display: 'none !important',
          },

          '& .MuiDataGrid-pinnedColumns--right': {
            boxShadow: 'unset',
            background: 'transparent',

            '& .MuiDataGrid-row': {
              background: 'transparent !important',
            },

            '& .MuiDataGrid-cell': {
              borderLeft: 'none',
              padding: '0px !important',
              minHeight: 'unset !important',
              background: 'transparent',
            },
          },

          '& .MuiDataGrid-pinnedColumns': {
            boxShadow: 'unset',
          },

          '& .actions-header': {
            '& > .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
          },
        },
      }}
    />
  );
}

// Extending DataGridProProps with a new property
interface ExtendedDataGridProProps<R extends GridValidRowModel>
  extends DataGridProProps<R> {
  isRowClickable: boolean;
}

const InternalStyledDataGrid = styled(
  ({
    // This is a hack to make Typescript work when using styled + DataGrid
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isRowClickable,
    ...props
  }: DataGridProProps & { isRowClickable?: boolean }) => (
    <DataGridPro {...props} disableColumnMenu />
  ),
)(({ isRowClickable = true }: { isRowClickable?: boolean }) => {
  const { palette } = useTheme();
  return {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell': {
      cursor: isRowClickable ? 'pointer' : 'default !important',
    },
    '& .BaseBadge-root .BaseBadge-badge': {
      display: 'none !important',
    },
    '& .MuiDataGrid-cell--editing': {
      backgroundColor: 'rgb(255,215,115, 0.19)',
      color: '#1A3E72',
      '& .MuiInputBase-root': {
        height: '90%',
        borderRadius: '5px',
        'input[type=text]': {
          height: '100%',
          borderRadius: '5px',
          border: '1px solid lightgrey',
          fontSize: '1rem',
          '&:hover': {
            border: `1px solid ${palette.grey[800]}`,
          },
          '&:focus': {
            border: `2px solid ${palette.primary.main}`,
            height: '95%',
          },
        },
      },
      '& .MuiTextField-root': {
        height: '100%',
        borderRadius: '5px',
        marginTop: '5px',
      },
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
      cursor: isRowClickable && 'pointer',
      '&.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus-visible':
        {
          borderRadius: 'unset',
        },
      '&.MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root ': {
        borderRadius: 'unset',
      },
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },

    '& .MuiDataGrid-pinnedColumnHeaders--right': {
      display: 'none !important',
    },

    '& .MuiDataGrid-pinnedColumns--right': {
      boxShadow: 'unset',
      background: 'transparent',

      '& .MuiDataGrid-row': {
        background: 'transparent !important',
      },

      '& .MuiDataGrid-cell': {
        borderLeft: 'none',
        padding: '0px !important',
        minHeight: 'unset !important',
        background: 'transparent',
      },
    },

    '& .MuiDataGrid-pinnedColumns': {
      boxShadow: 'unset',
    },

    '& .actions-header': {
      '& > .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
  };
});

// Defining a component that accepts ExtendedDataGridProProps
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledDataGrid = <R extends GridValidRowModel = any>(
  props: ExtendedDataGridProProps<R>,
): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isRowClickable, ...dataGridProps } = props;
  // You can use isRowClickable for conditional logic here, if needed

  // Assuming DataGridPro can accept all the rest of the props without modification
  return (
    <InternalStyledDataGrid
      {...({ isRowClickable, ...dataGridProps } as DataGridProProps<R>)}
    />
  );
};

export default StyledDataGrid;
