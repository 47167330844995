import { Box, Card, Link, Typography } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import StyledDataGrid from 'components/StyledDataGrid';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { formatDate } from 'utils/datetime';
import { EnumTableChallengeStatusesEnum } from 'apollo/generated/sdkInnovationManager';
import { Person } from '../../../@types/stakeholder';
import useAlternativeNavigate from 'hooks/useAlternativeNavigate';
import EmptyContent from 'components/EmptyContent';
import { parseISO } from 'date-fns';

type ChallengeDataType = {
  id: number;
  status: EnumTableChallengeStatusesEnum;
  updated_at: string;
  created_by: Person;
};

export const getChallengeStatusInfo = (
  status: EnumTableChallengeStatusesEnum,
) => {
  let labelText, labelColor;

  switch (status) {
    case 'discarded':
      labelColor = 'error';
      labelText = 'Discarded';
      break;
    default:
      labelColor = 'primary';
      labelText = 'Active';
      break;
  }

  return { labelColor, labelText };
};

export default function ChallengesTable({
  challengesData,
  loading,
}: {
  challengesData: ChallengeDataType[];
  loading: boolean;
}) {
  const navigate = useNavigate();

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'created_at', sort: 'desc' },
  ]);

  const challengesTableColumns = useGetChallengesTableColumns();

  const onChallengeClicked = (id: number) => {
    return navigate(PATH_ROOT.challenges._challengeDetails(id as number));
  };

  return (
    <>
      <Card sx={{ marginTop: 2, marginBottom: 2 }}>
        <StyledDataGrid
          loading={loading}
          isRowClickable
          sortModel={sortModel}
          onSortModelChange={sortModel => setSortModel(sortModel)}
          rows={challengesData}
          columns={challengesTableColumns}
          columnVisibilityModel={{ id: false, created_at: false }}
          autoHeight={Boolean(challengesData.length > 0)}
          disableRowSelectionOnClick
          disableColumnSelector
          onRowClick={(data, event) => {
            if (event.metaKey || event.ctrlKey) {
              window.open(
                `${window.location.origin}${PATH_ROOT.challenges._challengeDetails(
                  parseInt(data.id as string),
                )}`,
                '_blank',
              );
              return;
            }
            onChallengeClicked(parseInt(data.id as string));
          }}
          slots={{ noRowsOverlay: CustomNoRowsOverlay }}
          sx={challengesData.length === 0 ? { minHeight: 500 } : undefined}
        />
      </Card>
    </>
  );
}

const CustomNoRowsOverlay = () => {
  return (
    <Box sx={{ flexGrow: 1, height: 'auto' }}>
      <EmptyContent title={'You have no challenges yet'} />
    </Box>
  );
};

const useGetChallengesTableColumns = () => {
  const { navigateTo } = useAlternativeNavigate();

  const columns = useMemo<GridColDef[]>(
    () => [
      { field: 'id', filterable: false },
      { field: 'created_at', filterable: false },
      {
        field: 'title',
        headerName: 'Title',
        width: 350,
        filterable: false,
      },
      {
        field: 'created_by',
        headerName: 'Owner',
        minWidth: 160,
        flex: 0.5,
        filterable: false,
        renderCell: params => {
          const person = params.row.created_by;

          return (
            <Link
              color='secondary'
              variant='body2'
              onClick={e => {
                e.stopPropagation();
                navigateTo(PATH_ROOT.personProfile.details(person.id));
              }}
              sx={{ cursor: 'pointer' }}
            >
              {person.full_name}
            </Link>
          );
        },
      },
      {
        field: 'challenge_stakeholders',
        headerName: 'Stakeholders',
        minWidth: 190,
        flex: 0.6,
        filterable: false,
        renderCell: params => {
          const stakeholderCount = params.value.length;
          const firstStakeholder = params.value[0]?.stakeholder;
          const cellValue =
            stakeholderCount > 1
              ? `${firstStakeholder.full_name} +${stakeholderCount - 1}`
              : stakeholderCount === 1
                ? firstStakeholder.full_name
                : '';

          return (
            <>
              <Typography variant='body2'>{cellValue}</Typography>
            </>
          );
        },
      },
      {
        field: 'updated_at',
        headerName: 'Last updated',
        type: 'dateTime',
        width: 160,
        filterable: false,
        valueGetter: (params: GridRenderCellParams) =>
          params.value ? parseISO(params.value) : null,
        renderCell: params => {
          return (
            <>
              <Typography variant='body2'>
                {formatDate(params.value as string)}
              </Typography>
            </>
          );
        },
      },
      {
        field: 'connected_list_count',
        headerName: 'Lists',
        minWidth: 120,
        flex: 0.5,
        filterable: false,
        valueGetter: params => params.value.aggregate?.count || '-',
      },
      {
        field: 'connected_project_count',
        headerName: 'Leads/Projects',
        minWidth: 120,
        flex: 0.5,
        filterable: false,
        valueGetter: params => params.value.aggregate?.count || '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return columns;
};
