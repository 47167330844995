import { Drawer, Skeleton, Stack } from '@mui/material';
import { useGetSourcingOrderByIdQuery } from 'apollo/generated/sdkShared';
import { SEARCH_PARAMS } from 'config';
import { useGetProblemScope } from 'hooks/useGetProblemScope';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SourcingOrderDrawerHeader } from './SourcingOrderHeader';
import { SourcingOrderScopeContent } from './SourcingOrderScopeContent';

const DRAWER_WIDTH = 800;

export const useSourcingOrderDrawer = () => {
  const [searchParams] = useSearchParams();
  const buildDrawerSearchPath = useCallback(
    (briefId: number) => {
      return `?${new URLSearchParams({
        ...Object.fromEntries(searchParams),
        [SEARCH_PARAMS.sourcingOrderId]: String(briefId),
      }).toString()}`;
    },
    [searchParams],
  );

  return {
    buildDrawerSearchPath,
  };
};

const SourcingOrderDrawer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sourcingOrderId = searchParams.get(SEARCH_PARAMS.sourcingOrderId)
    ? Number(searchParams.get(SEARCH_PARAMS.sourcingOrderId))
    : null;
  const closeDrawer = useCallback(() => {
    searchParams.delete(SEARCH_PARAMS.sourcingOrderId);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);
  const { data, loading } = useGetSourcingOrderByIdQuery({
    variables: { id: sourcingOrderId! },
    skip: !sourcingOrderId,
  });
  const sourcingOrder = !loading && data?.sourcing_order;

  const problemScopeId =
    data?.sourcing_order?.startup_list?.scope_id ??
    data?.sourcing_order?.startup_list?.project?.scope_id;

  const { problemScope, problemScopeLoading } = useGetProblemScope(
    problemScopeId ?? undefined,
  );

  return (
    <Drawer
      anchor='right'
      open={sourcingOrderId !== null}
      onClose={closeDrawer}
    >
      {sourcingOrder ? (
        <Stack
          sx={{ width: DRAWER_WIDTH, paddingX: 1, marginTop: 0.5, padding: 2 }}
        >
          <SourcingOrderDrawerHeader sourcingOrder={sourcingOrder} />
          <SourcingOrderScopeContent
            problemScope={!problemScopeLoading ? problemScope : null}
          />
        </Stack>
      ) : (
        <SkeletonContent />
      )}
    </Drawer>
  );
};

const SkeletonContent = () => {
  return (
    <Stack
      direction={'column'}
      width={DRAWER_WIDTH}
      spacing={4}
      paddingTop={2}
      paddingX={3}
    >
      <Skeleton variant='rectangular' height={50} sx={{ borderRadius: 2 }} />
      <Skeleton variant='rectangular' height={200} sx={{ borderRadius: 2 }} />
      <Skeleton variant='rectangular' height={200} sx={{ borderRadius: 2 }} />
      <Skeleton variant='rectangular' height={200} sx={{ borderRadius: 2 }} />
    </Stack>
  );
};

export { SourcingOrderDrawer };
