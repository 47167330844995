import { TextField } from '@mui/material';

import { SourcingOrderState } from 'pages/request-form/sourcingOrderModel';
import { FieldProps } from '../../pages/request-form/types';
import { EditableField, FormLabelWithTooltip } from './ProblemField';

type DesiredSolutionSchemaValues = Pick<SourcingOrderState, 'desired_solution'>;

export function DesiredSolutionField({
  getFieldProps,
  touched,
  errors,
  isEditMode,
  showTooltip,
  handleToggle,
  toggleOpen,
}: FieldProps<Pick<DesiredSolutionSchemaValues, 'desired_solution'>>) {
  return (
    <>
      <FormLabelWithTooltip
        label='Desired solution'
        tooltipText='Please describe your preferred solution and any aspects you’d like to avoid in it.'
        isMandatory
        showTooltip={showTooltip}
      />
      {!isEditMode ? (
        <TextField
          rows={4}
          multiline={true}
          fullWidth
          data-test='desired-solution--field'
          {...getFieldProps('desired_solution')}
          error={Boolean(touched.desired_solution && errors.desired_solution)}
          helperText={touched.desired_solution && errors.desired_solution}
        />
      ) : (
        <EditableField
          data-test='desired-solution--field'
          {...getFieldProps('desired_solution')}
          handleToggle={handleToggle}
          toggleOpen={toggleOpen}
        />
      )}
    </>
  );
}
