import { Typography } from '@mui/material';

import { Stack } from '@mui/material';
import { Pitch } from './StartupPitchDetailsTab';
import { PitchMarkdown } from './PitchMarkdown';

const DefaultPitchSubmission = ({ pitch }: { pitch: Pitch }) => {
  if (!pitch) return null;

  return (
    <>
      <Stack>
        <Typography variant='subtitle1'>Pitch</Typography>
        <PitchMarkdown content={pitch.elevator_pitch} />
      </Stack>
      <Stack>
        <Typography variant='subtitle1'>Differentiation</Typography>
        <PitchMarkdown content={pitch.differentiation} />
      </Stack>
      {pitch.customer_references && (
        <Stack>
          <Typography variant='subtitle1'>Referenced Customers</Typography>
          <PitchMarkdown content={pitch.customer_references} />
        </Stack>
      )}
      {pitch.enterprise_pricing_model && (
        <Stack>
          <Typography variant='subtitle1'>Enterprise pricing model</Typography>
          <PitchMarkdown content={pitch.enterprise_pricing_model} />
        </Stack>
      )}
      {pitch.poc_experience && (
        <Stack>
          <Typography variant='subtitle1'>PoC experience</Typography>
          <PitchMarkdown content={pitch.poc_experience} />
        </Stack>
      )}
    </>
  );
};

export default DefaultPitchSubmission;
