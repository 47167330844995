import { Divider, Stack, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import {
  useArchivePersonActionMutation,
  useDeletePersonConfirmDialogPeopleQuery,
} from 'apollo/generated/sdkShared';
import { BaseDialog } from 'components/base/BaseDialog';
import BaseErrorMessage from 'components/base/BaseErrorMessage';
import { formatDistance } from 'date-fns';
import { useSnackbar } from 'notistack';
import React from 'react';

const DeletePersonConfirmDialog = ({
  peopleIds,
  onDelete,
  onClose,
  type,
}: {
  peopleIds: number[];
  onDelete: () => void;
  onClose: () => void;
  type: 'admin' | 'member';
}) => {
  const [deletePerson, { loading: archiving }] =
    useArchivePersonActionMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, error } = useDeletePersonConfirmDialogPeopleQuery({
    variables: {
      peopleIds,
    },
  });

  if (loading) {
    return null;
  }

  if (error) {
    captureException(error);
    return <BaseErrorMessage isDialog onClose={onClose} />;
  }

  const people = data!.people;

  let entityCopy;
  if (type === 'admin') {
    entityCopy = people.length > 1 ? 'users' : 'user';
  } else {
    entityCopy = people.length > 1 ? 'stakeholders' : 'stakeholder';
  }

  const handleDelete = async () => {
    people.forEach(async person => {
      try {
        await deletePerson({
          variables: { personId: person.id },
          update: cache => {
            cache.evict({
              id: cache.identify({
                __typename: 'people',
                id: person.id,
              }),
            });
            cache.gc(); // Garbage collect any non-referenced items in the cache
          },
        });

        enqueueSnackbar(`${person.full_name} deleted successfully`, {
          variant: 'success',
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(`Error deleting ${person.full_name}`, {
          variant: 'error',
        });
      }
    });
  };

  return (
    <BaseDialog
      confirmCTA='Delete'
      confirmColor='error'
      loading={archiving}
      onHide={async () => {
        onClose();
      }}
      heading={
        <Stack>
          Delete the following {entityCopy}?
          <Typography component='span' variant='caption'>
            By confirming you will also delete them from all connected PoCs
          </Typography>
        </Stack>
      }
      onConfirm={async () => {
        await handleDelete();
        onDelete();
        onClose();
      }}
    >
      {people.map((person, index) => {
        const pocsInvolvement = person.projects_involved_in.length;
        const submittedRequestIds = person.submitted_requests.filter(
          sr => sr.status === 'pending_request',
        ).length;
        let notes = 0;
        if (person.user) {
          notes =
            person.user.project_notes.length +
            person.user.project_idea_notes.length +
            person.user.startup_notes.length;
        }

        return (
          <React.Fragment key={person.id}>
            <Stack gap={2}>
              <Stack
                direction='row'
                justifyContent='space-between'
                gap={1}
                sx={{
                  marginTop: index > 0 ? 2 : 0,
                }}
              >
                <Stack>
                  <Typography>{person.full_name}</Typography>
                  <Typography variant='caption'>{person.email}</Typography>
                </Stack>
                <Stack textAlign='right' justifyContent='space-between'>
                  <Typography variant='caption'>
                    {person.organization_department?.name || 'No department'}
                  </Typography>
                  {person.user?.created_at ? (
                    <Typography variant='caption'>
                      Active since{' '}
                      {formatDistance(
                        new Date(person.user.created_at),
                        new Date(),
                        {
                          addSuffix: true,
                        },
                      )}
                    </Typography>
                  ) : person.created_at ? (
                    <Typography variant='caption'>
                      Created{' '}
                      {formatDistance(new Date(person.created_at), new Date(), {
                        addSuffix: true,
                      })}
                    </Typography>
                  ) : null}
                </Stack>
              </Stack>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                gap={1}
              >
                <Typography variant='body2'>
                  Wrote <b>{notes}</b> notes
                </Typography>
                <Typography variant='body2'>
                  Has <b>{submittedRequestIds}</b> pending requests
                </Typography>
                <Typography variant='body2'>
                  Involved in <b>{pocsInvolvement}</b> PoCs
                </Typography>
              </Stack>
              {people.length > 0 && index < people.length - 1 && <Divider />}
            </Stack>
          </React.Fragment>
        );
      })}
    </BaseDialog>
  );
};

export default DeletePersonConfirmDialog;
