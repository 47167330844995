import { Send } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { useGetAllPeopleQuery } from 'apollo/generated/sdkInnovationManager';
import {
  useGetStartupListPermissionAclQuery,
  useInsertStartupListAclMutation,
  useUpsertPersonActionMutation,
} from 'apollo/generated/sdkShared';
import LoadingScreen from 'components/LoadingScreen';
import { RouterLink } from 'components/RouterLink';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import BaseInitialsAvatar, {
  avatarSizeSmall,
} from 'components/base/BaseInitialsAvatar';
import { AddPersonModal } from 'components/engagement/people/AddPersonModal';
import { EmailField } from 'components/shared/EmailField';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { usePersonContext } from 'contexts/PersonContext';
import { sortBy, uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { PATH_ROOT } from 'routes/paths';
import { Person } from '../../../../../@types/startupList';
import { StartupListForShare } from './ShareStartupList';
import StartupListAcl from '../../../../permissions/lists/StartupListAcl';
import { useShareStartupList } from './useShareStartupList';
import BaseAddButtonForAutocomplete from 'components/base/BaseAddButtonForAutocomplete';
import { useBaseStakeholderFilterOptions } from 'components/base/stakeholdersSelect/useBaseStakeholderFilterOptions';

const ShareEmailTabContent = ({
  currentStartupList,
  onShare,
  selectedStakeholders,
  setSelectedStakeholders,
}: {
  onShare: ReturnType<typeof useShareStartupList>['handleSharing'];
  currentStartupList: StartupListForShare;
  setSelectedStakeholders: Dispatch<SetStateAction<Person[]>>;
  selectedStakeholders: Person[];
}) => {
  const { data, loading: loadingStakeholders } = useGetAllPeopleQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { allowed_email_domains } = useCurrentOrganizationFromContext();
  const [addNewModalOpened, showAddNewModalOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const { person } = usePersonContext();
  const { data: aclData, refetch } = useGetStartupListPermissionAclQuery({
    variables: { startupListId: currentStartupList.id, personId: person!.id! },
    skip: !person,
  });
  const [insertAcl] = useInsertStartupListAclMutation();
  const [upsertPersonAction] = useUpsertPersonActionMutation();
  const [additionalMessage, setAdditionalMessage] = useState('');

  const slug = useMemo(() => {
    return `Email for ${sortBy(selectedStakeholders.map(s => s.email)).join(
      ', ',
    )}`;
  }, [selectedStakeholders]);

  const { enqueueSnackbar } = useSnackbar();

  const filterOptions = useBaseStakeholderFilterOptions();

  // TODO: add better loader
  if (loadingStakeholders)
    return (
      <Box sx={{ height: '13rem', justifyContent: 'center' }}>
        <LoadingScreen />
      </Box>
    );

  if (!data?.people) return null;

  const notInvited = data.people
    .filter(
      p => !aclData?.startup_lists_acl.find(acl => acl.person?.id === p.id),
    )
    .filter(p => p.id !== person?.id);

  const people: typeof data.people = notInvited
    .sort((a, b) => a.full_name.localeCompare(b.full_name))
    .map(p => ({
      ...p,
      department: p.organization_department?.name,
    }));

  const uniquePeople = uniqBy(people, (person: Person) => person.id);

  const hasBlacklistedDomains = selectedStakeholders.some(
    ({ email }) =>
      !(allowed_email_domains || []).some(domain => email.endsWith(domain)),
  );

  const handleStakeholderSelect = () => {
    setLoading(true);
    onShare({
      slug,
      sendToStakeholders: selectedStakeholders,
      additionalMessage,
    })
      .then(
        async () => {
          enqueueSnackbar('Email sent successfully', {
            variant: 'success',
          });
          await insertAcl({
            variables: {
              objects: selectedStakeholders.map(person => ({
                startup_list_id: currentStartupList.id,
                person_id: person.id,
              })),
            },
          });

          refetch();

          captureAnalyticsEvent('Share link for list via email', {
            emails: selectedStakeholders.map(s => s.email),
            startupListPublicUUID: currentStartupList.public_uuid,
          });
          setSelectedStakeholders([]);
        },
        e => {
          console.log({ e });
          captureException(e);
          enqueueSnackbar('Failed to send email', {
            variant: 'error',
          });
        },
      )
      .finally(() => setLoading(false));
  };

  return (
    <Stack gap={2}>
      <Stack>
        {hasBlacklistedDomains && (
          <Typography
            variant='body2'
            sx={({ palette }) => ({ color: palette.common.black })}
          >
            Some users have email addresses which are not allowlisted. You can
            update the allowlist in the{' '}
            <RouterLink
              sx={{ color: 'primary.main' }}
              to={PATH_ROOT.settings.users}
            >
              organization settings
            </RouterLink>
          </Typography>
        )}
      </Stack>

      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Autocomplete
          multiple
          fullWidth
          filterOptions={filterOptions}
          filterSelectedOptions
          options={uniquePeople}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          disableCloseOnSelect
          value={selectedStakeholders}
          onChange={async (_e, newValue: Person[]) => {
            setSelectedStakeholders(newValue);
          }}
          noOptionsText={
            <BaseAddButtonForAutocomplete
              onClick={() => showAddNewModalOpened(true)}
              divider={false}
              text='New Stakeholder'
            />
          }
          getOptionLabel={option => option.full_name}
          // TODO: Find why duplicates
          renderOption={(props, option, state) => {
            const text = option.department
              ? `${option.full_name} (${option.department})`
              : option.full_name;

            return (
              <>
                {state.index === 0 && (
                  <BaseAddButtonForAutocomplete
                    sxProps={{
                      position: 'sticky',
                      top: 0,
                    }}
                    onClick={() => showAddNewModalOpened(true)}
                    text='New Stakeholder'
                  />
                )}
                <Box component='li' {...props}>
                  <BaseInitialsAvatar
                    sx={avatarSizeSmall}
                    full_name={option.full_name}
                  />
                  <Stack>
                    <TypographyWithEllipsis>{text}</TypographyWithEllipsis>
                    <EmailField ignoreTooltip value={option.email} />
                  </Stack>
                </Box>
              </>
            );
          }}
          renderInput={({ inputProps, ...rest }) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                sx={{ backgroundColor: 'white' }}
                inputProps={{
                  ...inputProps,
                }}
                className='input-padding'
                {...rest}
                // TODO: add error handling
                // error={Boolean(slugError && !loading)}
                // helperText={slugError}
                variant='outlined'
                placeholder='Enter Recipients'
              />
            </div>
          )}
        />
      </Box>

      {selectedStakeholders.length > 0 && (
        <TextField
          placeholder='Add a message... (recommended)'
          value={additionalMessage}
          onChange={e => setAdditionalMessage(e.target.value)}
          InputProps={{
            endAdornment: (
              <LoadingButton
                type='submit'
                variant='contained'
                onClick={handleStakeholderSelect}
                disabled={!selectedStakeholders.length}
                loading={loading}
                loadingIndicator='Sending...'
                startIcon={<Send />}
              >
                Invite
              </LoadingButton>
            ),
          }}
        />
      )}

      {selectedStakeholders.length === 0 && (
        <StartupListAcl
          currentStartupList={currentStartupList}
          refetch={refetch}
          aclData={aclData?.startup_lists_acl || []}
        />
      )}

      <AddPersonModal
        open={addNewModalOpened}
        onHide={() => showAddNewModalOpened(false)}
        onSetValues={async object => {
          upsertPersonAction({
            variables: { object },
          })
            .then(({ data }) => {
              if (data?.upsert_person?.person) {
                setSelectedStakeholders([
                  ...selectedStakeholders,
                  data?.upsert_person?.person,
                ]);
              }
              showAddNewModalOpened(false);
            })
            .catch(e => {
              captureException(e);
              enqueueSnackbar(
                'There was an error while adding the stakeholder',
                {
                  variant: 'error',
                },
              );
            });

          return { stakeholderId: null };
        }}
      />
    </Stack>
  );
};

export { ShareEmailTabContent };
