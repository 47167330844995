import { AddShoppingCart } from '@mui/icons-material';
import { Button, MenuItem } from '@mui/material';
import useDraftSourcingModal from 'components/dashboard/sourcingOrder/useDraftSourcingModal';

const OrderSourcingOnStartupListDetails = ({
  startupList,
  invalidateCache,
}: {
  startupList: { id: number; title: string };
  invalidateCache: () => void;
}) => {
  const [openModal, createSourcingOrderModal] = useDraftSourcingModal({
    invalidateCache,
    startupListId: startupList.id,
    initialTitle: startupList.title,
    orderOrigin: 'list',
  });

  return (
    <MenuItem sx={{ color: 'text.secondary', padding: 0 }}>
      <Button
        startIcon={<AddShoppingCart />}
        variant={'contained'}
        sx={{
          whiteSpace: 'nowrap',
          borderRadius: 0,
          paddingX: 2.5,
          width: '100%',
          textAlign: 'left',
          justifyContent: 'flex-start',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          color: 'inherit',
          fontWeight: 'inherit',
          fontSize: 'inherit',
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'inherit',
          },
        }}
        onClick={openModal}
      >
        Order sourcing
      </Button>
      {createSourcingOrderModal}
    </MenuItem>
  );
};

export default OrderSourcingOnStartupListDetails;
