import { useGetSourcingOrderByListIdQuery } from 'apollo/generated/sdkInnovationManager';
import {
  useGetProjectForScopeQuery,
  useGetStartupListForScopeQuery,
} from 'apollo/generated/sdkShared';
import { sourcingOrderModel } from 'pages/request-form/sourcingOrderModel';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export function useProblemScopeData({
  embeddedListId,
  embeddedProjectId,
}: { embeddedListId?: number; embeddedProjectId?: number } = {}) {
  const { startupListId, startupListPublicUUID } = useParams<{
    startupListId?: string;
    startupListPublicUUID?: string;
    projectId?: string;
  }>();

  const resolvedListId = Number(startupListId) || embeddedListId;

  const { data: startupListData, loading: isStartupListLoading } =
    useGetStartupListForScopeQuery({
      variables: {
        publicUUID: startupListPublicUUID || '',
        startupListId: startupListPublicUUID ? -1 : resolvedListId,
      },
      skip: !startupListPublicUUID && !resolvedListId,
    });
  // listId = startupListData?.startup_lists?.[0]?.id;

  const resolvedProjectId =
    embeddedProjectId ?? startupListData?.startup_lists?.[0]?.project?.id;

  const { data: projectData, loading: isProjectLoading } =
    useGetProjectForScopeQuery({
      variables: { projectId: resolvedProjectId! },
      skip: !resolvedProjectId,
    });

  const { problemScope, categoryId } = useMemo(
    () => ({
      categoryId:
        startupListData?.startup_lists?.[0]?.project_categories?.[0]?.id,
      problemScope: resolvedListId
        ? startupListData?.startup_lists?.[0]?.problem_scope
        : projectData?.projects_by_pk?.problem_scope,
    }),
    [startupListData, projectData, resolvedListId],
  );

  const { data: sourcingOrderData, loading: isSourcingOrderDataLoading } =
    useGetSourcingOrderByListIdQuery({
      variables: { id: resolvedListId },
      fetchPolicy: 'network-only',
      skip: !resolvedListId,
    });

  const isLoading =
    isStartupListLoading || isSourcingOrderDataLoading || isProjectLoading;

  if (isLoading) {
    return { isLoading };
  }

  const preloadedState = sourcingOrderModel({
    startupListId: resolvedListId,
    projectId: resolvedProjectId,
    sourcingOrder: sourcingOrderData?.sourcing_orders?.[0],
    problemScope,
  });

  return {
    listId: startupListData?.startup_lists[0]?.id,
    projectId: resolvedProjectId,
    isLoading,
    categoryId,
    startupListPublicUUID,
    project: startupListData?.startup_lists[0]?.project ?? null,
    preloadedState,
    problemScope,
    problemScopeLoading: isStartupListLoading,
  };
}
