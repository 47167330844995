import { Box, SxProps, Typography } from '@mui/material';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import { ReactNode } from 'react';

export function ExpandedGridCell({
  value,
  multiLineElipsis,
  numberOfLines,
  variant,
  sxProps,
  wrapperSxProps,
  onClick,
}: {
  value: ReactNode;
  wrapperSxProps?: SxProps;
  sxProps?: SxProps;
  multiLineElipsis?: boolean;
  numberOfLines?: number;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit'
    | undefined;
  onClick?: () => void;
}) {
  return (
    <TypographyWithEllipsis
      sx={wrapperSxProps}
      tooltipTitle={<Typography variant='body2'>{value}</Typography>}
      tooltipVariant='light'
      numberOfLines={numberOfLines}
      variant={variant || 'body2'}
    >
      <Box
        sx={{
          ...(multiLineElipsis && {
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: numberOfLines || 2,
            WebkitBoxOrient: 'vertical',
          }),
          ...sxProps,
        }}
        onClick={onClick}
      >
        {value}
      </Box>
    </TypographyWithEllipsis>
  );
}
