import { captureException, captureMessage } from '@sentry/react';
import axios from 'axios';
import { slsApiRoot } from 'config';
import _ from 'lodash';
import { OptionsObject, VariantType } from 'notistack';
import { ReactNode } from 'react';
import { isValidUrl } from './url';
import * as colors from '@mui/material/colors';

const muiColorArray = [
  colors.red,
  colors.pink,
  colors.purple,
  colors.deepPurple,
  colors.indigo,
  colors.blue,
  colors.lightBlue,
  colors.cyan,
  colors.teal,
  colors.green,
  colors.lightGreen,
  colors.lime,
  colors.yellow,
  colors.amber,
  colors.orange,
  colors.deepOrange,
  colors.brown,
  colors.grey,
  colors.blueGrey,
];
export const stableSort = <T>(
  array: T[],
  comparator: (a: T, b: T) => number,
): T[] => {
  const stabilizedArray = array.map((el, index) => [el, index] as const);

  return stabilizedArray
    .sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    })
    .map(([el]) => el);
};

export const delaySnackBarNotistack = ({
  enqueueSnackbar,
  closeSnackbar,
  message,
  delayMilliseconds,
  variant,
}: {
  enqueueSnackbar: (
    message: React.ReactNode,
    options?: OptionsObject | undefined,
  ) => React.ReactText;
  closeSnackbar: (key?: string | number | undefined) => void;
  message: string;
  delayMilliseconds: number;
  variant: VariantType;
}) => {
  const key = new Date().getTime().toString();

  enqueueSnackbar(message, { key, persist: true, variant });

  setTimeout(() => {
    closeSnackbar(key);
  }, delayMilliseconds);
};

export const getNameInitials = (fullName: string | null | undefined) => {
  if (!fullName) {
    return 'N/A';
  }

  let [firstName, lastName] = fullName.split(' ');
  firstName = firstName.trim();
  lastName = lastName?.trim();

  if (!lastName) {
    // Writing a single letter as name break the app
    let text = `${firstName[0].toUpperCase()}`;
    if (firstName[1] !== undefined) text += `${firstName[1].toUpperCase()}`;
    return text;
  }

  return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
};

export const getOrganizationSubdomain = () => {
  const [subdomain, domain, superDomain] = window.location.hostname.split('.');
  const organizationSubdomainFromDomain = subdomain?.replace(
    'staging-app-',
    '',
  );
  // invitation flow
  const orgSubdomainFromQuery = new URLSearchParams(window.location.search).get(
    'organization_name',
  );

  if (orgSubdomainFromQuery) {
    return orgSubdomainFromQuery;
  }

  if (domain === 'dev-glassdollar') {
    return subdomain;
  } else if (
    import.meta.env.VITE_ENV === 'preview' ||
    organizationSubdomainFromDomain === 'localhost' ||
    domain === 'ngrok' ||
    superDomain === 'ngrok'
  ) {
    return localStorage.getItem('organization') || 'corpalpha';
  } else if (
    !organizationSubdomainFromDomain ||
    organizationSubdomainFromDomain === 'app' ||
    organizationSubdomainFromDomain === 'staging-app'
  )
    return null;

  return organizationSubdomainFromDomain;
};

export const findOrganizationIdBySubdomain = async (
  organizationSubdomain: string,
): Promise<{ auth0_id: string; uuid: string }> => {
  if (!organizationSubdomain) {
    captureMessage('Missing organization subdomain');

    throw new Error('Missing organization subdomain');
  }

  try {
    const response = await fetch(
      `${slsApiRoot}/auth0/organizations/${organizationSubdomain}`,
    );

    const {
      auth0_id,
      internal_uuid,
    }: { auth0_id: string; hasura_id: number; internal_uuid: string } =
      await response.json();

    if (!auth0_id || !internal_uuid) {
      throw new Error('Organization not found');
    }

    return { auth0_id, uuid: internal_uuid };
  } catch (e) {
    captureMessage('Organization not found', scope => {
      scope.setContext('subdomain', { subodomain: organizationSubdomain });
      return scope;
    });
    captureException(e);

    throw new Error('Organization not found');
  }
};

// We use `JSON.stringify` for escape purposes
// Even that's not semantically right this was the best option at the time
// `escape()` is recommendended not to be used ->
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/escape
export const toAPIArray = (arr: string[]): string[] =>
  // @ts-expect-error: TODO: Write a reason
  `{${arr.map(f => JSON.stringify(f.trim())).join(',')}}`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any

/**
 * @deprecated Use useFiles instead
 */
export const uploadToS3 = async (file: File, signedRequest?: string) => {
  const options = {
    headers: {
      'Content-Type': file.type,
    },
  };
  await axios.put(signedRequest!, file, options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFileExtension = (fileName: string | undefined): string => {
  if (typeof fileName !== 'string') return '';

  const baseName = fileName.split(/[/\\]/).pop();
  if (!baseName) return '';

  const lastIndexOfDot = baseName.lastIndexOf('.');
  if (lastIndexOfDot === -1) return ''; // No extension found

  return baseName.slice(lastIndexOfDot + 1);
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const formatStringListForDisplay = (strings: string[]): string => {
  // ['first', 'second', 'third'] => 'first, second and third'
  if (strings.length < 2) return strings[0] || '';
  if (strings.length === 2) return `${strings[0]} and ${strings[1]}`;
  return formatStringListForDisplay([
    `${strings[0]}, ${strings[1]}`,
    ...strings.slice(2, strings.length),
  ]);
};

export const sanitizeDomain = (domain: string): string => {
  let sanitizedDomain = domain;
  try {
    sanitizedDomain = new URL(sanitizedDomain).hostname.replace('www.', '');
  } catch (e) {
    sanitizedDomain = sanitizedDomain.replace(/(www\.|https?:\/\/)/, '');
    sanitizedDomain = sanitizedDomain.split('/')[0];
  }

  return sanitizedDomain;
};

export const truncateWithEllipsis = (text: string, length: number) => {
  return text.length > length ? text.substring(0, length) + '...' : text;
};

export const swapKeysAndValues = (obj: { [key: string]: string | number }) => {
  // { prop1: 'value1', prop2: 'value2' } => { value1: 'prop1', value2: 'prop2 }
  const swapped = Object.entries(obj).map(([key, value]) => [value, key]);

  return Object.fromEntries(swapped);
};

export const returnLongestString = (strings: Array<string | null>) => {
  const filteredStrings = strings.filter(s => !!s) as string[];
  return _.maxBy(filteredStrings, s => s.length);
};

export const getFirstNAnd = (items: string[], n: number) => {
  const first = items.slice(0, n).join(', ');
  const itemsLeft = items.length - n;
  return itemsLeft > 0 ? `${first} and ${itemsLeft} more` : first;
};

export const includesCaseInsensitive = (str: string, search: string) => {
  return str.toLowerCase().includes(search.toLowerCase());
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const parseLinksInText = ({
  textToParse,
  renderLink,
}: {
  textToParse: string;
  renderLink: (link: string, domain: string) => ReactNode;
}) => {
  return String(textToParse)
    ?.split(/\s+/g)
    .map((possibleHyperLink: string) => {
      return isValidUrl(possibleHyperLink) && getDomainName(possibleHyperLink)
        ? renderLink(possibleHyperLink, getDomainName(possibleHyperLink)!)
        : `${possibleHyperLink + ' '}`;
    });
};

const getDomainName = (url: string) => {
  const { hostname } = new URL(url);
  return hostname.replace(/.+\/\/|www.|\..+/g, '');
};

export function stringToColor(name: string) {
  let hash = 0;
  for (let i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % muiColorArray.length;
  return muiColorArray[index];
}
