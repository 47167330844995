import { CopyAll } from '@mui/icons-material';
import {
  Tooltip,
  Stack,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';

export const EmailField = ({
  value,
  tooltip = true,
  ignoreTooltip = false,
}: {
  value: string;
  tooltip?: boolean;
  ignoreTooltip?: boolean;
}) => {
  const { palette, shadows } = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const smallTextStyles = {
    fontSize: '12px',
    fontWeight: 600,
    color: palette.grey[500],
  };

  if (!tooltip) {
    return (
      <Typography sx={smallTextStyles} noWrap component='div'>
        {value}
      </Typography>
    );
  }

  return (
    <Tooltip
      slotProps={{
        tooltip: {
          sx: {
            background: palette.common.white,
            color: palette.text.primary,
            boxShadow: shadows['10'],
          },
        },
      }}
      title={
        ignoreTooltip ? null : (
          <Stack direction='row' alignItems='center'>
            <Typography variant='body2'>{value} </Typography>
            <CopyToClipboard
              onCopy={() => {
                enqueueSnackbar('Copied to clipboard', {
                  variant: 'success',
                });
              }}
              text={value || ''}
            >
              <IconButton size='small'>
                <CopyAll fontSize='small' />
              </IconButton>
            </CopyToClipboard>
          </Stack>
        )
      }
    >
      <Typography sx={smallTextStyles} noWrap component='div'>
        {value}
      </Typography>
    </Tooltip>
  );
};
