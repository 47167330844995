import { Circle } from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  TypographyTypeMap,
} from '@mui/material';
import {
  EnumTableProjectStagesEnum,
  GetProjectsForImplementationsKanbanQuery,
} from 'apollo/generated/sdkInnovationManager';
import { useGetProjectStageDataQuery } from 'apollo/generated/sdkShared';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import {
  STATUS_COLOR_MAPPING,
  StatusMap,
} from 'components/dashboard/startupList/constants';
import { RouterLink } from 'components/RouterLink';
import AssignTeamButton from 'components/teams/AssignTeamButton';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import { formatDistance, parseISO } from 'date-fns';
import { groupBy, isNumber } from 'lodash';
import { initialProjectStages } from 'pages/dashboard/scoping/ProjectSummary';
import { useMemo } from 'react';
import { buildProjectDetailsPath } from 'routes/paths';
import {
  DECORATED_STAGE_MAPPING,
  indexOfStage,
  mapStageNameForOrganization,
} from 'utils/projectStageEnum';
import { useGetRelatedStartups } from './useGetRelatedStartups';
import BaseInitialsAvatar from 'components/base/BaseInitialsAvatar';
import { FALLBACK_SUPPLIER_LOGO_URL } from 'components/AvatarGroup';
import { pxToRem } from 'theme/typography';
import {
  ProjectFilters,
  useServerFiltersContext,
} from 'components/base/serverFilters/BaseServerFiltersContext';

export type ProjectsForKanban =
  GetProjectsForImplementationsKanbanQuery['projects'];

export type ProjectItemForKanban = ProjectsForKanban[number];

export const CardSubtitle = ({ title }: { title: string }) => (
  <Typography variant='subtitle2' sx={{ color: 'text.disabled', marginTop: 1 }}>
    {title}
  </Typography>
);

export const CardTitle = ({
  health_status,
  title,
  path,
}: Pick<ProjectItemForKanban, 'health_status'> & {
  title: string;
  path: string;
}) => {
  return (
    <Typography
      data-testid={`kanban-card-${title}`}
      variant='subtitle2'
      sx={{ marginBottom: 1, minWidth: 0 }}
    >
      <RouterLink to={path} onClick={e => e.stopPropagation()}>
        {health_status && (
          <Circle
            sx={{
              color: STATUS_COLOR_MAPPING[health_status as keyof StatusMap],
              marginLeft: -0.5,
              marginRight: 0.5,
              marginBottom: -0.8,
            }}
          />
        )}

        {title}
      </RouterLink>
    </Typography>
  );
};

export const CardBUDepartment = ({
  project_stakeholders: stakeholders,
}: Pick<ProjectItemForKanban, 'project_stakeholders'>) => {
  const buDepartmentLead = stakeholders.find(
    stakeholder => stakeholder.role === 'project_leader',
  );

  return (
    <>
      <CardSubtitle title='Department' />
      {buDepartmentLead &&
      buDepartmentLead.stakeholder.organization_department?.name ? (
        <Typography sx={{ fontSize: pxToRem(14) }}>
          {buDepartmentLead.stakeholder.organization_department.name}
        </Typography>
      ) : (
        '-'
      )}
    </>
  );
};

type Startup = {
  id: number;
  name: string;
  domain: string;
  logo_url?: string | null;
};

export const StartupsInARow = ({
  startups,
  loading,
  visibleCount = 5,
  cardTitle,
}: {
  startups: Startup[];
  loading: boolean;
  visibleCount?: number;
  cardTitle: string;
}) => {
  const { openStartupSidePanel } = useStartupSidePanel();
  if (loading) {
    return <Skeleton />;
  }

  if (!startups || startups.length === 0) {
    return null;
  }

  const decoratedStartups = startups.map(startup => ({
    ...startup,
    logo_url: startup.logo_url || FALLBACK_SUPPLIER_LOGO_URL,
  }));
  const startupsVisible = decoratedStartups.slice(0, visibleCount);
  const startupsNotVisibleCount = decoratedStartups.length - visibleCount;

  return (
    <>
      <CardSubtitle title={cardTitle} />

      <Stack direction='row'>
        {startupsVisible.map(startup => (
          <IconButton
            key={startup.id}
            size='small'
            onClick={e => {
              e.stopPropagation();
              openStartupSidePanel(startup.id);
            }}
          >
            <BaseStartupAvatar
              key={startup.id}
              size='extra-tiny'
              startup={startup}
            />
          </IconButton>
        ))}
        {startupsNotVisibleCount > 0 && (
          <Typography variant='body2' alignSelf={'center'}>
            +{startupsNotVisibleCount}
          </Typography>
        )}
      </Stack>
    </>
  );
};

export const CardShortlisted = ({ projectId }: { projectId: number }) => {
  const getRelatedStartups = useGetRelatedStartups(projectId);

  if (!getRelatedStartups) {
    return null;
  }

  const { loading, shortlistedStartups } = getRelatedStartups;

  return (
    <StartupsInARow
      loading={loading}
      startups={shortlistedStartups}
      cardTitle='Shortlisted startup(s)'
    />
  );
};

export const CardStartups = ({ projectId }: { projectId: number }) => {
  const getRelatedStartups = useGetRelatedStartups(projectId);

  if (!getRelatedStartups) {
    return null;
  }

  const { loading, relatedOrganizationStartups } = getRelatedStartups;

  return (
    <StartupsInARow
      loading={loading}
      startups={relatedOrganizationStartups}
      cardTitle='Startups'
    />
  );
};

export const CardSelectedForPoC = ({ projectId }: { projectId: number }) => {
  const getRelatedStartups = useGetRelatedStartups(projectId);

  if (!getRelatedStartups) {
    return null;
  }

  const { loading, selectedStartupForPoC } = getRelatedStartups;

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Stack direction='column' alignItems='flex-start'>
      <CardSubtitle title='Selected for PoC' />
      {selectedStartupForPoC ? (
        <StartAvatarButton startup={selectedStartupForPoC} />
      ) : (
        <Typography variant='body2'>-</Typography>
      )}
    </Stack>
  );
};

const CardTeam = ({
  projectId,
  projectTitle,
  team,
}: {
  projectId: number;
  projectTitle: string;
  team?: {
    id: number;
    name: string;
  } | null;
}) => {
  return (
    <>
      <CardSubtitle title='Team' />
      <AssignTeamButton
        name={projectTitle}
        id={projectId}
        type='project'
        team={team}
        sxProps={{ alignSelf: 'flex-start', padding: 0 }}
      />
    </>
  );
};

const TimeSpentInStage = ({
  stage,
  projectId,
}: {
  stage: EnumTableProjectStagesEnum;
  projectId: number;
}) => {
  const { data, loading } = useGetProjectStageDataQuery({
    variables: { projectId, stage },
  });

  const { subdomain: organizationSubdomain } =
    useCurrentOrganizationFromContext();

  const stageName = mapStageNameForOrganization({
    name: DECORATED_STAGE_MAPPING[stage],
    organizationSubdomain,
  });

  const created_at = data?.project_stages?.find(
    s => stage === s.stage,
  )?.created_at;

  return (
    <Collapse in={!loading && !!created_at}>
      <Stack>
        <CardSubtitle title={`In ${stageName} since`} />
        <Typography variant='body2'>
          {loading || !created_at ? (
            <Skeleton variant='text' />
          ) : (
            formatDistance(new Date(), new Date(created_at), {
              addSuffix: false,
            })
          )}
        </Typography>
      </Stack>
    </Collapse>
  );
};

const CardAdoptionProbability = ({
  probability,
  stage,
}: {
  probability: number;
  stage: EnumTableProjectStagesEnum;
}) => {
  const titlePrefix = initialProjectStages.includes(stage) ? 'PoC' : 'Adoption';

  return (
    <>
      <CardSubtitle title={`${titlePrefix} Probability`} />
      <LinearProgress
        sx={{ marginTop: 1 }}
        variant='determinate'
        value={probability}
      />
    </>
  );
};

export const CardPainPointOwner = ({
  painPointOwners,
  typographyVariant,
}: {
  painPointOwners: {
    id: number;
    email: string;
    full_name: string;
  }[];
  typographyVariant?: TypographyTypeMap['props']['variant'];
}) => {
  if (painPointOwners.length === 0) {
    return null;
  }

  const { full_name } = painPointOwners[0];

  return (
    <>
      <CardSubtitle title='Pain Point Owner' />
      <Stack direction='row' gap={1} alignItems='center'>
        <BaseInitialsAvatar
          full_name={full_name || ''}
          sx={{
            width: '20px',
            height: '20px',
            fontSize: '10px',
            margin: 0,
          }}
        />
        <Tooltip title={full_name}>
          <Typography
            variant={typographyVariant ? typographyVariant : 'body1'}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: pxToRem(14),
            }}
          >
            {painPointOwners.length === 1
              ? full_name
              : `${full_name} +${painPointOwners.length - 1}`}
          </Typography>
        </Tooltip>
      </Stack>
    </>
  );
};

export const StartAvatarButton = ({
  startup,
}: {
  startup: {
    id: number;
    domain: string;
    name: string;
    logo_url?: string | null;
  };
}) => {
  const { openStartupSidePanel } = useStartupSidePanel();

  return (
    <Stack
      direction='row'
      key={startup!.id}
      onClick={e => {
        if (!startup || !startup.id) return;

        e.stopPropagation();

        openStartupSidePanel(startup.id);
      }}
      sx={{
        padding: 0.5,
        marginLeft: -0.5,
        '&:hover': {
          borderRadius: 1,
          backgroundColor: 'grey.300',
        },
      }}
    >
      <BaseStartupAvatar
        size='tiny'
        sx={{
          border: '1px solid transparent',
          marginRight: 0.5,
        }}
        startup={{
          ...startup,
          logo_url: startup.logo_url!,
        }}
      />
      <Typography variant='body2'>{startup.name}</Typography>
    </Stack>
  );
};

export const useGetCardFields = ({
  allowedStages,
  projects,
  scope,
}: {
  allowedStages: EnumTableProjectStagesEnum[];
  projects: GetProjectsForImplementationsKanbanQuery['projects'];
  scope: ProjectFilters;
}) => {
  const { has_teams, id: organizationId } = useCurrentOrganizationFromContext();
  const { filters } = useServerFiltersContext(scope);

  return useMemo(() => {
    const cards = projects.map(project => ({
      id: project.id,
      stage: project.stage,
      title: project.title,
      stage_order: project.stage_order,
      created_at: parseISO(project.created_at),
      render: () => {
        const currentIndexStage = indexOfStage(project.stage);
        const shouldRenderShortlisted =
          currentIndexStage === indexOfStage('assessment');
        const shouldRenderSelectedForPoC =
          currentIndexStage > indexOfStage('assessment');

        const showTeam =
          has_teams &&
          (organizationId === 71 || import.meta.env.VITE_ENV === 'development');

        return (
          <Box paddingBottom={2}>
            <CardTitle
              title={project.title || ''}
              path={buildProjectDetailsPath({
                id: project.id,
                stage: project.stage,
              })}
              health_status={project.health_status}
            />
            {filters && Object.keys(filters.department).length > 0 && (
              <CardBUDepartment
                project_stakeholders={project.project_stakeholders}
              />
            )}
            {shouldRenderShortlisted && (
              <CardShortlisted projectId={project.id} />
            )}
            {shouldRenderSelectedForPoC && (
              <CardSelectedForPoC projectId={project.id} />
            )}
            {/* Enable for VW initially */}
            {showTeam && (
              <CardTeam
                projectId={project.id}
                projectTitle={project.title}
                team={project.team}
              />
            )}
            {isNumber(project.poc_adoption_probability) && (
              <CardAdoptionProbability
                probability={project.poc_adoption_probability}
                stage={project.stage}
              />
            )}
            <TimeSpentInStage stage={project.stage} projectId={project.id} />
          </Box>
        );
      },
    }));

    const columnsWithCards = groupBy(cards, 'stage');

    return allowedStages.reduce<typeof columnsWithCards>((acc, stage) => {
      if (columnsWithCards[stage]) {
        acc[stage] = columnsWithCards[stage];
      } else {
        acc[stage] = [];
      }

      return acc;
    }, {});
  }, [allowedStages, filters, has_teams, organizationId, projects]);
};
