import { lazy } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
// components
import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';
import { SourcingOrderDrawer } from 'components/dashboard/sourcingOrder/SourcingOrderDrawer';
import StartupInfoSidePanel from 'components/dashboard/StartupInfoSidePanel/StartupInfoSidePanel';
import PersonDetailsDrawer from 'components/engagement/people/details/DetailsDrawer';
import { StartupEnrichmentProgressProvider } from 'components/enrichmentProgress/StartupEnrichmentProgressContext';
import LoadingScreen from 'components/LoadingScreen';
import ScrollToTop from 'components/ScrollToTop';
import SystemMessages from 'components/SystemMessages/SystemMessages';
import { StartupSidePanelProvider } from 'contexts/StartupSidePanelContext';
import AdminGuard from 'guards/AdminGuard';
import BuUserGuard from 'guards/BuUserGuard';
import useAuth from 'hooks/useAuth';
import SharedPagesLayout, { BuPageLayout } from 'layouts/SharedPagesLayout';
import { ProjectStageChangeDialog } from 'pages/dashboard/scoping/ProjectStageChangeDialog';
import { ChatwootPlugin } from 'plugins/ChatwootPlugin';
import PosthogAnalytics from 'plugins/PosthogAnalytics';
import { INTERNAL_ROUTES } from './internalRoutes';
import { Loadable } from './Loadable';
import { PATH_NAME, PATH_PARAMS, PATH_ROOT } from './paths';
import { RedirectToOrganizationLogin } from './RedirectToOrganizationLogin';
import { SHARED_ROUTES } from './sharedRoutes';
import BaseScrollbar2 from 'components/base/BaseScrollbar2';

const InitializedAppRouter = () => {
  const location = useLocation();

  // Source: https://github.com/remix-run/react-router/blob/dev/examples/modal/src/App.tsx
  // The `backgroundLocation` state is the location that we were at when one of
  // the stakeholder-profile links was clicked. If it's there, use it as the location for
  // the <Routes> so we show the stakeholder-profile in the background, behind the modal.
  const state = location.state as { backgroundLocation?: Location };

  return (
    <BaseScrollbar2 sx={{ height: '100vh' }} autoHide={false}>
      <StartupEnrichmentProgressProvider>
        <StartupSidePanelProvider>
          <PosthogAnalytics>
            <SystemMessages />
            <StartupInfoSidePanel />

            {/* Ensure that the User loaded properly */}
            <ScrollToTop />
            <ChatwootPlugin />
            <SourcingOrderDrawer />
            <Outlet />
            <Routes location={state?.backgroundLocation || location}>
              <Route path={PATH_NAME.auth}>
                <Route
                  path={PATH_NAME.login}
                  element={
                    <GuestGuard>
                      <LoginPage />
                    </GuestGuard>
                  }
                />
                <Route
                  path={PATH_NAME.loginUnprotected}
                  element={<LoginPage />}
                />
                <Route
                  path={PATH_NAME.redirectToLogin}
                  element={<RedirectToOrganizationLogin />}
                />
              </Route>

              <Route
                path={PATH_ROOT.root}
                element={
                  <AdminGuard>
                    <DashboardLayout />
                  </AdminGuard>
                }
              >
                {INTERNAL_ROUTES.map(route => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                    // eslint-disable-next-line
                    children={route.children?.map(child => (
                      <Route
                        key={child.path}
                        path={child.path}
                        element={child.element}
                      />
                    ))}
                  />
                ))}
              </Route>

              <Route path={PATH_NAME.shared} element={<SharedPagesLayout />}>
                {SHARED_ROUTES.map(route => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                    index={route.index}
                  />
                ))}
              </Route>

              <Route
                path={PATH_NAME.portalRoot}
                element={<SharedPagesLayout />}
              >
                {SHARED_ROUTES.map(route => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                    index={route.index}
                  />
                ))}
              </Route>

              <Route
                path={`o/:teamSlug/${PATH_NAME.listScope}/:startupListPublicUUID`}
                element={<BuPageLayout />}
              >
                <Route
                  index
                  element={
                    <BuUserGuard>
                      <BUScopingPage />
                    </BuUserGuard>
                  }
                />
              </Route>
              <Route
                path={`/${PATH_NAME.briefUs}`}
                element={<SharedPagesLayout />}
              >
                <Route index element={<RequestSolutionsPage />} />
              </Route>

              <Route path='*' element={<LogoOnlyLayout />}>
                <Route path='500' element={<Page500 />} />
                <Route path='404' element={<NotFound />} />
                <Route path='*' element={<Navigate to='/404' replace />} />
              </Route>

              <Route path='*' element={<Navigate to='/404' replace />} />
            </Routes>
            {/*
          All drawers/modals that have their own routes should be placed here
          If the user opens the link to the profile directly, this route is used
        */}
            {state?.backgroundLocation && (
              <Routes>
                <Route
                  path={PATH_ROOT.personProfile.details(
                    `:${PATH_PARAMS.personId}`,
                  )}
                  element={<PersonDetailsDrawer />}
                />
                <Route
                  path={PATH_ROOT.changeProjectStage.dialog(
                    `:${PATH_PARAMS.projectId}`,
                    `:${PATH_PARAMS.currentStage}` as unknown as EnumTableProjectStagesEnum,
                    `:${PATH_PARAMS.nextStage}` as unknown as EnumTableProjectStagesEnum,
                  )}
                  element={<ProjectStageChangeDialog />}
                />
              </Routes>
            )}
          </PosthogAnalytics>
        </StartupSidePanelProvider>
      </StartupEnrichmentProgressProvider>
    </BaseScrollbar2>
  );
};

export default function AppRouter() {
  const { isInitialized } = useAuth();

  if (!isInitialized) return <LoadingScreen isAbsolute />;

  return <InitializedAppRouter />;
}

const LoginPage = Loadable(
  lazy(() => import('pages/authentication/LoginPage')),
);

const RequestSolutionsPage = Loadable(
  lazy(() => import('pages/request-form-v2/page/RequestSolutionsPage')),
);

const BUScopingPage = Loadable(
  lazy(() => import('pages/dashboard/scoping/BUScopingPage')),
);

const Page500 = Loadable(lazy(() => import('pages/Page500')));
const NotFound = Loadable(lazy(() => import('pages/Page404')));
