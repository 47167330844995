import { Stack, Typography, TextField } from '@mui/material';
import { ChangeEvent } from 'react';

const MAX_INPUT_VALUE = 10;

export const DifficultyField = ({
  difficultyValue,
  handleDifficultyChange,
}: {
  difficultyValue: string;
  handleDifficultyChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
  return (
    <Stack
      marginTop={0.5}
      marginBottom={1}
      direction='row'
      alignItems='flex-end'
    >
      <Typography
        flexBasis='100%'
        variant='body2'
        fontSize={theme => theme.spacing(1.6875)}
        fontWeight={500}
        color='grey.600'
      >
        Project difficulty
      </Typography>
      <TextField
        variant='standard'
        type='number'
        value={difficultyValue}
        onChange={handleDifficultyChange}
        inputProps={{
          style: { fontSize: 12.5, textAlign: 'center' },
          min: 0,
          // For some weird MUI reason, this also controls the width of the input field
          max: Number(difficultyValue) < MAX_INPUT_VALUE ? 9 : MAX_INPUT_VALUE,
        }}
      />
    </Stack>
  );
};
