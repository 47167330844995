import { OpenInNew } from '@mui/icons-material';
import { Box, Stack, Divider, Tooltip, Link, useTheme } from '@mui/material';

export const OpenLinkHoverButton = ({ website }: { website: string }) => {
  const theme = useTheme();
  const dividerSx = {
    background: theme.palette.grey[300],
    height: theme.spacing(1.5),
    marginY: 'auto !important',
  };

  return (
    <Box
      sx={{
        '&:hover': {
          '.show-website-link': {
            transform: 'scale(1)',
            transition: 'all 0.2s ease-in-out',
          },
        },
      }}
    >
      <Stack
        spacing={1}
        direction='row'
        className='show-website-link'
        sx={{
          transform: 'scale(0)',
          transition: 'transform 0.2s ease-in-out',
        }}
      >
        <Divider flexItem sx={dividerSx} orientation='vertical' />
        <Tooltip title='CTRL/CMD + Click to open in background'>
          <Link
            href={website}
            target='_blank'
            color={'secondary.main'}
            onClick={event => {
              event.stopPropagation();
            }}
          >
            <OpenInNew
              sx={{
                fontSize: theme.typography.body1,
                height: '100%',
              }}
            />
          </Link>
        </Tooltip>
      </Stack>
    </Box>
  );
};
