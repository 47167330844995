import { CircularProgress, Stack, Tooltip } from '@mui/material';
import { StartupSignalLabels } from 'apollo/generated/sdkInnovationManager';
import { useCheckHealthStatusMutation } from 'apollo/generated/sdkShared';
import useLocalStorage from 'hooks/useLocalStorage';
import { useEffect } from 'react';

type SignalLabel = Pick<StartupSignalLabels, 'value'>;

export const useHealthCheckProgress = ({
  startupId,
  signalLabels,
  healthCheckPresent,
}: {
  startupId: number;
  signalLabels: SignalLabel[];
  healthCheckPresent: boolean;
}) => {
  const [inProgress, setInProgress] = useLocalStorage(
    `useHealthCheckProgress:inProgress:${startupId}`,
    false,
    undefined,
    undefined,
    true,
  );

  const healthCheckDone =
    healthCheckPresent ||
    signalLabels.some(
      ({ value }) => value === 'Inactive' || value === 'Acquired',
    );

  const [checkHealthStatus] = useCheckHealthStatusMutation({
    variables: {
      startupId,
    },
  });

  useEffect(() => {
    if (healthCheckDone) {
      setInProgress(false);
    } else {
      if (!inProgress) {
        setInProgress(true);
        checkHealthStatus().catch(() => {
          setInProgress(false);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthCheckDone]);

  const healthCheckSpinner = inProgress && (
    <Stack direction='row' marginLeft={1}>
      <Tooltip title='Checking website...' open placement='right'>
        <Stack direction='row' alignItems='center'>
          <CircularProgress size={16} variant='indeterminate' />
        </Stack>
      </Tooltip>
    </Stack>
  );

  return {
    healthCheckSpinner,
  };
};
